import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "./MobileChart.css";
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { DataEmpty } from "../../../../../Pages";

/* ===== Style ===== */

/**
 * Mobile Chart Container
 * --
 */
const MobileChartContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    background-color: var(--col-white);
    border: ${(props) => props.border ? '1px solid var(--col-gray-1)' : 'none'};
    border-radius: 12px;
    box-shadow: ${(props) => props.border ? '0px 4px 12px var(--col-gray-1)' : 'none'};

    margin-bottom: 20px;
`

/**
 * Mobile Chart Legend
 * --
 */
const ChartLegend = styled.div`
    max-width: 80%;

    display: flex;
    align-items: center;

    text-align: center;
    
    margin-bottom: 1rem;

    color: var(--col-gray-3);

    span {
        display: inline-block;
        margin-right: 3px;
        width: 12px;
        height: 12px;
        background-color: ${(props) => props.color || 'var(--col-gray-3'};
        border-radius: 50%;
        margin-left : 1rem;
    }
`


const MobileChart = ({
    title = '테스트 타이틀',
    DateConfig = [
        {
            name: '방문자수',
            dataKey: '방문자수',
            stroke: '#2EBF6D',
        },
        {
            name: '방문횟수',
            dataKey: '신규방문자수',
            stroke: '#2C97DE',
        },
        {
            name: '신규방문자수',
            dataKey: '페이지뷰',
            stroke: '#E16070',
        },
        {
            name: '재방문자수',
            dataKey: '재방문자수',
            stroke: '#EEC763',
        },
        {
            name: '방문횟수',
            dataKey: '방문횟수',
            stroke: '#2397DE',
        }
    ],
    TimeConfig = [
        {
            name: '방문자수',
            dataKey: 'visitNum',
            stroke: '#2EBF6D',
        },
        {
            name: '방문횟수',
            dataKey: 'totalCnt',
            stroke: '#2C97DE',
        },
        {
            name: '신규방문자수',
            dataKey: 'newVisitNum',
            stroke: '#E16070',
        },
        {
            name: '재방문자수',
            dataKey: 'reVisitNum',
            stroke: '#EEC763',
        },
        {
            name: '페이지뷰',
            dataKey: 'pageView',
            stroke: '#8C939C',
        },
    ],
    PVConfig = [
        {
            name: '페이지뷰',
            dataKey: 'visitPv',
            stroke: '#2EBF6D',
        },
        {
            name: '방문횟수',
            dataKey: 'newVisitPv',
            stroke: '#2C97DE',
        },
        {
            name: '재방문자PV',
            dataKey: 'reVisitPv',
            stroke: '#E16070',
        },
        {
            name: '방문자수',
            dataKey: 'visitCnt',
            stroke: '#EEC763',
        },
        {
            name: '방문당PV',
            dataKey: 'pageViewConv',
            stroke: '#8C939C',
        },
    ],
    UVConfig = [
        {
            name: '방문자수',
            dataKey: 'visitNum',
            stroke: '#2EBF6D',
        },
        {
            name: '방문횟수',
            dataKey: 'visitCnt',
            stroke: '#2C97DE',
        },
        {
            name: '신규방문자수',
            dataKey: 'newVisitNum',
            stroke: '#E16070',
        },
        {
            name: '재방문자수',
            dataKey: 'reVisitNum',
            stroke: '#EEC763',
        },
        {
            name: '방문당PV',
            dataKey: 'pageViewConv',
            stroke: '#8C939C',
        },
    ],
    SummaryConfig = [
        {
            name: '방문자수',
            dataKey: 'visitNum',
            stroke: '#2EBF6D',
        },
        {
            name: '방문횟수',
            dataKey: 'visitCnt',
            stroke: '#2C97DE',
        },
        {
            name: '신규방문자수',
            dataKey: 'newVisitNum',
            stroke: '#E16070',
        },
        {
            name: '재방문자수',
            dataKey: 'reVisitNum',
            stroke: '#EEC763',
        },
        {
            name: '페이지뷰',
            dataKey: 'pageView',
            stroke: '#8C939C',
        },
    ],
    data,
    border = true,

}) => {
    /* ===== State ===== */
    const chartRef = useRef(null);
    // URL에 따른 데이터 선택
    const [linesConfig, setLineConfig] = useState([])
    const [Key, SeyKey] = useState();

    /* ===== Hooks ===== */
    useEffect(() => {
        // URL에 따른 데이터 선택
        const currentPath = window.location.pathname;
        // console.log(currentPath, "currentpath");
        if (currentPath === "/analytics/visit/timeline") {
            setLineConfig(TimeConfig);
            SeyKey('time');
        }
        if (currentPath === "/analytics/visit/daily") {
            setLineConfig(DateConfig);
            SeyKey('date');
        }
        if (currentPath === "/analytics/visit/uv") {
            setLineConfig(UVConfig);
            SeyKey('date');
        }
        if (currentPath === "/analytics/visit/pv") {
            setLineConfig(PVConfig);
            SeyKey('date');
        }
        if (currentPath === "/analytics/summary") {
            setLineConfig(SummaryConfig);
            SeyKey('date');
        }
    }, []);

    /* ===== Function ===== */
    return (
        <>
            <MobileChartContainer className="mobile_chart_container" border={border}>
                <div className="mobile_chart_title">
                    <span
                        style={
                            border ? { margin: '1.5rem' } : { margin: '0 0 1.5rem 0' }
                        }
                    >
                        {title}
                    </span>
                </div>
                {
                    data?.length ? (
                        <>
                            <ResponsiveContainer height={200} overflow={'scroll'}>
                                <LineChart ref={chartRef} data={data} overflow={'scroll'} wrapperStyle={{ zIndex: 1000 }}>
                                    <XAxis dataKey={Key} />
                                    <YAxis width={20} />
                                    {linesConfig.map((line, index) => (
                                        <Line key={index} type={'monotone'} dataKey={line.dataKey} stroke={line.stroke} activeDot={{ r: 7 }} />
                                    ))}
                                </LineChart>
                            </ResponsiveContainer>
                            <div className="mobile_chart_legend">
                                {linesConfig.map((line, index) => (
                                    <ChartLegend key={index} color={line.stroke}>
                                        <span></span> {line.name}
                                    </ChartLegend>
                                ))}
                            </div>
                        </>
                    ) : (
                        <DataEmpty />
                    )
                }

            </MobileChartContainer>
        </>
    );
};

export default MobileChart;