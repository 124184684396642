import React, { useState, useEffect } from 'react';
import './Styles/AnalyticsFooter.css';
import call from './Images/call.svg';
import insta from './Images/insta.svg';
import blog from './Images/blog.svg';
import kakao from './Images/kakao.svg';
import { isMobile } from 'react-device-detect';
import { MobileFooter } from './Mobile';
// import Pic5 from './Images/pic5_v1.png';

function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [showFooter, setShowFooter] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  /**
   * 열기
   * @param {} e
   */
  const openModal = () => {
    setIsModalOpen(true);
  };

  /**
   * 모달 핸들러
   * @param {} e
   */
  const handleModalClick = (e) => {
    setIsModalOpen(false);
  };
  /**
   *  시간 함수
   * --
   */
  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    setCurrentYear(year);
  }, []);
  return (
    <>
      <MobileFooter />
      <div className='pushFooterDown'></div>
      <footer>
        <div className='footer_container'>
          <div className='footer_top'>
            <p className='footer_title'>
              디앤매트릭스 - <span>DN METRICS</span>
            </p>
            <p className='footer_info text_pc'>
              부산광역시 사상구 주례로 19, 2F, 디앤커머스 ⎮
              <br className='m_view_footer' /> 사업자번호 : 376-04-01199 ⎮
              대표자 : 양욱제, 김성훈 ⎮ <br className='m_view_footer' />
              <p className='modal_popup' onClick={openModal}>
                이메일무단수집거부
              </p>
              <br />
              TEL : 051-710-2959 ⎮ E-mail : dncommerce.corp@gmail.com
            </p>
          </div>
          {isModalOpen && (
            <div className='modal_overlay' onClick={handleModalClick}>
              <div className='modal'>
                <div className='modal_content'>
                  <dl>
                    <dt>이메일무단수집거부</dt>
                    <dd>
                      <span>
                        ① 본 웹사이트에 게시된 이메일 주소가 전자우편 수집
                        프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로
                        수집되는 것을 거부하며, 이를 위반시 정보통신망법에 의해
                        형사처벌됨을 유념하시기 바랍니다.
                      </span>
                      <br />
                    </dd>
                    <dd>
                      ② 정보통신망법 제 50조의 2 (전자우편주소의 무단 수집행위
                      등 금지)
                      <br />
                      누구든지 전자우편주소의 수집을 거부하는 의사가 명시된
                      인터넷 홈페이지 에서 자동으로 전자우편주소를 수집하는
                      프로그램 그 밖의 기술적 장치를 이용하여 전자우편주소를
                      수집하여서는 아니된다.
                      <br />
                    </dd>
                    <dd>
                      ③ 누구든지 제1항의 규정을 위반하여 수집된 전자우편주소를
                      판매·유통하여서는 아니된다.
                      <br />
                    </dd>
                    <dd>
                      ④ 누구든지 제1항 및 제2항의 규정에 의하여 수집·판매 및
                      유통이 금지된 전자 우편주소임을 알고 이를 정보전송에
                      이용하여서는 아니된다.
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          )}
          <div className={'footer_bottom'}>
            <p>
              COPYRIGHT(c) 2018 - <span id='current-year'>{currentYear}</span>{' '}
              디앤커머스
              <br className='m_view_footer' /> ALL RIGHTS RESERVED.
            </p>
            <div className='footer_icon'>
              <a
                href='https://blog.naver.com/dncommerce-/'
                target='_blank'
                rel='noopener'
              >
                <img width={45} src={blog} alt='' />
              </a>
              <a
                href='https://pf.kakao.com/_AnHxkC'
                target='_blank'
                rel='noopener'
              >
                <img width={45} src={kakao} alt='' />
              </a>
              <a
                href='https://www.instagram.com/dncommerce_official/'
                target='_blank'
                rel='noopener'
              >
                <img width={45} src={insta} alt='' />
              </a>
              <a href='tel:051-710-2959' target='_blank' rel='noopener'>
                <img width={45} src={call} alt='' />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
