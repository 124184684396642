import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './Styles/PreiodPicker.css';

const PreiodPicker = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(['', '']);
  const [comparePeriod, setComparePeriod] = useState(['', '']);
  useEffect(() => {
    initPeriod();
  }, []);
  const date = new Date();
  date.setDate(date.getDate() - 1);
  const dateStr = date.toISOString().slice(0, 10);

  // console.log('nowDate', dateStr);
  const [inputPeriod, setInputPeriod] = useState(true);
  const [checked, setChecked] = useState('7일');
  const [startDate, setStartDate] = useState(dateStr);
  const [endDate, setEndDate] = useState(dateStr);

  const handleRadioBtn = (e) => {
    setChecked(e.target.value);
    let periodStart = '';
    let periodEnd = '';
    let comparePeriodStart = '';
    let comparePeriodEnd = '';
    if (e.target.value === '직접입력') {
      setInputPeriod(false);
    } else {
      setInputPeriod(true);
      periodStart = dateMinus(
        dateStr,
        parseInt(e.target.value.split('일')[0]) - 1
      );
      periodEnd = dateStr;
      comparePeriodStart = dateMinus(
        periodStart,
        parseInt(e.target.value.split('일')[0])
      );

      comparePeriodEnd = dateMinus(periodStart, 1);
      setPeriod([periodStart, periodEnd]);
      setComparePeriod([comparePeriodStart, comparePeriodEnd]);
      setStartDate(periodStart);
      setEndDate(periodEnd);
    }
  };
  const handleStartDate = (e) => {
    const _inputDate = new Date(e.target.value);
    const _endDate = new Date(endDate);
    // console.log(_inputDate, _endDate);
    if (_inputDate > _endDate) {
      alert('시작일은 종료일 이후로 지정할 수 없습니다.');
    } else {
      setStartDate(e.target.value);
    }
  };
  const handleEndDate = (e) => {
    const _inputDate = new Date(e.target.value);
    const _startDate = new Date(startDate);
    const _todayDate = new Date(dateStr);
    _todayDate.setDate(_todayDate.getDate() + 1);
    // console.log(_startDate, _inputDate);
    if (_inputDate > _todayDate) {
      alert('종료일은 오늘 이후로 지정할 수 없습니다.');
    } else if (_inputDate < _startDate) {
      alert('종료일은 시작일 이전으로 지정할 수 없습니다.');
    } else {
      setEndDate(e.target.value);
    }
  };
  function initPeriod() {
    let periodStart = dateMinus(dateStr, 6);
    // console.log('periodStart', periodStart);
    let periodEnd = dateStr;
    let comparePeriodStart = dateMinus(periodStart, 7);
    // console.log('comparePeriod', comparePeriod);
    let comparePeriodEnd = dateMinus(periodStart, 2);
    setPeriod([periodStart, periodEnd]);
    setComparePeriod([comparePeriodStart, comparePeriodEnd]);
    setStartDate(periodStart);
    setEndDate(periodEnd);
  }
  function dateMinus(dateStr, n) {
    // console.log(dateStr);
    const newDate = new Date(dateStr);
    newDate.setDate(newDate.getDate() - n);
    const newDateStr = `${newDate.getFullYear()}-${(
      '0' +
      (newDate.getMonth() + 1)
    ).slice(-2)}-${('0' + newDate.getDate()).slice(-2)}`;
    // console.log(new Date(), newDateStr);
    return newDateStr;
  }
  const searchBtnHandler = () => {
    dispatch({
      type: 'SET_PERIOD',
      payload: period,
    });
    dispatch({
      type: 'SET_COMPARE_PERIOD',
      payload: comparePeriod,
    });

    // setLoading(true);
  };

  useEffect(() => {
    if (checked === '직접입력') {
      const date = new Date(endDate) - new Date(startDate);
      const millisecondsPerSecond = 1000;
      const secondsPerMinute = 60;
      const minutesPerHour = 60;
      const hoursPerDay = 24;
      const totalSeconds = date / millisecondsPerSecond;
      const totalMinutes = totalSeconds / secondsPerMinute;
      const totalHours = totalMinutes / minutesPerHour;
      const totalDays = totalHours / hoursPerDay;
      if (date === 0) {
        setPeriod([startDate, endDate]);
        let comparePeriodStart = dateMinus(startDate, 1);
        let comparePeriodEnd = dateMinus(startDate, 1);
        setComparePeriod([comparePeriodStart, comparePeriodEnd]);
      } else {
        // console.log('sadsadda', totalDays);
        let comparePeriodEnd = dateMinus(startDate, 1);
        let comparePeriodStart = dateMinus(comparePeriodEnd, totalDays);
        // console.log('comparePeriod', comparePeriod);

        setPeriod([startDate, endDate]);
        setComparePeriod([comparePeriodStart, comparePeriodEnd]);
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (checked === '직접입력') {
      const date = new Date(endDate) - new Date(startDate);
      const millisecondsPerSecond = 1000;
      const secondsPerMinute = 60;
      const minutesPerHour = 60;
      const hoursPerDay = 24;
      const totalSeconds = date / millisecondsPerSecond;
      const totalMinutes = totalSeconds / secondsPerMinute;
      const totalHours = totalMinutes / minutesPerHour;
      const totalDays = totalHours / hoursPerDay;
      if (date === 0) {
        setPeriod([startDate, endDate]);
        let comparePeriodStart = dateMinus(startDate, 1);
        let comparePeriodEnd = dateMinus(startDate, 1);
        setComparePeriod([comparePeriodStart, comparePeriodEnd]);
      } else {
        // console.log('sadsadda', totalDays);
        let comparePeriodEnd = dateMinus(startDate, 1);
        let comparePeriodStart = dateMinus(comparePeriodEnd, totalDays);
        // console.log('comparePeriod', comparePeriod);

        setPeriod([startDate, endDate]);
        setComparePeriod([comparePeriodStart, comparePeriodEnd]);
      }
    }
  }, []);
  return (
    <>
      <div id='periodPicker'>
        <div className='title'>조회 기간</div>
        <div className='option'>
          <input
            type='radio'
            name='selPeriod'
            value='1일'
            checked={checked === '1일'}
            onChange={handleRadioBtn}
            id='selPreiod1d'
          />
          <label htmlFor='selPreiod1d'>
            <span></span>1일
          </label>
          <input
            type='radio'
            name='selPeriod'
            value='7일'
            id='selPreiod7d'
            checked={checked === '7일'}
            onChange={handleRadioBtn}
          />
          <label htmlFor='selPreiod7d'>
            <span></span>7일
          </label>
          <input
            type='radio'
            name='selPeriod'
            value='30일'
            id='selPreiod30d'
            checked={checked === '30일'}
            onChange={handleRadioBtn}
          />
          <label htmlFor='selPreiod30d'>
            <span></span>30일
          </label>
          <input
            type='radio'
            name='selPeriod'
            value='직접입력'
            id='selPreiodSelf'
            checked={checked === '직접입력'}
            onChange={handleRadioBtn}
          />
          <label htmlFor='selPreiodSelf'>
            <span></span>직접입력
          </label>
        </div>
        <div className='preiod'>
          <input
            type='date'
            name='preiodStart'
            id='preiodStart'
            value={startDate}
            disabled={inputPeriod}
            onChange={handleStartDate}
          />
          <span>-</span>
          <input
            type='date'
            name='preiodEnd'
            id='preiodEnd'
            value={endDate}
            disabled={inputPeriod}
            onChange={handleEndDate}
          />
        </div>
        <button className='fullBtn' onClick={searchBtnHandler}>
          조회
        </button>
      </div>
    </>
  );
};
export default PreiodPicker;