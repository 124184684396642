import React from 'react';
import { useSelector } from 'react-redux';
import './Styles/SummaryVisit.css';

const SummaryVisit = ({ data }) => {
  const PVdataSummary = { ...data?.PVData?.summary };
  const UVdataSummary = { ...data?.UVData?.summary };
  const period = useSelector((state) => state.period);
  return (
    <>
      <div id='summaryVisit'>
        <div className='compHeader'>
          <div className='title'>방문현황 요약</div>
          <div className='info'>
            등락 비교일 {period[0]}~{period[1]}
          </div>
        </div>
        <div className='compBody'>
          <div className='item numVisit'>
            <div className='itemHeader'>
              <div className='title'>방문자수</div>
              <div className='info'>
                {UVdataSummary[0]?.pre}명 |{' '}
                {UVdataSummary[0]?.cur - UVdataSummary[0]?.pre}명(
                {UVdataSummary[0]?.pre
                  ? UVdataSummary[0]?.cur - UVdataSummary[0]?.pre < 0
                    ? `${(
                        ((UVdataSummary[0]?.cur - UVdataSummary[0]?.pre) /
                          UVdataSummary[0]?.pre) *
                        100
                      ).toFixed(1)}%`
                    : `+${(
                        ((UVdataSummary[0]?.cur - UVdataSummary[0]?.pre) /
                          UVdataSummary[0]?.pre) *
                        100
                      ).toFixed(1)}%`
                  : '100%'}
                )
              </div>
            </div>
            <div className='itemBody'>
              <div className='count'>{UVdataSummary[0]?.cur}</div>
            </div>
          </div>
          <div className='item cntVisit'>
            <div className='itemHeader'>
              <div className='title'>방문횟수</div>
              <div className='info'>
                {UVdataSummary[1]?.pre}명 |{' '}
                {UVdataSummary[1]?.cur - UVdataSummary[1]?.pre}명(
                {UVdataSummary[1]?.pre
                  ? UVdataSummary[1]?.cur - UVdataSummary[1]?.pre < 0
                    ? `${(
                        ((UVdataSummary[1]?.cur - UVdataSummary[1]?.pre) /
                          UVdataSummary[1]?.pre) *
                        100
                      ).toFixed(1)}%`
                    : `+${(
                        ((UVdataSummary[1]?.cur - UVdataSummary[1]?.pre) /
                          UVdataSummary[1]?.pre) *
                        100
                      ).toFixed(1)}%`
                  : '100%'}
                )
              </div>
            </div>
            <div className='itemBody'>
              <div className='count'>{UVdataSummary[1]?.cur}</div>
            </div>
          </div>
          <div className='item numPageView'>
            <div className='itemHeader'>
              <div className='title'>페이지뷰</div>
              <div className='info'>
                {PVdataSummary[0]?.pre}명 |{' '}
                {PVdataSummary[0]?.cur - PVdataSummary[0]?.pre}명(
                {PVdataSummary[0]?.pre
                  ? PVdataSummary[0]?.cur - PVdataSummary[0]?.pre < 0
                    ? `${(
                        ((PVdataSummary[0]?.cur - PVdataSummary[0]?.pre) /
                          PVdataSummary[0]?.pre) *
                        100
                      ).toFixed(1)}%`
                    : `+${(
                        ((PVdataSummary[0]?.cur - PVdataSummary[0]?.pre) /
                          PVdataSummary[0]?.pre) *
                        100
                      ).toFixed(1)}%`
                  : '100%'}
                )
              </div>
            </div>
            <div className='itemBody'>
              <div className='count'>{PVdataSummary[0]?.cur}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SummaryVisit;
