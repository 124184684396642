import { useEffect, useRef, useState } from 'react';
import './Styles/TLGraph.css';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { MobileChart } from '../Mobile';

const TLGraph = ({
  data
}) => {
  // const newData =
  //   data && data.length > 0
  //     ? data
  //     : [
  //       {
  //         time: '00:00',
  //         visitNum: 0,
  //         visitCnt: 0,
  //         newVisitNum: 9,
  //         reVisitNum: 0,
  //         pageView: 0,
  //       },
  //     ];
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [graphWidth, setGraphWidth] = useState(
    window.innerWidth > 1920
      ? 1156
      : window.innerWidth > 1200
        ? window.innerWidth - 764
        : window.innerWidth - 422
  );

  /**
   * 데이터 그룹화 함수
   * --
   */
  const groupByTime = (data) => {
    return data.reduce((acc, item) => {
      const time = item.time;
      if (!acc[time]) {
        acc[time] = {
          time: time || 0,
          visitNum: 0,
          visitCnt: 0,
          newVisitNum: 0,
          reVisitNum: 0,
          pageView: 0,
        };
      }
      acc[time].visitNum += item.visitNum || 0;
      acc[time].visitCnt += item.visitCnt || 0;
      acc[time].newVisitNum += item.newVisitNum || 0;
      acc[time].reVisitNum += item.reVisitNum || 0;
      acc[time].pageView += item.pageView || 0;
      return acc;
    }, {});
  };

  const groupedData = groupByTime(data);
  const newData = Object.values(groupedData);

  /**
   * 그룹 데이터 정렬
   * --
   */
  const sortedData = newData.sort((a, b) => {
    const hour = a.time.split(':').map(Number);
    const minute = b.time.split(':').map(Number);
    return hour[0] - minute[0] || hour[1] - minute[1];
  });

  const graphRef = useRef();
  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
      if (window.innerWidth > 1920) {
        setGraphWidth(1160);
      } else if (window.innerWidth > 1200) {
        setGraphWidth(window.innerWidth - 764);
      } else if (window.innerWidth > 960) {
        setGraphWidth(window.innerWidth - 422);
      } else {
        setGraphWidth(540);
      }
    };
    window.addEventListener('resize', resizeListener);
  });

  return (
    <>
      <div id='tlGraph'>

        {/* Mobile Chart */}
        <div className='mobile_chart'>
          <MobileChart
            title='시간대별 방문그래프'
            data={sortedData}
            border={false}
          />
        </div>

        <div className='compHeader'>
          <div className='title'>시간대별 방문그래프</div>
        </div>
        <div className='compBody'>
          <div className='graph'>
            <div className='chart' ref={graphRef}>
              <div style={{ width: graphWidth, height: 200 }}>
                <ResponsiveContainer>
                  <LineChart
                    width={graphWidth}
                    data={sortedData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <XAxis dataKey='time' />
                    <YAxis />
                    <Line
                      type='monotone'
                      dataKey='visitNum'
                      stroke='#2EBF6D'
                      activeDot={{ r: 7 }}
                    />
                    <Line
                      type='monotone'
                      dataKey='visitCnt'
                      stroke='#2C97DE'
                      activeDot={{ r: 7 }}
                    />
                    <Line
                      type='monotone'
                      dataKey='newVisitNum'
                      stroke='#E16070'
                      activeDot={{ r: 7 }}
                    />
                    <Line
                      type='monotone'
                      dataKey='reVisitNum'
                      stroke='#EEC763'
                      activeDot={{ r: 7 }}
                    />
                    <Line
                      type='monotone'
                      dataKey='pageView'
                      stroke='#8C939C'
                      activeDot={{ r: 7 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className='graphInfo'>
            <div className='legendInfo totalNum'>
              <span></span> 방문자수
            </div>
            <div className='legendInfo totalCnt'>
              <span></span> 방문횟수
            </div>
            <div className='legendInfo newVisitNum'>
              <span></span> 신규방문자수
            </div>
            <div className='legendInfo reVisitNum'>
              <span></span> 재방문자수
            </div>
            <div className='legendInfo pageView'>
              <span></span> 페이지뷰
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TLGraph;
