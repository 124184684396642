import React from 'react';
import { useSelector } from 'react-redux';
import './Styles/PVSummary.css';

const PVSummary = ({ data }) => {
  const period = useSelector((state) => state.period);
  return (
    <>
      <div id='pvSummary'>
        <div className='compHeader'>
          <div className='title'>페이지뷰 요약</div>
          <div className='info'>
            등락 비교일 {period[0]}~{period[1]}
          </div>
        </div>

        <div className='compBody'>
          {data?.map((item, idx) => {
            return (
              <div className='item' key={idx}>
                <div className='itemHeader'>
                  <div className='title'>{item?.title}</div>
                  <div className='value'>{item?.cur}</div>
                </div>
                <div className='itemBody'>
                  <div className='info'>{item?.pre}</div>
                  <div className='info'>
                    {idx === 4
                      ? (item?.cur - item?.pre).toFixed(2)
                      : item?.cur - item?.pre}
                    (
                    {item.pre
                      ? item?.cur - item.pre < 0
                        ? `${(
                            ((item?.cur - item.pre) / item?.pre) *
                            100
                          ).toFixed(1)}%`
                        : `+${(
                            ((item?.cur - item.pre) / item?.pre) *
                            100
                          ).toFixed(1)}%`
                      : '100%'}
                    )
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default PVSummary;
