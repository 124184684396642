import { Link } from 'react-router-dom';
import styled from 'styled-components';
import './MobileSidebar.css';
import Logo from '../../../Images/logo_color.svg';
import { useCallback, useEffect, useRef, useState } from 'react';

/* ===== Style ===== */
const SideBarLink = styled(Link)`
    margin: 12px 0;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    color: var(--col-gray-4);
    cursor: pointer;
    &:hover {
    font-weight: 600;
    color: var(--col-primary-2);
    }
    &:active {
    font-weight: 700;
    color: var(--col-primary-1);
    }
`;

const MobileSideBar = ({ width = 240 }, props) => { // 240 = Sidebar min-width

    /* ===== State ===== */
    const [isOpen, setIsOpen] = useState(false);
    const [xPosition, setXPosition] = useState(width);
    const sidebarRef = useRef();

    /* ===== Function ===== */
    const goToAnalyticsMain = () => {
        window.location.assign('/analytics/summary');
    };

    /**
     * 사이드바 너비 조절
     * --
     */
    const toggleMenu = (e) => {
        // 지금 'click' 이벤트 발생 시, toggleMenu 와 window eventListener 같이 작동중임
        // stopPropagation 은 이벤트 발생 시, 상위 트리로 전파를 막음
        e.stopPropagation();
        if (xPosition > 0) {
            setXPosition(0);
            setIsOpen(true);
        } else {
            setXPosition(width);
            setIsOpen(false);
        }
    };

    /**
     * 사이드바 닫기 핸들러
     * --
     */
    const handleClose = useCallback(async (e) => {
        let sidebarArea = sidebarRef.current;
        let sidebarChildren = sidebarRef.current.contains(e.target);
        if (isOpen && (!sidebarArea || !sidebarChildren)) {
            await setXPosition(width);
            setIsOpen(false);
        }
    });

    /* ===== Link 클릭 핸들러 ===== */
    // 재랜더링 시, 콜백 함수 재생성 방지
    const handleLinkClick = useCallback(() => {
        setIsOpen(false);
        setXPosition(width);
    }, [width]);

    /* ===== Hooks ===== */
    useEffect(() => {
        if (isOpen) {
            window.addEventListener('click', handleClose);
        return () => {
            window.removeEventListener('click', handleClose);
        }
        }
    })

    return (
        <div id='sidebar'
            ref={sidebarRef}
            style={{ width: `${width}px`, transform: `translate(${-xPosition - 1}px)` }}
        >
            <button
                className='sidebar_drawer'
                onClick={toggleMenu}
            >
                {
                    isOpen ? (
                        "<<"
                    ) : (
                        ">>"
                    )
                }
            </button>
            <div className='logo' onClick={goToAnalyticsMain}>
                <img src={Logo} alt='' />
            </div>
            <div className='menu'>
                <ul className='step1'>
                    <li>
                        요약
                        <ul className='step2'>
                            <li className='sel'>
                                <SideBarLink to='/analytics/summary/' onClick={handleLinkClick}>
                                    {props?.currentPage === '사이트현황' ? (
                                        <strong>사이트현황</strong>
                                    ) : (
                                        '사이트현황'
                                    )}
                                </SideBarLink>
                            </li>
                        </ul>
                    </li>
                    <li>
                        방문분석
                        <ul className='step2'>
                            <li>
                                <SideBarLink to='/analytics/visit/uv' onClick={handleLinkClick}>
                                    {props.currentPage === '방문현황' ? (
                                        <strong>방문현황</strong>
                                    ) : (
                                        '방문현황'
                                    )}
                                </SideBarLink>
                            </li>
                            <li>
                                <SideBarLink to='/analytics/visit/pv' onClick={handleLinkClick}>
                                    {props.currentPage === '페이지뷰' ? (
                                        <strong>페이지뷰</strong>
                                    ) : (
                                        '페이지뷰'
                                    )}
                                </SideBarLink>
                            </li>
                            <li>
                                <SideBarLink to='/analytics/visit/timeline' onClick={handleLinkClick}>
                                    {props.currentPage === '시간대방문분포' ? (
                                        <strong>시간대방문분포</strong>
                                    ) : (
                                        '시간대방문분포'
                                    )}
                                </SideBarLink>
                            </li>
                            <li>
                                <SideBarLink to='/analytics/visit/daily' onClick={handleLinkClick}>
                                    {props.currentPage === '요일별방문분포' ? (
                                        <strong>요일별방문분포</strong>
                                    ) : (
                                        '요일별방문분포'
                                    )}
                                </SideBarLink>
                            </li>
                            <li className='developing'>
                                <SideBarLink to='/analytics/visit/durationTime' onClick={handleLinkClick}>
                                    {props.currentPage === '방문체류시간' ? (
                                        <strong>방문체류시간</strong>
                                    ) : (
                                        '방문체류시간'
                                    )}
                                </SideBarLink>
                            </li>
                            <li>
                                <SideBarLink to='/analytics/visit/powerlink' onClick={handleLinkClick}>
                                    {props.currentPage === '부정클릭현황' ? (
                                        <strong>부정클릭현황</strong>
                                    ) : (
                                        '부정클릭현황'
                                    )}
                                </SideBarLink>
                            </li>
                        </ul>
                    </li>
                    <li>
                        유입분석
                        <ul className='step2'>
                            <li className='developing'>
                                <SideBarLink to='/analytics/inflow/search' onClick={handleLinkClick}>
                                    {props.currentPage === '검색유입현황' ? (
                                        <strong>검색유입현황</strong>
                                    ) : (
                                        '검색유입현황'
                                    )}
                                </SideBarLink>
                            </li>
                            <li>
                                <SideBarLink to='/analytics/inflow/keyword' onClick={handleLinkClick}>
                                    {props.currentPage === '유입검색어' ? (
                                        <strong>유입검색어</strong>
                                    ) : (
                                        '유입검색어'
                                    )}
                                </SideBarLink>
                            </li>
                            <li>
                                <SideBarLink to='/analytics/inflow/url' onClick={handleLinkClick}>
                                    {props.currentPage === '유입상세URL' ? (
                                        <strong>유입상세URL</strong>
                                    ) : (
                                        '유입상세URL'
                                    )}
                                </SideBarLink>
                            </li>
                        </ul>
                    </li>
                    <li>
                        페이지분석
                        <ul className='step2'>
                            <li>
                                <SideBarLink to='/analytics/page/popular' onClick={handleLinkClick}>
                                    {props.currentPage === '인기페이지' ? (
                                        <strong>인기페이지</strong>
                                    ) : (
                                        '인기페이지'
                                    )}
                                </SideBarLink>
                            </li>
                            <li>
                                <SideBarLink to='/analytics/page/start' onClick={handleLinkClick}>
                                    {props.currentPage === '시작페이지' ? (
                                        <strong>시작페이지</strong>
                                    ) : (
                                        '시작페이지'
                                    )}
                                </SideBarLink>
                            </li>
                            <li className='developing'>
                                <SideBarLink to='/analytics/page/end' onClick={handleLinkClick}>
                                    {props.currentPage === '종료페이지' ? (
                                        <strong>종료페이지</strong>
                                    ) : (
                                        '종료페이지'
                                    )}
                                </SideBarLink>
                            </li>
                            <li className='developing'>
                                <SideBarLink to='/analytics/page/return' onClick={handleLinkClick}>
                                    {props.currentPage === '반송페이지' ? (
                                        <strong>반송페이지</strong>
                                    ) : (
                                        '반송페이지'
                                    )}
                                </SideBarLink>
                            </li>
                        </ul>
                    </li>
                    <li>
                        사용자분석
                        <ul className='step2'>
                            <li>
                                <SideBarLink to='/analytics/user/os' onClick={handleLinkClick}>
                                    {props.currentPage === '운영체제' ? (
                                        <strong>운영체제</strong>
                                    ) : (
                                        '운영체제'
                                    )}
                                </SideBarLink>
                            </li>
                            <li>
                                <SideBarLink to='/analytics/user/browser' onClick={handleLinkClick}>
                                    {props.currentPage === '웹브라우저' ? (
                                        <strong>웹브라우저</strong>
                                    ) : (
                                        '웹브라우저'
                                    )}
                                </SideBarLink>
                            </li>
                        </ul>
                    </li>
                    <li>
                        이메일관리
                        <ul className='step2'>
                            <li>
                                <SideBarLink to='/analytics/user/email' onClick={handleLinkClick}>
                                    {props.currentPage === '이메일관리' ? (
                                        <strong>이메일관리</strong>
                                    ) : (
                                        '이메일관리'
                                    )}
                                </SideBarLink>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default MobileSideBar;
