import React, { useEffect, useState } from 'react';
import './Styles/AnalyticsMain.css';
import UVSummary from '../../Components/AnalyticsUV/UVSummary';
import UVGraph from '../../Components/AnalyticsUV/UVGraph';
import UVDetail from '../../Components/AnalyticsUV/UVDetail';
import { useSelector } from 'react-redux';
import { DataApi } from '../../API';
import { useLoadingState } from '../../context/LoadingContext';
import { isMobile } from 'react-device-detect';
import { MobileChart } from '../../Components/Mobile';
function AnalyticsVisit(props) {
  props.setCurrentPage(props.pageTitle);
  const [, setLoading] = useLoadingState(true);
  const currentSite = useSelector((state) => state.currentSite);
  const period = useSelector((state) => state.period);
  const comparePeriod = useSelector((state) => state.comparePeriod);
  const [data, setData] = useState({ summary: [], row: [] });
  useEffect(() => {
    try {
      setLoading(true);
      const getApi = async () => {
        const uvData = await DataApi.getUV(
          currentSite.script_code,
          period,
          comparePeriod
        );
        const uvRowData = await DataApi.getUVRow(
          currentSite.script_code,
          period
        );
        setData({ summary: uvData, row: uvRowData });
      };
      getApi();
    } catch (e) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [currentSite, period, comparePeriod, setLoading]);

  /**
   * Mobile: 데이터 구조 재정의
   * --
   */
  const newData = data.row?.map((item) => {
    return {
      date: item?.date,
      visitNum: item?.all_visitor?.value,
      visitCnt: item?.visit_times?.value,
      newVisitNum: item?.new_visitor?.value,
      reVisitNum: item?.re_visitor?.value,
      pageView: item?.visit_pv?.value,
      pageViewConv: item?.visit_pv?.value,
    };
  });

  return (
    <>
      <UVSummary data={data.summary} />
      <UVGraph data={data.row} />
      <UVDetail data={data.row} />
    </>
  );
}

export default AnalyticsVisit;
