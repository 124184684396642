import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './Styles/UserDetail.css';
import { UserApi } from '../../API';

const UserDetail = () => {
  const userData = useSelector((state) => state.userData);
  const [isPopUp, setIsPopUp] = useState(false);
  const [isPasswordPopUp, setIsPasswordPopUp] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [editInfo, setEditInfo] = useState([]);
  const [passowrdInputData, setPassowrdInputData] = useState([]);
  const [feedback, setFeedback] = useState('');
  const updatePopUpHandler = (type, data) => {
    setFeedback('');
    switch (type) {
      case 'user':
        setIsPopUp(!isPopUp);
        setEditInfo(data);
        break;
      case 'password':
        setIsPasswordPopUp(!isPasswordPopUp);
        setPassowrdInputData({
          user_account: data?.user_account,
          user_id: data?.user_id,
        });
        break;
    }
  };
  const blockBubbling = (event) => {
    event.stopPropagation();
  };
  const handleChange = (t, v, callback = null) => {
    let newData = { ...editInfo };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setEditInfo(d);
    if (callback) callback(v);
  };
  const handleChangePassoword = (t, v, callback = null) => {
    let newData = { ...passowrdInputData };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setPassowrdInputData(d);
    if (callback) callback(v);
  };

  const updateUserHandler = async () => {
    const { phone, email, user_id } = editInfo;
    const phoneReg = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;
    const emailReg =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,4}$/i;
    if (!emailReg.test(email)) {
      setFeedback('이메일 형식이 틀렸습니다.');
      return;
    }
    if (!phoneReg.test(phone)) {
      setFeedback('휴대전화 형식이 틀렸습니다.');
      return;
    }
    setFeedback('');
    const { status } = await UserApi.updateUser({
      phone,
      email,
      user_id,
    });
    if (status === 200) {
      window.location.reload();
    }
  };

  const updateUserPasswordHandler = async () => {
    const { password, newpassword, comfirmpassword } = passowrdInputData;
    const { status } = await UserApi.checkUserPassword({
      password: password,
      user_account: editInfo?.user_account,
      user_id: editInfo?.user_id,
    });
    if (status !== 200) {
      setFeedback('현재 비밀번호가 일치하지 않습니다.');
      return;
    }
    if (!newpassword || !comfirmpassword || !password) {
      setFeedback('빈칸을 모두 입력해주세요.');
      return;
    }
    if (newpassword !== comfirmpassword) {
      setFeedback('새 비밀번호가 일치하지 않습니다.');
      return;
    }
    if (window.confirm('정말로 수정하시겠습니까?')) {
      const { status } = await UserApi.updateUser({
        password: newpassword,
        user_account: editInfo?.user_account,
        user_id: editInfo?.user_id,
      });
      if (status === 200) {
        window.location.reload();
      }
    }
    // console.log(passowrdInputData);
    // console.log(editInfo);
    // console.log(password);
    // console.log(newpassword);
    // console.log(comfirmpassword);
  };
  useEffect(() => {
    try {
      const getApi = async () => {
        const { type } = userData;
        if (type === 'ADMIN') {
          const { status, data } = await UserApi.getUsers();
          if (status === 200) {
            setUserInfo(data);
          }
        } else {
          const { status, data } = await UserApi.getUser(userData?.user_id);
          if (status === 200) {
            setUserInfo([data]);
          }
        }
      };
      getApi();
    } catch (e) {
      throw e;
    }
  }, []);
  return (
    <>
      <div id='UserDetail'>
        <div className='compHeader'>
          <div className='title'>회원정보 관리</div>
        </div>
        <div className='compBody'>
          {userInfo?.length === 0 ? (
            <div className='noData'>
              <svg viewBox='0 0 24 24' width='20' height='20'>
                <path d='m19,0H5C2.243,0,0,2.243,0,5v14c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5Zm-8,6c0-.553.447-1,1-1s1,.447,1,1v7.5c0,.553-.447,1-1,1s-1-.447-1-1v-7.5Zm1,13c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5,1.5.672,1.5,1.5-.672,1.5-1.5,1.5Z' />
              </svg>
              데이터가 없습니다.
            </div>
          ) : (
            <table className='table'>
              <thead>
                <tr>
                  <th>아이디</th>
                  <th>이메일</th>
                  <th>전화번호</th>
                  <th>이름</th>
                  {/* <th>수정하기</th> */}
                  {/* <th>삭제하기</th> */}
                </tr>
              </thead>
              <tbody>
                {userInfo?.map((item, idx) => (
                  <tr className='item' key={`${idx}${item.user_account}`}>
                    <td>{item.user_account}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{item.name}</td>
                    <td>
                      <button
                        className='tdBtn'
                        onClick={() => updatePopUpHandler('user', item)}
                        value={idx}
                      >
                        수정하기
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {isPopUp ? (
        <div id='addSite' onClick={() => updatePopUpHandler('user')}>
          <div className='content' onClick={blockBubbling}>
            <div className='close' onClick={() => updatePopUpHandler('user')}>
              <svg viewBox='0 0 24 24' width='32' height='32'>
                <path d='M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z' />
              </svg>
            </div>
            <div className='title_container'>
              <h1 className='title'>유저정보 수정</h1>
              <button
                className='tdPopupBtn'
                onClick={() => updatePopUpHandler('password', editInfo)}
              >
                비밀번호 변경
              </button>
            </div>
            {feedback !== '' ? <p className='error'>{feedback}</p> : <></>}
            <h3 className='subTitle'>아이디</h3>
            <div className='inputWrap'>
              <input
                id='user_account'
                name='user_account'
                type='text'
                style={{ background: '#C2C8CF' }}
                value={editInfo?.user_account}
                disabled={editInfo?.user_account}
              ></input>
            </div>
            <h3 className='subTitle'>이름</h3>
            <div className='inputWrap'>
              <input
                id='name'
                name='name'
                type='text'
                style={{ background: '#C2C8CF' }}
                value={editInfo?.name}
                disabled={editInfo?.name}
              ></input>
            </div>
            <h3 className='subTitle'>이메일</h3>
            <div className='inputWrap'>
              <input
                id='signInUserAccount'
                name='email'
                type='text'
                placeholder='이메일을 입력해주세요'
                value={editInfo?.email}
                onChange={(e) => handleChange('email', e.target.value)}
              ></input>
            </div>
            <h3 className='subTitle'>휴대전화</h3>
            <div className='inputWrap'>
              <input
                id='phone'
                name='phone'
                placeholder='사이트 주소를 입력해주세요.'
                value={editInfo?.phone}
                onChange={(e) => handleChange('phone', e.target.value)}
              ></input>
            </div>
            <button className='fullBtn' onClick={updateUserHandler}>
              수정하기
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {isPasswordPopUp ? (
        <div id='addSite' onClick={() => updatePopUpHandler('password')}>
          <div className='content' onClick={blockBubbling}>
            <div
              className='close'
              onClick={() => updatePopUpHandler('password')}
            >
              <svg viewBox='0 0 24 24' width='32' height='32'>
                <path d='M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z' />
              </svg>
            </div>
            <h1 className='title'>비밀번호 수정</h1>
            {feedback !== '' ? <p className='error'>{feedback}</p> : <></>}
            <h3 className='subTitle'>현재 비밀번호</h3>
            <div className='inputWrap'>
              <input
                id='signInUserAccount'
                name='password'
                type='text'
                placeholder='현재 비밀번호를 입력해주세요'
                onChange={(e) =>
                  handleChangePassoword('password', e.target.value)
                }
              ></input>
            </div>
            <h3 className='subTitle'>새 비밀번호</h3>
            <div className='inputWrap'>
              <input
                id='newpassword'
                name='newpassword'
                placeholder='새 비밀번호를 입력해주세요'
                onChange={(e) =>
                  handleChangePassoword('newpassword', e.target.value)
                }
              ></input>
            </div>
            <h3 className='subTitle'>비밀번호 확인</h3>
            <div className='inputWrap'>
              <input
                id='comfirmpassword'
                name='comfirmpassword'
                placeholder='새 비밀번호를 입력해주세요'
                onChange={(e) =>
                  handleChangePassoword('comfirmpassword', e.target.value)
                }
              ></input>
            </div>
            <button className='fullBtn' onClick={updateUserPasswordHandler}>
              수정하기
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default UserDetail;
