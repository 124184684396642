import './Styles/UVDetail.css';

const UVDetail = ({ data }) => {
  const newData = data?.map((item) => {
    return {
      date: item?.date,
      visitNum: item?.all_visitor?.value,
      visitCnt: item?.visit_times?.value,
      newVisitNum: item?.new_visitor?.value,
      reVisitNum: item?.re_visitor?.value,
      pageView: item?.visit_pv?.value,
      pageViewConv: item?.visit_pv?.value * 100,
    };
  });
  return (
    <>
      <div id='uvDetail'>
        <div className='compHeader'>
          <div className='title'>방문현황 상세정보</div>
        </div>
        <div className='compBody'>
          <table className='table'>
            <thead>
              <tr>
                <th>날짜</th>
                <th>방문자수</th>
                <th>전일대비</th>
                <th>방문횟수</th>
                <th>신규방문자수</th>
                <th>재방문자수</th>
                <th>방문당PV</th>
              </tr>
            </thead>
            <tbody>
              {newData && newData.length > 0 ? (
                newData?.map((item, idx) => (
                  <tr className='item' key={idx}>
                    <td className='date'>{item?.date}</td>
                    <td className='visitNum'>{item?.visitNum}</td>
                    <td className='compare'>
                      {idx === 0
                        ? '0'
                        : item?.visitNum - newData[idx - 1].visitNum}
                      {(idx === 0
                        ? '0'
                        : item?.visitNum - newData[idx - 1]?.visitNum) > 0 ? (
                        <svg id='up' viewBox='0 0 24 24' width='10' height='10'>
                          <path d='M10,22.03c-.77,0-1.51-.3-2.09-.88L1.18,14.82c-1.57-1.57-1.57-4.09-.02-5.64,0,0,.01-.01,.02-.02L7.93,2.81c.84-.85,2.09-1.1,3.22-.63s1.84,1.52,1.85,2.74v2.06h7.03c2.19,0,3.97,1.8,3.97,4.01v1.98c0,2.21-1.78,4.01-3.97,4.01h-7.03v2.06c0,1.23-.71,2.28-1.85,2.75-.38,.16-.77,.23-1.15,.23Z' />
                        </svg>
                      ) : item?.visitNum - newData[idx - 1]?.visitNum < 0 ? (
                        <svg
                          id='down'
                          viewBox='0 0 24 24'
                          width='10'
                          height='10'
                        >
                          <path d='M10,22.03c-.77,0-1.51-.3-2.09-.88L1.18,14.82c-1.57-1.57-1.57-4.09-.02-5.64,0,0,.01-.01,.02-.02L7.93,2.81c.84-.85,2.09-1.1,3.22-.63s1.84,1.52,1.85,2.74v2.06h7.03c2.19,0,3.97,1.8,3.97,4.01v1.98c0,2.21-1.78,4.01-3.97,4.01h-7.03v2.06c0,1.23-.71,2.28-1.85,2.75-.38,.16-.77,.23-1.15,.23Z' />
                        </svg>
                      ) : (
                        <svg width='10' height='10' viewBox='0 0 30 512'>
                          <path d='M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z' />
                        </svg>
                      )}
                    </td>
                    <td className='visitCnt'>{item?.visitCnt}</td>
                    <td className='newVisitNum'>{item?.newVisitNum}</td>
                    <td className='reVisitNum'>{item?.reVisitNum}</td>
                    <td className='pageView'>{item?.pageView}</td>
                  </tr>
                ))
              ) : (
                <div></div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default UVDetail;
