import React, { useState, useEffect } from 'react';
import './Styles/AnalyticsMain.css';

import DailySummary from '../../Components/AnalyticsDaily/DailySummary';
import DailyGraph from '../../Components/AnalyticsDaily/DailyGraph';
import DailyDetail from '../../Components/AnalyticsDaily/DailyDetail';
import { useSelector } from 'react-redux';
import { DataApi } from '../../API';
import { useLoadingState } from '../../context/LoadingContext';
import { isMobile } from 'react-device-detect';
import { MobileChart } from '../../Components/Mobile';
function AnalyticsDaily(props) {
  props.setCurrentPage(props.pageTitle);
  const [, setLoading] = useLoadingState(true);
  const currentSite = useSelector((state) => state.currentSite);
  const period = useSelector((state) => state.period);
  const comparePeriod = useSelector((state) => state.comparePeriod);
  const [data, setData] = useState({ summary: [], row: [] });
  useEffect(() => {
    try {
      setLoading(true);
      const getApi = async () => {
        const dailyData = await DataApi.getDaily(
          currentSite.script_code,
          period,
          comparePeriod
        );
        const dailyDayData = await DataApi.getDailyRow(
          currentSite.script_code,
          period
        );
        setData({ summary: dailyData, row: dailyDayData });
      };
      getApi();
    } catch (e) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [currentSite, period, comparePeriod]);

  return (
    <>
      <DailySummary data={data?.summary} />
      <DailyGraph data={data?.row} />
      <DailyDetail data={data?.row} />
    </>
  );
}

export default AnalyticsDaily;
