import React, { useEffect } from 'react';
import './Styles/AnalyticsMain.css';
import DTDetail from '../../Components/AnalyticsDurationTime/DTDetail';
import { useSelector } from 'react-redux';
import { useLoadingState } from '../../context/LoadingContext';
function AnalyticsDurationTime(props) {
  props.setCurrentPage(props.pageTitle);
  const [, setLoading] = useLoadingState(true);
  const currentSite = useSelector((state) => state.currentSite);
  const period = useSelector((state) => state.period);
  const comparePeriod = useSelector((state) => state.comparePeriod);
  useEffect(() => {
    try {
      setLoading(true);
    } catch (e) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [currentSite, period, comparePeriod]);
  const data = [
    {
      durationTime: '00',
      visitCnt: 1317,
    },
    {
      durationTime: '01-10',
      visitCnt: 41,
    },
    {
      durationTime: '11-30',
      visitCnt: 85,
    },
    {
      durationTime: '31-60',
      visitCnt: 37,
    },
    {
      durationTime: '61-120',
      visitCnt: 41,
    },
    {
      durationTime: '121-240',
      visitCnt: 18,
    },
    {
      durationTime: '241-480',
      visitCnt: 17,
    },
    {
      durationTime: '481-960',
      visitCnt: 6,
    },
    {
      durationTime: '961-1800',
      visitCnt: 6,
    },
    {
      durationTime: '1801-3600',
      visitCnt: 0,
    },
    {
      durationTime: '3600+',
      visitCnt: 0,
    },
  ];
  return (
    <>
      <DTDetail data={data} />
    </>
  );
}

export default AnalyticsDurationTime;
