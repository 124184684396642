import { Link } from 'react-router-dom';
import styled from 'styled-components';
import './Styles/AnalyticsSideBar.css';
import Logo from './Images/logo_color.svg';
import { MobileSidebar } from './Mobile';
import { useEffect, useState } from 'react';
const AnalyticsSideBar = (props) => {
  const SideBarLink = styled(Link)`
    margin: 12px 0;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    color: var(--col-gray-4);
    cursor: pointer;
    &:hover {
      font-weight: 600;
      color: var(--col-primary-2);
    }
    &:active {
      font-weight: 700;
      color: var(--col-primary-1);
    }
  `;
  const goToAnalyticsMain = () => {
    window.location.assign('/analytics/summary');
  };

  const [isMobileView, SetIsMobileView] = useState(window.innerWidth <= 1250);

  useEffect(() => {
    const WindowResize = () => {
      SetIsMobileView(window.innerWidth <= 1250 ? true : false);
    };
    window.addEventListener('resize', WindowResize);
    return () => {
      window.removeEventListener('resize', WindowResize);
    }
  }, [])
  
  return (
    <>
      <div className='mobile_sidebar'>
        <MobileSidebar />
      </div>
      <div id='sidebar' style={{ display: isMobileView ? 'none' : 'block'}}>
        <div className='logo' onClick={goToAnalyticsMain}>
          {/* <strong>DN</strong> METRICS */}
          <img src={Logo} alt='' />
        </div>
        <div className='menu'>
          <ul className='step1'>
            <li>
              요약
              <ul className='step2'>
                <li className='sel'>
                  <SideBarLink to='/analytics/summary/'>
                    {props?.currentPage === '사이트현황' ? (
                      <strong>사이트현황</strong>
                    ) : (
                      '사이트현황'
                    )}
                  </SideBarLink>
                </li>
              </ul>
            </li>
            <li>
              방문분석
              <ul className='step2'>
                <li>
                  <SideBarLink to='/analytics/visit/uv'>
                    {props.currentPage === '방문현황' ? (
                      <strong>방문현황</strong>
                    ) : (
                      '방문현황'
                    )}
                  </SideBarLink>
                </li>
                <li>
                  <SideBarLink to='/analytics/visit/pv'>
                    {props.currentPage === '페이지뷰' ? (
                      <strong>페이지뷰</strong>
                    ) : (
                      '페이지뷰'
                    )}
                  </SideBarLink>
                </li>
                <li>
                  <SideBarLink to='/analytics/visit/timeline'>
                    {props.currentPage === '시간대방문분포' ? (
                      <strong>시간대방문분포</strong>
                    ) : (
                      '시간대방문분포'
                    )}
                  </SideBarLink>
                </li>
                <li>
                  <SideBarLink to='/analytics/visit/daily'>
                    {props.currentPage === '요일별방문분포' ? (
                      <strong>요일별방문분포</strong>
                    ) : (
                      '요일별방문분포'
                    )}
                  </SideBarLink>
                </li>
                <li className='developing'>
                  <SideBarLink to='/analytics/visit/durationTime'>
                    {props.currentPage === '방문체류시간' ? (
                      <strong>방문체류시간</strong>
                    ) : (
                      '방문체류시간'
                    )}
                  </SideBarLink>
                </li>
                <li>
                  <SideBarLink to='/analytics/visit/powerlink'>
                    {props.currentPage === '부정클릭현황' ? (
                      <strong>부정클릭현황</strong>
                    ) : (
                      '부정클릭현황'
                    )}
                  </SideBarLink>
                </li>
              </ul>
            </li>
            <li>
              유입분석
              <ul className='step2'>
                {/* <li>
                                <SideBarLink to="/analytics/inflow/summary">
                                    {props.currentPage === "전체유입현황" ? (
                                        <strong>전체유입현황</strong>
                                    ) : (
                                        "전체유입현황"
                                    )}
                                </SideBarLink>
                            </li> */}

                <li className='developing'>
                  <SideBarLink to='/analytics/inflow/search'>
                    {props.currentPage === '검색유입현황' ? (
                      <strong>검색유입현황</strong>
                    ) : (
                      '검색유입현황'
                    )}
                  </SideBarLink>
                </li>
                <li>
                  <SideBarLink to='/analytics/inflow/keyword'>
                    {props.currentPage === '유입검색어' ? (
                      <strong>유입검색어</strong>
                    ) : (
                      '유입검색어'
                    )}
                  </SideBarLink>
                </li>
                <li>
                  <SideBarLink to='/analytics/inflow/url'>
                    {props.currentPage === '유입상세URL' ? (
                      <strong>유입상세URL</strong>
                    ) : (
                      '유입상세URL'
                    )}
                  </SideBarLink>
                </li>
              </ul>
            </li>
            <li>
              페이지분석
              <ul className='step2'>
                <li>
                  <SideBarLink to='/analytics/page/popular'>
                    {props.currentPage === '인기페이지' ? (
                      <strong>인기페이지</strong>
                    ) : (
                      '인기페이지'
                    )}
                  </SideBarLink>
                </li>
                <li>
                  <SideBarLink to='/analytics/page/start'>
                    {props.currentPage === '시작페이지' ? (
                      <strong>시작페이지</strong>
                    ) : (
                      '시작페이지'
                    )}
                  </SideBarLink>
                </li>
                <li className='developing'>
                  <SideBarLink to='/analytics/page/end'>
                    {props.currentPage === '종료페이지' ? (
                      <strong>종료페이지</strong>
                    ) : (
                      '종료페이지'
                    )}
                  </SideBarLink>
                </li>
                <li className='developing'>
                  <SideBarLink to='/analytics/page/return'>
                    {props.currentPage === '반송페이지' ? (
                      <strong>반송페이지</strong>
                    ) : (
                      '반송페이지'
                    )}
                  </SideBarLink>
                </li>
              </ul>
            </li>
            {/* <li>
            실시간분석
            <ul className='step2'>
              <li className='developing'>
                <SideBarLink to='/analytics/realtime'>
                  {props.currentPage === '실시간분석' ? (
                    <strong>실시간분석</strong>
                  ) : (
                    '실시간분석'
                  )}
                </SideBarLink>
              </li>
            </ul>
          </li> */}
            <li>
              사용자분석
              <ul className='step2'>
                <li>
                  <SideBarLink to='/analytics/user/os'>
                    {props.currentPage === '운영체제' ? (
                      <strong>운영체제</strong>
                    ) : (
                      '운영체제'
                    )}
                  </SideBarLink>
                </li>
                <li>
                  <SideBarLink to='/analytics/user/browser'>
                    {props.currentPage === '웹브라우저' ? (
                      <strong>웹브라우저</strong>
                    ) : (
                      '웹브라우저'
                    )}
                  </SideBarLink>
                </li>
              </ul>
            </li>
            <li>
              이메일관리
              <ul className='step2'>
                <li>
                  <SideBarLink to='/analytics/user/email'>
                    {props.currentPage === '이메일관리' ? (
                      <strong>이메일관리</strong>
                    ) : (
                      '이메일관리'
                    )}
                  </SideBarLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AnalyticsSideBar;
