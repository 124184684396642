import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import cookies from 'react-cookies';
import './Styles/Sign.css';
import { removeCookie } from '../utils';
import { ScriptApi, UserApi } from '../API';
function Sign({ value }) {
  useEffect(() => {
    if (cookies.load('token')) {
      window.location.replace('/analytics/summary');
    }
  }, []);
  //기존 유저 정보 받아오기
  useEffect(() => {
    const getAPI = async () => {
      const { status, data } = await UserApi.getUsers();
      if (status === 200) {
        setNetworkCheck(true);
        setFindUser(data);
      }

      if (status !== 200) {
        setNetworkCheck(false);
        setSuccess('');
        setFeedback(
          '정의되지 않은 오류입니다.\n 새로고침 후 다시 시도해 주세요..'
        );
      }
    };
    getAPI();
  }, []);
  const dispatch = useDispatch();
  const [id, setId] = useState(''); // 사용자 아이디 입력
  const [pw, setPw] = useState(''); // 사용자 비밀번호 입력
  const [, setPwConfirm] = useState('');
  // 사용자 비밀번호 확인 입력
  const [email, setEmail] = useState(''); // 사용자 이메일 입력
  const [name, setName] = useState(''); // 사용자 이름 입력
  const [phone, setPhone] = useState(''); // 사용자 전화번호 입력
  const [feedback, setFeedback] = useState(''); // 로그인/회원가입 피드백
  const [success, setSuccess] = useState(''); // 로그인/회원가입 피드백
  // 로그인 비밀번호 표시 상태
  const [inPwVisible, setInPwVisible] = useState(false);
  // 회원가입 비밀번호 표시 상태
  const [upPwVisible, setUpPwVisible] = useState(false);
  // 회원가입 비밀번호 확인 표시 상태
  const [upPwConfVisible, setUpPwConfVisible] = useState(false);
  // 이메일 형식 확인 상태
  const [emailFormCheck, setEmailFormCheck] = useState(false);
  // 비밀번호 길이 확인 상태
  const [pwLengthCheck, setPwLengthCheck] = useState(false);
  // 비밀번호 알파벳, 숫자, 특수문자 확인 상태
  const [pwMultiCheck, setPwMultiCheck] = useState(false);
  // 비밀번호 연속 문자 확인 상태
  const [pwContinityCheck, setPwContinityCheck] = useState(false);
  // 비밀번호, 비밀번호 확인 일치 확인 상태
  const [pwSameCheck, setPwSameCheck] = useState(false);
  // 전화번호 형식 확인
  const [phoneCheck, setPhoneCheck] = useState(false);
  // 아이디 크 형식 확인
  const [idCheck, setIdCheck] = useState(false);
  // 기존 저장 되어있는 계정 정보 받아오기
  const [findUser, setFindUser] = useState([]);
  //아이디 중복 체크 했니?
  const [userInfoCheck, setUserInfoCheck] = useState(false);
  const [networkCheck, setNetworkCheck] = useState(false);

  const inPwHandler = () => {
    setInPwVisible(!inPwVisible);
  };
  // 회원가입 비밀번호 표시 처리
  const upPwHandler = () => {
    setUpPwVisible(!upPwVisible);
  };
  // 회원가입 비밀번호 확인 표시 처리
  const upPwConfHandler = () => {
    setUpPwConfVisible(!upPwConfVisible);
  };
  // 아이디 입력 처리
  const inputIdHandler = (e) => {
    setId(e.target.value);
  };
  // 이메일 입력 처리
  const inputEmailHandler = (e) => {
    setEmail(e.target.value);
    if (
      e.target.value.match(
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,4}$/i
      )
    ) {
      setUserInfoCheck(true);
      setEmailFormCheck(true);
    } else {
      setEmailFormCheck(false);
    }
  };
  // 비밀번호 입력 처리
  const inputPwHandler = (e) => {
    setPw(e.target.value);

    e.target.value.length >= 8 && e.target.value.length <= 16
      ? setPwLengthCheck(true)
      : setPwLengthCheck(false);
    e.target.value.match(
      /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/
    ) !== null
      ? setPwMultiCheck(true)
      : setPwMultiCheck(false);
    e.target.value.match(/(.)\1\1/) === null
      ? setPwContinityCheck(true)
      : setPwContinityCheck(false);
    if (e.target.value === '') {
      setPwContinityCheck(false);
    }
  };
  // 비밀번호 확인 입력 처리
  const inputPwConfirmHandler = (e) => {
    setPwConfirm(e.target.value);
    if (pw !== '' && e.target.value !== '') {
      pw === e.target.value ? setPwSameCheck(true) : setPwSameCheck(false);
    } else {
      setPwSameCheck(false);
    }
  };
  // 이름 입력 처리
  const inputNameHandler = (e) => {
    setName(e.target.value);
  };
  // 전화번호 입력 처리
  const inputPhoneHandler = (e) => {
    setPhone(e.target.value);
    if (/^(02\d{7,8}|01[0-9]\d{7,8})$/.test(e.target.value)) {
      setSuccess('');
      setPhoneCheck(true);
      setUserInfoCheck(true);
    } else {
      setFeedback('');
      setPhoneCheck(false);
    }
  };
  // 상태 초기화 처리
  const initState = () => {
    setId('');
    setPw('');
    setPwConfirm('');
    setEmail('');
    setName('');
    setPhone('');
    setFeedback('');
  };
  // 로그인 엔터키 처리
  const pressEnterKeyHandler = (e) => {
    if (e.key === 'Enter') {
      if (value === 'in' && id !== '' && pw !== '') {
        signIn();
      } else if (
        value === 'up' &&
        id !== '' &&
        emailFormCheck &&
        pwLengthCheck &&
        pwMultiCheck &&
        pwContinityCheck &&
        pwSameCheck &&
        name !== '' &&
        phone !== ''
      ) {
        singUp();
      }
    }
  };
  // 로그인 처리
  const signIn = async () => {
    const { status, data } = await UserApi.userLogin(id, pw);
    if (status === 200) {
      const date = 8 * 60 * 60 * 1000;
      removeCookie('METRICS_ACCECE_TOKEN', data?.token, date);
      const { type, user_id } = data;

      if (type === 'ADMIN') {
        const adminScriptData = await ScriptApi.getScripts(type);
        if (adminScriptData?.length > 0) {
          dispatch({
            type: 'SET_USER_DATA',
            payload: data,
          });
          dispatch({
            type: 'SET_SCRIPT_DATA',
            payload: adminScriptData,
          });
          dispatch({
            type: 'SET_CURRENT_SITE',
            payload: adminScriptData[0],
          });
          window.location.replace('/analytics/summary');
        } else {
          dispatch({
            type: 'SET_USER_DATA',
            payload: data,
          });
          dispatch({
            type: 'SET_CURRENT_SITE',
            payload: {},
          });
          window.location.replace('/analytics/manage');
        }
      }

      if (type === 'USER') {
        const userScriptData = await ScriptApi.getScripts(type, user_id);
        if (userScriptData?.length > 0) {
          dispatch({
            type: 'SET_USER_DATA',
            payload: data,
          });
          dispatch({
            type: 'SET_SCRIPT_DATA',
            payload: userScriptData,
          });
          dispatch({
            type: 'SET_CURRENT_SITE',
            payload: userScriptData[0],
          });
          window.location.replace('/analytics/summary');
        } else {
          dispatch({
            type: 'SET_USER_DATA',
            payload: data,
          });
          dispatch({
            type: 'SET_CURRENT_SITE',
            payload: {},
          });
          window.location.replace('/analytics/manage');
        }
      }
    }

    if (status === 203) {
      console.log('없는 아이디');
      setFeedback('존재하지 않는 아이디입니다.');
      setId('');
      setPw('');
      document.getElementById('signInUserAccount').value = '';
      document.getElementById('signInPassword').value = '';
    }

    if (status === 204) {
      console.log('비밀번호 불일치');
      setFeedback('비밀번호가 일치하지 않습니다.');
      document.getElementById('signInPassword').value = '';
      setPw('');
    }

    if (status === 500) {
      setId('');
      setPw('');
      document.getElementById('signInUserAccount').value = '';
      document.getElementById('signInPassword').value = '';
      console.log('에러 발생');
      setFeedback('정의되지 않은 오류입니다.\n다시 시도해 주세요..');
    }
  };
  //아이디 중복 검사 함수
  const IdCheckHandler = async () => {
    const checkId =
      findUser?.length > 0
        ? findUser?.filter((x) => x.user_account === id)
        : [];
    if (checkId?.length > 0) {
      setSuccess('');
      setFeedback('사용중인 아이디입니다.');
      setIdCheck(false);
    } else {
      setFeedback('');
      setSuccess('사용가능한 아이디입니다.');
      setIdCheck(true);
    }
  };
  // 회원가입 처리
  const singUp = async () => {
    try {
      const userData = {
        user_account: id,
        password: pw,
        name,
        email,
        phone,
      };
      const checkEmail =
        findUser?.length > 0 ? findUser?.filter((x) => x.email === email) : [];
      const checkPhone =
        findUser?.length > 0 ? findUser?.filter((x) => x.phone === phone) : [];
      console.log('checkEmail', checkEmail);
      if (checkPhone?.length > 0) {
        setSuccess('');
        // setPhone('');
        setFeedback('사용중인 전화번호입니다.');
        setPhoneCheck(false);
      } else if (checkEmail?.length > 0) {
        setSuccess('');
        // setEmail('');
        setFeedback('사용중인 이메일입니다.');
        setEmailFormCheck(false);
      } else {
        const { status } = await UserApi.createUser(userData);
        if (status === 200) {
          window.location.replace('/signin');
        } else {
          setSuccess('');
          setNetworkCheck(false);
          setFeedback('회원가입 실패 새로고침 후 다시 시도 해주세요.');
        }
      }
    } catch (err) {
      setSuccess('');
      setFeedback('정의되지 않은 오류입니다.\n다시 시도해 주세요..');
      console.log(err);
      return err;
    }
  };
  return (
    <div id='sign'>
      {value === 'in' ? (
        <div id='signin'>
          <h1 className='title'>로그인</h1>
          {feedback === '' ? <></> : <p className='error'>{feedback}</p>}
          <h3 className='subTitle'>아이디</h3>
          <div className='inputWrap'>
            <input
              id='signInUserAccount'
              name='user_account'
              type='text'
              placeholder='아이디를 입력해주세요.'
              onChange={inputIdHandler}
              onKeyPress={pressEnterKeyHandler}
            ></input>
          </div>
          <h3 className='subTitle'>비밀번호</h3>
          <div className='inputWrap'>
            <input
              id='signInPassword'
              name='password'
              type={inPwVisible ? 'text' : 'password'}
              placeholder='비밀번호를 입력해주세요.'
              onChange={inputPwHandler}
              onKeyPress={pressEnterKeyHandler}
            ></input>
            {inPwVisible ? (
              <svg
                onClick={inPwHandler}
                viewBox='0 0 24 24'
                width='20'
                height='20'
                fill='#2C97DE'
              >
                <path d='M23.821,11.181v0C22.943,9.261,19.5,3,12,3S1.057,9.261.179,11.181a1.969,1.969,0,0,0,0,1.64C1.057,14.739,4.5,21,12,21s10.943-6.261,11.821-8.181A1.968,1.968,0,0,0,23.821,11.181ZM12,18a6,6,0,1,1,6-6A6.006,6.006,0,0,1,12,18Z' />
                <circle cx='12' cy='12' r='4' />
              </svg>
            ) : (
              <svg
                onClick={inPwHandler}
                viewBox='0 0 24 24'
                width='20'
                height='20'
                fill='#C2C8CF'
              >
                <path d='M23.271,9.419A15.866,15.866,0,0,0,19.9,5.51l2.8-2.8a1,1,0,0,0-1.414-1.414L18.241,4.345A12.054,12.054,0,0,0,12,2.655C5.809,2.655,2.281,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162A15.866,15.866,0,0,0,4.1,18.49l-2.8,2.8a1,1,0,1,0,1.414,1.414l3.052-3.052A12.054,12.054,0,0,0,12,21.345c6.191,0,9.719-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419ZM2.433,13.534a2.918,2.918,0,0,1,0-3.068C3.767,8.3,6.782,4.655,12,4.655A10.1,10.1,0,0,1,16.766,5.82L14.753,7.833a4.992,4.992,0,0,0-6.92,6.92l-2.31,2.31A13.723,13.723,0,0,1,2.433,13.534ZM15,12a3,3,0,0,1-3,3,2.951,2.951,0,0,1-1.285-.3L14.7,10.715A2.951,2.951,0,0,1,15,12ZM9,12a3,3,0,0,1,3-3,2.951,2.951,0,0,1,1.285.3L9.3,13.285A2.951,2.951,0,0,1,9,12Zm12.567,1.534C20.233,15.7,17.218,19.345,12,19.345A10.1,10.1,0,0,1,7.234,18.18l2.013-2.013a4.992,4.992,0,0,0,6.92-6.92l2.31-2.31a13.723,13.723,0,0,1,3.09,3.529A2.918,2.918,0,0,1,21.567,13.534Z' />
              </svg>
            )}
          </div>
          <button
            className='fullBtn'
            onClick={signIn}
            disabled={id !== '' && pw !== '' ? '' : 'disabled'}
          >
            로그인하기
          </button>
          <div className='link'>
            아직 회원이 아니신가요?
            <Link to='/signup' onClick={initState}>
              회원가입
            </Link>
          </div>
          <Link to='/' className='close'>
            <svg
              x='0px'
              y='0px'
              viewBox='0 0 511.991 511.991'
              width='20'
              height='20'
            >
              <g>
                <path d='M286.161,255.867L505.745,36.283c8.185-8.474,7.951-21.98-0.523-30.165c-8.267-7.985-21.375-7.985-29.642,0   L255.995,225.702L36.411,6.118c-8.475-8.185-21.98-7.95-30.165,0.524c-7.985,8.267-7.985,21.374,0,29.641L225.83,255.867   L6.246,475.451c-8.328,8.331-8.328,21.835,0,30.165l0,0c8.331,8.328,21.835,8.328,30.165,0l219.584-219.584l219.584,219.584   c8.331,8.328,21.835,8.328,30.165,0l0,0c8.328-8.331,8.328-21.835,0-30.165L286.161,255.867z' />
              </g>
            </svg>
          </Link>
        </div>
      ) : (
        <div id='signup'>
          <h1 className='title'>회원가입</h1>
          {feedback === '' ? <></> : <p className='error'>{feedback}</p>}
          {success === '' ? <></> : <p className='success'>{success}</p>}
          <h3 className='subTitle'>아이디</h3>
          <div className='signinputWrap'>
            <input
              name='email'
              type='e-mail'
              onChange={inputIdHandler}
              placeholder='아이디를 입력해주세요.'
              onKeyPress={pressEnterKeyHandler}
            ></input>
            <input
              id='signInUserAccount'
              name='user_account'
              type='button'
              value={'중복검사'}
              onClick={IdCheckHandler}
            ></input>
          </div>
          <div className='inputGuide'></div>
          <h3 className='subTitle'>비밀번호</h3>
          <div className='inputWrap'>
            <input
              name='password'
              type={upPwVisible ? 'text' : 'password'}
              placeholder='비밀번호를 입력해주세요.'
              onChange={inputPwHandler}
              onKeyPress={pressEnterKeyHandler}
            ></input>
            {upPwVisible ? (
              <svg
                onClick={upPwHandler}
                viewBox='0 0 24 24'
                width='20'
                height='20'
                fill='#2C97DE'
              >
                <path d='M23.821,11.181v0C22.943,9.261,19.5,3,12,3S1.057,9.261.179,11.181a1.969,1.969,0,0,0,0,1.64C1.057,14.739,4.5,21,12,21s10.943-6.261,11.821-8.181A1.968,1.968,0,0,0,23.821,11.181ZM12,18a6,6,0,1,1,6-6A6.006,6.006,0,0,1,12,18Z' />
                <circle cx='12' cy='12' r='4' />
              </svg>
            ) : (
              <svg
                onClick={upPwHandler}
                viewBox='0 0 24 24'
                width='20'
                height='20'
                fill='#C2C8CF'
              >
                <path d='M23.271,9.419A15.866,15.866,0,0,0,19.9,5.51l2.8-2.8a1,1,0,0,0-1.414-1.414L18.241,4.345A12.054,12.054,0,0,0,12,2.655C5.809,2.655,2.281,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162A15.866,15.866,0,0,0,4.1,18.49l-2.8,2.8a1,1,0,1,0,1.414,1.414l3.052-3.052A12.054,12.054,0,0,0,12,21.345c6.191,0,9.719-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419ZM2.433,13.534a2.918,2.918,0,0,1,0-3.068C3.767,8.3,6.782,4.655,12,4.655A10.1,10.1,0,0,1,16.766,5.82L14.753,7.833a4.992,4.992,0,0,0-6.92,6.92l-2.31,2.31A13.723,13.723,0,0,1,2.433,13.534ZM15,12a3,3,0,0,1-3,3,2.951,2.951,0,0,1-1.285-.3L14.7,10.715A2.951,2.951,0,0,1,15,12ZM9,12a3,3,0,0,1,3-3,2.951,2.951,0,0,1,1.285.3L9.3,13.285A2.951,2.951,0,0,1,9,12Zm12.567,1.534C20.233,15.7,17.218,19.345,12,19.345A10.1,10.1,0,0,1,7.234,18.18l2.013-2.013a4.992,4.992,0,0,0,6.92-6.92l2.31-2.31a13.723,13.723,0,0,1,3.09,3.529A2.918,2.918,0,0,1,21.567,13.534Z' />
              </svg>
            )}
          </div>
          <div className='inputGuide'>
            <div className='guide'>
              <svg
                viewBox='0 0 24 24'
                width='14'
                height='14'
                fill={pwLengthCheck ? '#2C97DE' : '#C2C8CF'}
              >
                <path d='m17.582,8.461c.573.597.555,1.547-.043,2.121l-4.605,4.424c-.668.659-1.552.989-2.438.989s-1.774-.33-2.451-.991l-1.547-1.388c-.616-.554-.667-1.502-.113-2.118.554-.615,1.5-.668,2.119-.113l1.592,1.43c.237.23.555.232.746.042l4.62-4.438c.597-.574,1.545-.557,2.121.042Zm6.418,3.539c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-3,0c0-4.962-4.037-9-9-9S3,7.038,3,12s4.037,9,9,9,9-4.038,9-9Z' />
              </svg>
              8자 이상 32자 이하 입력 (공백 제외)
            </div>
            <div className='guide'>
              <svg
                viewBox='0 0 24 24'
                width='14'
                height='14'
                fill={pwMultiCheck ? '#2C97DE' : '#C2C8CF'}
              >
                <path d='m17.582,8.461c.573.597.555,1.547-.043,2.121l-4.605,4.424c-.668.659-1.552.989-2.438.989s-1.774-.33-2.451-.991l-1.547-1.388c-.616-.554-.667-1.502-.113-2.118.554-.615,1.5-.668,2.119-.113l1.592,1.43c.237.23.555.232.746.042l4.62-4.438c.597-.574,1.545-.557,2.121.042Zm6.418,3.539c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-3,0c0-4.962-4.037-9-9-9S3,7.038,3,12s4.037,9,9,9,9-4.038,9-9Z' />
              </svg>
              영문/숫자/특수문자 모두 포함
            </div>
            <div className='guide'>
              <svg
                viewBox='0 0 24 24'
                width='14'
                height='14'
                fill={pwContinityCheck ? '#2C97DE' : '#C2C8CF'}
              >
                <path d='m17.582,8.461c.573.597.555,1.547-.043,2.121l-4.605,4.424c-.668.659-1.552.989-2.438.989s-1.774-.33-2.451-.991l-1.547-1.388c-.616-.554-.667-1.502-.113-2.118.554-.615,1.5-.668,2.119-.113l1.592,1.43c.237.23.555.232.746.042l4.62-4.438c.597-.574,1.545-.557,2.121.042Zm6.418,3.539c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-3,0c0-4.962-4.037-9-9-9S3,7.038,3,12s4.037,9,9,9,9-4.038,9-9Z' />
              </svg>
              연속 3자 이상 동일한 문자/숫자 입력 제외
            </div>
          </div>
          <h3 className='subTitle'>비밀번호 확인</h3>
          <div className='inputWrap'>
            <input
              type={upPwConfVisible ? 'text' : 'password'}
              placeholder='비밀번호를 한번 더 입력해주세요.'
              onChange={inputPwConfirmHandler}
              onKeyPress={pressEnterKeyHandler}
            ></input>
            {upPwConfVisible ? (
              <svg
                onClick={upPwConfHandler}
                viewBox='0 0 24 24'
                width='20'
                height='20'
                fill='#2C97DE'
              >
                <path d='M23.821,11.181v0C22.943,9.261,19.5,3,12,3S1.057,9.261.179,11.181a1.969,1.969,0,0,0,0,1.64C1.057,14.739,4.5,21,12,21s10.943-6.261,11.821-8.181A1.968,1.968,0,0,0,23.821,11.181ZM12,18a6,6,0,1,1,6-6A6.006,6.006,0,0,1,12,18Z' />
                <circle cx='12' cy='12' r='4' />
              </svg>
            ) : (
              <svg
                onClick={upPwConfHandler}
                viewBox='0 0 24 24'
                width='20'
                height='20'
                fill='#C2C8CF'
              >
                <path d='M23.271,9.419A15.866,15.866,0,0,0,19.9,5.51l2.8-2.8a1,1,0,0,0-1.414-1.414L18.241,4.345A12.054,12.054,0,0,0,12,2.655C5.809,2.655,2.281,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162A15.866,15.866,0,0,0,4.1,18.49l-2.8,2.8a1,1,0,1,0,1.414,1.414l3.052-3.052A12.054,12.054,0,0,0,12,21.345c6.191,0,9.719-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419ZM2.433,13.534a2.918,2.918,0,0,1,0-3.068C3.767,8.3,6.782,4.655,12,4.655A10.1,10.1,0,0,1,16.766,5.82L14.753,7.833a4.992,4.992,0,0,0-6.92,6.92l-2.31,2.31A13.723,13.723,0,0,1,2.433,13.534ZM15,12a3,3,0,0,1-3,3,2.951,2.951,0,0,1-1.285-.3L14.7,10.715A2.951,2.951,0,0,1,15,12ZM9,12a3,3,0,0,1,3-3,2.951,2.951,0,0,1,1.285.3L9.3,13.285A2.951,2.951,0,0,1,9,12Zm12.567,1.534C20.233,15.7,17.218,19.345,12,19.345A10.1,10.1,0,0,1,7.234,18.18l2.013-2.013a4.992,4.992,0,0,0,6.92-6.92l2.31-2.31a13.723,13.723,0,0,1,3.09,3.529A2.918,2.918,0,0,1,21.567,13.534Z' />
              </svg>
            )}
          </div>
          <div className='inputGuide'>
            <div className='guide'>
              <svg
                viewBox='0 0 24 24'
                width='14'
                height='14'
                fill={pwSameCheck ? '#2C97DE' : '#C2C8CF'}
              >
                <path d='m17.582,8.461c.573.597.555,1.547-.043,2.121l-4.605,4.424c-.668.659-1.552.989-2.438.989s-1.774-.33-2.451-.991l-1.547-1.388c-.616-.554-.667-1.502-.113-2.118.554-.615,1.5-.668,2.119-.113l1.592,1.43c.237.23.555.232.746.042l4.62-4.438c.597-.574,1.545-.557,2.121.042Zm6.418,3.539c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-3,0c0-4.962-4.037-9-9-9S3,7.038,3,12s4.037,9,9,9,9-4.038,9-9Z' />
              </svg>
              {pwSameCheck ? '비밀번호가 같습니다.' : '비밀번호가 다릅니다.'}
            </div>
          </div>
          <h3 className='subTitle'>이름</h3>
          <div className='inputWrap'>
            <input
              name='name'
              type='text'
              onChange={inputNameHandler}
              placeholder='이름을 입력해주세요.'
              onKeyPress={pressEnterKeyHandler}
            ></input>
          </div>
          <div className='inputGuide'></div>
          <h3 className='subTitle'>전화번호</h3>
          <div className='inputWrap'>
            <input
              name='phone'
              type='tel'
              placeholder='전화번호를 입력해주세요.( - 제외 )'
              onChange={inputPhoneHandler}
              onKeyPress={pressEnterKeyHandler}
            ></input>
          </div>
          <div className='inputGuide'>
            <div className='guide'>
              <svg
                viewBox='0 0 24 24'
                width='14'
                height='14'
                fill={phoneCheck ? '#2C97DE' : '#C2C8CF'}
              >
                <path d='m17.582,8.461c.573.597.555,1.547-.043,2.121l-4.605,4.424c-.668.659-1.552.989-2.438.989s-1.774-.33-2.451-.991l-1.547-1.388c-.616-.554-.667-1.502-.113-2.118.554-.615,1.5-.668,2.119-.113l1.592,1.43c.237.23.555.232.746.042l4.62-4.438c.597-.574,1.545-.557,2.121.042Zm6.418,3.539c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-3,0c0-4.962-4.037-9-9-9S3,7.038,3,12s4.037,9,9,9,9-4.038,9-9Z' />
              </svg>
              {phoneCheck
                ? '전화번호를 올바르게 입력했습니다.'
                : '전화번호 형식이 올바르지 않습니다.'}
            </div>
          </div>
          <div className='inputGuide'></div>
          <h3 className='subTitle'>이메일</h3>
          <div className='inputWrap'>
            <input
              name='email'
              type='e-mail'
              placeholder='이메일을 입력해주세요.'
              onChange={inputEmailHandler}
              onKeyPress={pressEnterKeyHandler}
            ></input>
          </div>
          <div className='inputGuide'>
            <div className='guide'>
              <svg
                viewBox='0 0 24 24'
                width='14'
                height='14'
                fill={emailFormCheck ? '#2C97DE' : '#C2C8CF'}
              >
                <path d='m17.582,8.461c.573.597.555,1.547-.043,2.121l-4.605,4.424c-.668.659-1.552.989-2.438.989s-1.774-.33-2.451-.991l-1.547-1.388c-.616-.554-.667-1.502-.113-2.118.554-.615,1.5-.668,2.119-.113l1.592,1.43c.237.23.555.232.746.042l4.62-4.438c.597-.574,1.545-.557,2.121.042Zm6.418,3.539c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-3,0c0-4.962-4.037-9-9-9S3,7.038,3,12s4.037,9,9,9,9-4.038,9-9Z' />
              </svg>
              {emailFormCheck
                ? '이메일을 올바르게 입력했습니다.'
                : '이메일 형식이 올바르지 않습니다..'}
            </div>
          </div>
          <button
            className='fullBtn'
            onClick={singUp}
            disabled={
              networkCheck &&
              id !== '' &&
              userInfoCheck &&
              idCheck &&
              phoneCheck &&
              emailFormCheck &&
              pwLengthCheck &&
              pwMultiCheck &&
              pwContinityCheck &&
              pwSameCheck &&
              name !== '' &&
              phone !== ''
                ? ''
                : 'disabled'
            }
          >
            회원가입하기
          </button>
          <div className='link'>
            이미 회원이신가요?
            <Link to='/signin' onClick={initState}>
              로그인
            </Link>
          </div>
          <Link to='/' className='close'>
            <svg
              x='0px'
              y='0px'
              viewBox='0 0 511.991 511.991'
              width='20'
              height='20'
            >
              <g>
                <path d='M286.161,255.867L505.745,36.283c8.185-8.474,7.951-21.98-0.523-30.165c-8.267-7.985-21.375-7.985-29.642,0   L255.995,225.702L36.411,6.118c-8.475-8.185-21.98-7.95-30.165,0.524c-7.985,8.267-7.985,21.374,0,29.641L225.83,255.867   L6.246,475.451c-8.328,8.331-8.328,21.835,0,30.165l0,0c8.331,8.328,21.835,8.328,30.165,0l219.584-219.584l219.584,219.584   c8.331,8.328,21.835,8.328,30.165,0l0,0c8.328-8.331,8.328-21.835,0-30.165L286.161,255.867z' />
              </g>
            </svg>
          </Link>
        </div>
      )}
    </div>
  );
}

export default Sign;
