import "./Styles/ISDetail.css";

const ISDetail = ({ data }) => {
    const totalCnt = getTotalCount();

    function getTotalCount() {
        let temp = 0;
        for (let i = 0; i < data.length; i++) {
            temp += data[i].inflowNum;
        }
        return temp;
    }
    return (
        <>
            <div id="isDetail">
                <div className="compHeader">
                    <div className="title">방문현황 상세정보</div>
                </div>
                <div className="compBody">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>순위</th>
                                <th>검색채널</th>
                                <th>유형</th>
                                <th>유입수</th>
                                <th>비율</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, idx) => (
                                <tr className="item" key={idx}>
                                    <td className="rank">{idx + 1}</td>
                                    <td className="channel">{item.channel}</td>
                                    <td className="type">{item.type}</td>
                                    <td className="inflowNum">
                                        {item.inflowNum}
                                    </td>
                                    <td className="ratio">
                                        {(
                                            (item.inflowNum / totalCnt) *
                                            100
                                        ).toFixed(2)}
                                        %
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
export default ISDetail;
