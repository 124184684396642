import React from "react";
import "./Styles/NotFound.css";

function NotFound(props) {
    const returnLastPage = () => {
        window.history.back();
    };
    return (
        <div id="notFound">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                width="64"
                height="64"
            >
                <path d="m19,0H5C2.243,0,0,2.243,0,5v14c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5Zm-8,6c0-.553.447-1,1-1s1,.447,1,1v7.5c0,.553-.447,1-1,1s-1-.447-1-1v-7.5Zm1,13c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5,1.5.672,1.5,1.5-.672,1.5-1.5,1.5Z" />
            </svg>
            <h1>
                <strong>페이지를</strong> 찾을 수 없습니다.
            </h1>
            <div className="info">
                <p>방문하시려는 페이지의 주소가 잘못 입력되었거나,</p>
                <p>
                    페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수
                    없습니다.
                </p>
                <p>
                    입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.
                </p>
            </div>
            <button className="fullBtn" onClick={returnLastPage}>
                이전 페이지로
            </button>
        </div>
    );
}

export default NotFound;
