import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ImageSlider from './Sliders';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Styles/LandingMain.css';
import pic1 from './Images/group01.gif';
import pic2 from './Images/group2.gif';
import pic2_2 from './Images/group2_03.png';
import pic3 from './Images/group3.gif';
import pic4 from './Images/group4.gif';
import pic5 from './Images/group5.gif';
import emailreportimg2 from './Images/emailreport2.gif';
import emailreportimg1 from './Images/emailreport1.png';
import Logo from './Images/logo_white.svg';
import topupArrow from './Images/up-arrow-svgrepo-com.svg';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { getCookie, deleteCookie } from '../../utils';
import { DataApi } from '../../API';
import LandingFooter from './LandingFooter';

// ..
AOS.init();

function LandingMain() {
  const navigate = useNavigate();
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [currentPage, setCurrentPage] = useState(0);
  const goToAnalytics = async () => {
    const cookie = getCookie('METRICS_ACCECE_TOKEN');
    const authToken = async () => {
      // const data = await authcheck(cookie);
      const data = await DataApi.authcheck();
      if (!data?.user_id) {
        deleteCookie('METRICS_ACCECE_TOKEN', {});
        navigate('/signin');
      } else {
        navigate('/analytics/summary');
      }
    };
    if (cookie) {
      // console.log(cookie);
      authToken();
    } else {
      window.location.assign('/signin');
    }
  };
  /**
   * 디앤커머스 홈페이지 이동
   * --
   */
  const goDncommerce = () => {
    window.open('http://dncommerce.co.kr/contact', '_blank');
  };
  /**
   * 탑 버튼
   * --
   */
  const clickHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    // 스크립트 태그 생성
    const script = document.createElement('script');
    script.src = 'https://d2qpc2upogatfh.cloudfront.net/';
    script.async = true;
    // 스크립트 로드 완료 이벤트 핸들러
    script.onload = () => {
      // 스크립트 로드 완료 후 함수 호출
      if (window.postClientData) {
        window.postClientData('3194b29e-f6d1-4d20-a63d-4f9307a9b9f5');
      }
    };

    // 스크립트 태그를 문서에 추가
    document.body.appendChild(script);

    // 컴포넌트 언마운트 시 스크립트 제거
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const handleScroll = () => {
      const pageHeight = window.innerHeight;
      const scrollPosition = window.scrollY;
      const nextPage = Math.floor(scrollPosition / pageHeight);
      if (nextPage !== currentPage) {
        setCurrentPage(nextPage);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };
    window.addEventListener('resize', call);
  }, []);
  const [isFixed, setIsFixed] = useState(false);
  /**
   * 스크롤 감지
   * --
   */
  useEffect(() => {
    function handleScroll() {
      // 스크롤 위치를 추적
      const scrollPosition = window.scrollY;

      // 페이지 상단에서 80% 이상 아래로 스크롤되었을 때
      if (scrollPosition > window.innerHeight * 7 * 0.8) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    }

    // 스크롤 이벤트 리스너 추가
    window.addEventListener('scroll', handleScroll);

    return () => {
      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);
  }, []);
  // console.log(isFixed);
  return (
    <>
      <div id='landingMain'>
        {/* 메인 */}
        <div className='page main'>
          <ImageSlider />
          <div className='firstPageContainer'>
            <img className='Logo' src={Logo} alt='로고' />
            <h1 className='title'>
              실제 유저만 정확히 분석하고 <br />
              페이크 유저는 차단하세요.
            </h1>
            <div className='startBtns'>
              <button className='startBtn' onClick={goToAnalytics}>
                바로 시작하기
                <svg viewBox='0 0 24 24' width='16' height='16'>
                  <path d='M17.061,9.525,13.475,5.939a1.5,1.5,0,0,0-2.121,2.122L13.793,10.5H5a1.5,1.5,0,0,0,0,3h8.793l-2.439,2.439a1.5,1.5,0,0,0,2.121,2.122l3.586-3.586A3.505,3.505,0,0,0,17.061,9.525Z' />
                </svg>
              </button>
              <button className='startBtn' onClick={goDncommerce}>
                도입 문의하기
              </button>
            </div>
          </div>
        </div>
        {/* 소개 */}
        <div className='page intro'>
          <h1 data-aos='fade-up' data-aos-duration='1000' className='title'>
            DN METRICS
          </h1>
          <p data-aos='fade-up' data-aos-duration='1000' className='subtitle02'>
            온라인 마케팅 극대화 데이터마이닝 툴
          </p>
          <h3 data-aos='fade-up' data-aos-duration='1000' className='subTitle'>
            디앤 매트릭스를 도입하면 사이트의 방문자수, 방문횟수, 페이지뷰,
            검색유입 집계, 부정클릭 현황 확인 등 <br className='m_view' />
            사이트에 대한 자세한 데이터를 직접 확인할 수 있습니다. <br />
            특히, AI기반의 부정클릭 현황 체크를 통해
            <br className='m_view2' /> 의심스러운 접속을 즉시 찾아낼 수
            있습니다.
          </h3>
          <div className='layout' data-aos='fade-up' data-aos-duration='1500'>
            <div className='mainpage'>
              <img src={pic1} alt='' />
            </div>
            <h3 className='content'>
              <p>#방문현황</p>
              <p>#일일방문분포</p>
              <p>#접속채널별분석</p>
              <p>#부정클릭차단</p>
              <p>#유입검색어분석</p>
              <p>#인기페이지분석</p>
            </h3>
          </div>
        </div>
        {/* 부정클릭 */}
        <div className='page badclickmonit'>
          {/* <div style={{ width: '1200px', margin: '0 auto' }}> */}
          <h1 data-aos='fade-up' data-aos-duration='1000' className='title'>
            부정클릭 모니터링 시스템
          </h1>
          <h3 data-aos='fade-up' data-aos-duration='1000' className='subTitle'>
            AI기반의 부정클릭 모니터링 시스템을 통해 의심스러운{' '}
            <br className='m_view2' />
            접속을 즉시 확인할 수 있으며, <br className='m_view2' />
            IP주소, 검색키워드, 접속시간로그, 대역폭변경 등의
            <br className='m_view1' />
            데이터로 의도적인 접속을 유발하는 사용자를 찾아낼 수 있습니다.
          </h3>
          <div className='layout' data-aos='fade-up' data-aos-duration='1500'>
            <div className='badclick'>
              {/* <div className='badclickchild1'> */}
              <img src={pic2} alt='' />
              {/* <div className='spacer'></div> */}
              <img src={pic2_2} alt='' />
            </div>
            {screenSize > 600 ? (
              <>
                <h3 className='content'>
                  <p>#IP추적</p>
                  <p>#파워링크추적</p>
                  <p>#검색키워드추적</p>
                  <p>#로그추적</p>
                  <p>#대역폭변경추적</p>
                </h3>
              </>
            ) : (
              <>
                <h3 className='content'>
                  <p>#IP, 로그추적</p>
                  <p>#파워링크추적</p>
                  <p>#검색키워드추적</p>
                  <p>#대역폭변경추적</p>
                </h3>
              </>
            )}
          </div>
        </div>
        {/* Email report */}
        <div className='page email'>
          <h1 data-aos='fade-up' data-aos-duration='1000' className='title'>
            데일리 이메일 리포트
          </h1>
          <h3 data-aos='fade-up' data-aos-duration='1500' className='subTitle'>
            <span>방문현황(UV), 신규/재방문자 수, 방문횟수</span> 등
            <br className='m_view2' />
            방문자의 방문 특성을 이해하기 위한 종합적인 정보를{' '}
            <br className='m_view2' />
            매일 밤 자동 취합하여 이메일로 발송합니다. <br />
            중요한 고객유형(신규/재방문)이 잘 방문하는지, 방문이 집중되는
            시간대는 언제인지 등을 참고하여 사이트 운영 방식을 개선할 수
            있습니다.
          </h3>
          <div className='layout' data-aos='fade-up' data-aos-duration='1500'>
            <div className='emailimage'>
              <img src={emailreportimg1} alt='' />
              <img src={emailreportimg2} alt='' />
            </div>
          </div>
        </div>
        {/* 방문현황 */}
        <div className='page visit'>
          <h1 data-aos='fade-up' data-aos-duration='1000' className='title'>
            방문 현황
          </h1>
          <h3 data-aos='fade-up' data-aos-duration='1500' className='subTitle'>
            <span>방문현황(UV), 신규/재방문자 수, 방문횟수 등</span>
            <br className='m_view2' />
            방문자의 방문 특성을 이해하기 위한 종합적인 정보를 제공합니다.{' '}
            <br />
            중요한 고객유형(신규/재방문)이 잘 방문하는지,{' '}
            {/* <br className='m_view' /> */}
            방문이 집중되는 시간대는 언제인지 등을 참고하여{' '}
            {/* <br className='m_view4' /> */}
            사이트 운영 방식을 개선할 수 있습니다.
          </h3>
          <div className='visitpage'>
            <img
              data-aos='fade-up'
              data-aos-duration='2000'
              src={pic4}
              alt=''
            />
          </div>
        </div>
        {/* 인기페이지 */}
        <div className='page poupular'>
          <h1 data-aos='fade-up' data-aos-duration='1000' className='title'>
            인기페이지
          </h1>
          <h3 data-aos='fade-up' data-aos-duration='1500' className='subTitle'>
            내 사이트에서 가장 인기있는 페이지는 어디인지,{' '}
            {/* <br className='m_view4' /> */}
            <span>각 페이지 조회수 및 비율을 확인</span>할 수 있습니다. <br />
            인기페이지 정보를 통해 인기가 많은 콘텐츠는 강화하고,{' '}
            {/* <br className='m_view' /> */}
            그렇지 않은 콘텐츠는 보완하여 전체적인 사이트 품질을 높일 수
            있습니다.
          </h3>
          <div className='lankingpage'>
            <img
              data-aos='fade-up'
              data-aos-duration='2000'
              src={pic3}
              alt=''
            />
          </div>
        </div>
        {/* 운영체제분석 */}
        <div className='page os'>
          <h1 data-aos='fade-up' data-aos-duration='1000' className='title'>
            운영체제분석
          </h1>
          <h3 data-aos='fade-up' data-aos-duration='1500' className='subTitle'>
            내 사이트에 접속한 기기 및 각{' '}
            <span>
              기기들의 <br className='m_view2' />
              점유율을 확인
            </span>
            할 수 있습니다. <br className='m_view1' />
            이를 이용하여 점유율이 높거나 낮은 기기들의{' '}
            <br className='m_view2' />
            마케팅을 효율적으로 개선할 수 있습니다.
          </h3>
          <div className='ospage'>
            <img
              data-aos='fade-up'
              data-aos-duration='2000'
              src={pic5}
              alt=''
            />
          </div>
        </div>
        {/* 도입문의*/}
        <div className='page contact'>
          <h1 className='endcontent'>
            DN 매트릭스 도입 문의는
            <br className='m_view' /> 고객센터를 이용해 주세요.
          </h1>
          <a className='endBtn' onClick={goDncommerce}>
            CONTACT US
          </a>
        </div>
        {isFixed ? (
          <>
            {' '}
            <button
              onClick={clickHandler}
              className='fixed-button'
              style={{ transform: 'translate(-50%, -600%)' }}
            >
              {' '}
              <img width={12} src={topupArrow} alt='' />
            </button>
          </>
        ) : (
          <>
            <button onClick={clickHandler} className='fixed-button'>
              {' '}
              <img width={12} src={topupArrow} alt='' />
            </button>
          </>
        )}
        <LandingFooter />
      </div>
    </>
  );
}

export default LandingMain;
