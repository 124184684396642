import { useEffect, useRef, useState } from 'react';
import './Styles/OSGraph.css';
import { BarChart, Bar, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';

const OSGraph = ({ data }) => {
  const getData = data;
  //예외 처리
  const deviceData = getData && getData.length > 0 ? getData : [];
  // 각 os와 그 개수를 구하는 함수
  function getOsCounts(data) {
    const osCounts = {};
    const osType = {};
    const osCountsArray = [];
    //각 os 갯수 구하기
    for (const item of data) {
      if (item.is_mobile === '0') {
        const os = item.os;
        osCounts[os] = (osCounts[os] || 0) + 1;
        // console.log(item);
      } else {
        const os = item.os;
        osCounts[os] = (osCounts[os] || 0) + 1;
      }
    }
    //각 os 타입 구하기
    for (const item of data) {
      if (item.is_mobile === '0') {
        const os = item.os;
        osType[os] = 'PC';
        // console.log(item);
      } else {
        const os = item.os;
        osType[os] = '모바일';
      }
    }
    //원하는 객체로 만들기
    for (const os in osCounts) {
      osCountsArray.push({ type: osType[os], os, visitNum: osCounts[os] });
    }
    return osCountsArray;
  }

  const result = getOsCounts(deviceData);
  const [, setInnerWidth] = useState(window.innerWidth);
  const [graphWidth, setGraphWidth] = useState(
    window.innerWidth > 1920
      ? 1156
      : window.innerWidth > 1200
        ? window.innerWidth - 764
        : window.innerWidth - 422
  );
  const graphRef = useRef();
  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
      if (window.innerWidth > 1920) {
        setGraphWidth(1160);
      } else if (window.innerWidth > 1200) {
        setGraphWidth(window.innerWidth - 764);
      } else if (window.innerWidth > 960) {
        setGraphWidth(window.innerWidth - 422);
      } else {
        setGraphWidth(540);
      }
    };
    window.addEventListener('resize', resizeListener);
  });

  return (
    <>
      <div id='osGraph'>
        <div className='compHeader'>
          <div className='title'>운영체제 그래프</div>
        </div>
        <div className='compBody'>
          <div className='graph'>
            <div className='chart' ref={graphRef}>
              {/* 반응형 컨테이너 추가 */}
              <ResponsiveContainer>
                <BarChart
                  width={graphWidth}
                  height={280}
                  data={result.slice(0, 7)}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <Tooltip />
                  <XAxis dataKey='os' stroke='#686C73' />
                  <YAxis stroke='#686C73' />
                  <Bar barSize={20} dataKey='visitNum' fill='#426BDD' />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OSGraph;
