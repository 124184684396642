import React, { useState, useEffect } from 'react';
import './Styles/AnalyticsMain.css';
import ISSummary from '../../Components/AnalyticsIS/ISSummary';
import ISGraph from '../../Components/AnalyticsIS/ISGraph';
import ISDetail from '../../Components/AnalyticsIS/ISDetail';
import { useSelector } from 'react-redux';
import { useLoadingState } from '../../context/LoadingContext';
function AnalyticsInflowSearch(props) {
  props.setCurrentPage(props.pageTitle);
  const [, setLoading] = useLoadingState(true);
  const currentSite = useSelector((state) => state.currentSite);
  const period = useSelector((state) => state.period);
  const comparePeriod = useSelector((state) => state.comparePeriod);
  const [data, setData] = useState({ summary: [], row: [] });
  useEffect(() => {
    try {
      setLoading(true);
      const getApi = async () => {
        // let data = await Api.getIncomeSearch(
        //   currentSite.script_code,
        //   period,
        //   comparePeriod
        // );
        const data = {
          summary: [],
          row: [],
        };
        setData(data);
      };
      getApi();
    } catch (e) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [currentSite, period, comparePeriod, setLoading]);
  return (
    <>
      <ISSummary data={data?.summary} />
      <ISGraph data={data?.row} />
      <ISDetail data={data?.row} />
    </>
  );
}

export default AnalyticsInflowSearch;
