import React from 'react';
import './Styles/InflowByChannel.css';
import { PieChart, Pie, Cell } from 'recharts';

const COLORS = ['#2EBF6D', '#EFC563', '#E16070', '#2C97DE', '#675cb4'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
    >
      {index < 3 ? `${(percent * 100).toFixed(1)}%` : <></>}
    </text>
  );
};

const InflowByChannel = ({ data }) => {
  const urlData = data && data.length > 0 ? data : [];
  const resultArray = [];

  let searchNaver = 0;
  let searchAdNaver = 0;
  let searchDaum = 0;
  let searchGoole = 0;
  let searchNate = 0;
  let totalCnt = 0;
  // console.log(urlData);
  for (let i = 0; i < urlData?.length; i++) {
    // console.log(urlData[i].url);
    const naverQueryCheck = ['m.search.naver.com', 'search.naver.com'];
    const adNaverQueryCheck = ['m.ad.search.naver.com', 'ad.search.naver.com'];
    const daumQueryCheck = ['search.daum.net', 'm.search.daum.net'];
    const nateQuertCheck = ['search.daum.net/nate'];
    const gooleQueryCheck = [
      'www.google.co.kr',
      'www.google.com',
      'ww.google.com',
      'ww.google.co.kr',
    ];
    if (urlData[i]?.url !== '(없음)') {
      const host = new URL(urlData[i].url).href;
      if (naverQueryCheck.some((param) => host.includes(param))) {
        searchNaver++;
        totalCnt++;
      }
      if (adNaverQueryCheck.some((param) => host.includes(param))) {
        searchAdNaver++;
        totalCnt++;
      }
      if (daumQueryCheck.some((param) => host.includes(param))) {
        searchDaum++;
        totalCnt++;
      }
      if (gooleQueryCheck.some((param) => host.includes(param))) {
        searchGoole++;
        totalCnt++;
      }
      if (nateQuertCheck.some((param) => host.includes(param))) {
        searchDaum--;
        searchNate++;
        totalCnt++;
      }
    }
  }
  searchNaver > 0 &&
    resultArray.push({ name: '네이버', value: searchNaver / totalCnt });
  searchAdNaver > 0 &&
    resultArray.push({
      name: '네이버광고',
      value: searchAdNaver / totalCnt,
    });
  searchGoole > 0 &&
    resultArray.push({ name: '구글', value: searchGoole / totalCnt });
  searchDaum > 0 &&
    resultArray.push({ name: '다음', value: searchDaum / totalCnt });
  searchNate > 0 &&
    resultArray.push({ name: '네이트', value: searchNate / totalCnt });
  resultArray?.sort((a, b) => b.value - a.value);
  // console.log(resultArray);
  return (
    <>
      <div id='inflowByChannel'>
        <div className='compHeader'>
          <div className='title'>검색채널별 검색유입</div>
        </div>
        <div className='compBody'>
          <div className='graph'>
            <PieChart width={160} height={160}>
              <Pie
                data={resultArray}
                cx='50%'
                cy='50%'
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill='#8884d8'
                dataKey='value'
              >
                {resultArray?.map((entry, index) =>
                  entry?.value !== 0 ? (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ) : null
                )}
              </Pie>
            </PieChart>
          </div>
          <div className='graphInfo'>
            {resultArray?.map((item, idx) => (
              <div className='item' key={idx + 3}>
                <span style={{ background: COLORS[idx] }}></span>
                <div className='channel'>{resultArray[idx].name}</div>
                <div className='percent'>
                  {(resultArray[idx].value * 100).toFixed(1)}%
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default InflowByChannel;
