import { useEffect, useRef, useState } from 'react';
import './Styles/UVGraph.css';
import { LineChart, Line, XAxis, YAxis } from 'recharts';
import { MobileChart } from '../Mobile';

const UVGraph = ({ data }) => {
  const newData = data?.map((item) => {
    return {
      date: item?.date,
      visitNum: item?.all_visitor?.value,
      visitCnt: item?.visit_times?.value,
      newVisitNum: item?.new_visitor?.value,
      reVisitNum: item?.re_visitor?.value,
      pageView: item?.visit_pv?.value,
      pageViewConv: item?.visit_pv?.value,
    };
  });
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [graphWidth, setGraphWidth] = useState(
    window.innerWidth > 1920
      ? 1156
      : window.innerWidth > 1200
      ? window.innerWidth - 764
      : window.innerWidth - 422
  );
  const graphRef = useRef();
  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
      if (window.innerWidth > 1920) {
        setGraphWidth(1160);
      } else if (window.innerWidth > 1200) {
        setGraphWidth(window.innerWidth - 764);
      } else if (window.innerWidth > 960) {
        setGraphWidth(window.innerWidth - 422);
      } else {
        setGraphWidth(540);
      }
    };

    window.addEventListener('resize', resizeListener);


    // 클린업 함수로 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  });

  return (
    <>
      <div id='uvGraph'>

        {/* Mobile Chart */}
        <div className='mobile_chart'>
          <MobileChart
            title='일별 방문분포'
            data={newData}
            border={false}
          />
        </div>

        <div className='compHeader'>
          <div className='title'>일별 방문분포</div>
        </div>
        <div className='compBody'>
          <div className='graph'>
            <div className='chart' ref={graphRef}>
              <LineChart
                width={graphWidth}
                height={200}
                data={newData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey='date' />
                <YAxis />
                <Line
                  type='monotone'
                  dataKey='visitNum'
                  stroke='#2EBF6D'
                  borderWidth='3px'
                  activeDot={{ r: 7 }}
                />
                <Line
                  type='monotone'
                  dataKey='visitCnt'
                  stroke='#2C97DE'
                  activeDot={{ r: 7 }}
                />
                <Line
                  type='monotone'
                  dataKey='newVisitNum'
                  stroke='#E16070'
                  activeDot={{ r: 7 }}
                />
                <Line
                  type='monotone'
                  dataKey='reVisitNum'
                  stroke='#EEC763'
                  activeDot={{ r: 7 }}
                />
                <Line
                  type='monotone'
                  dataKey='pageViewConv'
                  stroke='#8C939C'
                  activeDot={{ r: 7 }}
                />
              </LineChart>
            </div>
          </div>
          <div className='graphInfo'>
            <div className='legendInfo totalNum'>
              <span></span> 방문자수
            </div>
            <div className='legendInfo totalCnt'>
              <span></span> 방문횟수
            </div>
            <div className='legendInfo newVisitNum'>
              <span></span> 신규방문자수
            </div>
            <div className='legendInfo reVisitNum'>
              <span></span> 재방문자수
            </div>
            <div className='legendInfo pageView'>
              <span></span> 방문당PV
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UVGraph;
