import './Styles/OSDetail.css';
import { useState } from 'react';
const OSDetail = ({ data }) => {
  const getData = data;
  //예외 처리
  const deviceData = getData && getData.length > 0 ? getData : [];
  const totalPageView = data?.data?.length;
  const [rowNum, setRowNum] = useState(10);
  const [startPage, setStartPage] = useState(0);
  const handleRowNum = (e) => {
    setRowNum(parseInt(e.target.value));
    setStartPage(0);
  };
  // 각 os와 그 개수를 구하는 함수
  function getOsCounts(data) {
    const osCounts = {};
    const osType = {};
    const osCountsArray = [];
    //각 os 갯수 구하기
    for (const item of data) {
      if (item.is_mobile === '0') {
        const os = item.os;
        osCounts[os] = (osCounts[os] || 0) + 1;
        // console.log(item);
      } else {
        const os = item.os;
        osCounts[os] = (osCounts[os] || 0) + 1;
      }
    }
    //각 os 타입 구하기
    for (const item of data) {
      if (item.is_mobile === '0') {
        const os = item.os;
        osType[os] = 'PC';
        // console.log(item);
      } else {
        const os = item.os;
        osType[os] = '모바일';
      }
    }
    //원하는 객체로 만들기
    for (const os in osCounts) {
      osCountsArray.push({ type: osType[os], os, visitNum: osCounts[os] });
    }
    return osCountsArray;
  }

  const result = getOsCounts(deviceData);
  result?.sort((a, b) => b.visitNum - a.visitNum);
  const totalCnt = getTotalCount();
  function getTotalCount() {
    let temp = 0;
    for (let i = 0; i < result.length; i++) {
      temp += result[i].visitNum;
    }
    return temp;
  }
  return (
    <>
      <div id='osDetail'>
        <div className='compHeader'>
          <div className='title'>운영체제 상세정보</div>

          <div className='func'>
            <div className='info'>
              운영체제 합계<strong>{totalPageView}</strong>
            </div>
            <select className='rowNum' onChange={handleRowNum}>
              <option value='10'>10개씩 보기</option>
              <option value='20'>20개씩 보기</option>
              <option value='30'>30개씩 보기</option>
              <option value='50'>50개씩 보기</option>
              <option value='100'>100개씩 보기</option>
            </select>
          </div>
        </div>
        <div className='compBody'>
          <table className='table'>
            <thead>
              <tr>
                <th>순위</th>
                <th>운영체제</th>
                <th>방문자수</th>
                <th>비율</th>
              </tr>
            </thead>
            <tbody>
              {result?.slice(startPage, startPage + rowNum).map((item, idx) => (
                <tr className='item' key={idx}>
                  <td className='rank'>{startPage + idx + 1}</td>
                  <td className='os'>
                    [{item.type}] {item.os}
                  </td>
                  <td className='visitNum'>{item.visitNum}</td>
                  <td className='ratio'>
                    {((item.visitNum / totalCnt) * 100).toFixed(2)}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='compFooter'>
          {startPage === 0 ? (
            <span></span>
          ) : (
            <button
              className='pageBtn preBtn'
              onClick={() => {
                setStartPage(startPage - rowNum);
              }}
            >
              <svg
                viewBox='0 0 24 24'
                width='12'
                height='12'
                style={{ transform: 'rotate(180deg)' }}
              >
                <path d='M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z' />
              </svg>
              이전페이지
            </button>
          )}

          {startPage + rowNum >= data?.length ? (
            <span></span>
          ) : (
            <button
              className='pageBtn nxtBtn'
              onClick={() => {
                setStartPage(startPage + rowNum);
              }}
            >
              다음페이지
              <svg viewBox='0 0 24 24' width='12' height='12'>
                <path d='M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z' />
              </svg>
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default OSDetail;
