import "./Styles/MADetail.css";

const MADetail = ({ data }) => {
    return (
        <>
            <div id="maDetail">
                <div className="compHeader">
                    <div className="title">권한관리</div>
                </div>
                <div className="compBody"></div>
            </div>
        </>
    );
};
export default MADetail;
