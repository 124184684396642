import './Styles/DataEmpty.css';
import { Empty } from 'antd';

const DataEmpty = () => {
    return (
        <>
            <Empty />
        </>
    );
};

export default DataEmpty;