import { useEffect, useRef, useState } from 'react';
import './Styles/BSGraph.css';
import { BarChart, Bar, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';

const BSGraph = ({ data }) => {
  const broswerData = data ? data : [];
  // const broswerData = getData && getData?.length > 0 ? getData : [];
  const browserCounts = {};
  for (const item of broswerData) {
    const browser =
      item.app_name === null ? '기타' : `${item.app_name} ${item.version}`;
    browserCounts[browser] = (browserCounts[browser] || 0) + 1;
  }
  const result = Object.entries(browserCounts)?.map(([browser, count]) => ({
    browser: browser,
    visitNum: count,
  }));
  // "기타" 항목을 필터링하여 새로운 배열 생성
  const filteredData = result?.filter((item) => item.browser !== '기타');
  // count를 기준으로 내림차순으로 정렬
  filteredData?.sort((a, b) => b.count - a.count);
  const [, setInnerWidth] = useState(window.innerWidth);
  const [graphWidth, setGraphWidth] = useState(
    window.innerWidth > 1920
      ? 1156
      : window.innerWidth > 1200
        ? window.innerWidth - 764
        : window.innerWidth - 422
  );
  const graphRef = useRef();
  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
      if (window.innerWidth > 1920) {
        setGraphWidth(1160);
      } else if (window.innerWidth > 1200) {
        setGraphWidth(window.innerWidth - 764);
      } else if (window.innerWidth > 960) {
        setGraphWidth(window.innerWidth - 422);
      } else {
        setGraphWidth(540);
      }
    };
    window.addEventListener('resize', resizeListener);
  });

  return (
    <>
      <div id='bsGraph'>
        <div className='compHeader'>
          <div className='title'>웹브라우저 그래프</div>
        </div>
        <div className='compBody'>
          <div className='graph'>
            <div className='chart' ref={graphRef}>
              {/* 반응형 컨테이너 추가 */}
              <ResponsiveContainer>
                <BarChart
                  width={graphWidth}
                  height={280}
                  data={filteredData?.slice(0, 10)}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <Tooltip />
                  <XAxis dataKey='browser' stroke='#686C73' />
                  <YAxis stroke='#686C73' />
                  <Bar barSize={30} dataKey='visitNum' fill='#426BDD' />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BSGraph;
