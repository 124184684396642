import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Styles/AnalyticsRatePlan.css';

function AnalyticsRatePlan(props) {
  props.setCurrentPage(props.pageTitle);
  return (
    <div id='RatePlan'>
      <div className='compHeader'>
        <div className='title'>요금 정책</div>
      </div>
      <div className='compBody'>
        <h1 className='etc'>{'준비중입니다...'}</h1>
      </div>
      {/* <svg viewBox='0 0 24 24' width='64' height='64'>
        <path d='m19,0H5C2.243,0,0,2.243,0,5v14c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5Zm-8,6c0-.553.447-1,1-1s1,.447,1,1v7.5c0,.553-.447,1-1,1s-1-.447-1-1v-7.5Zm1,13c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5,1.5.672,1.5,1.5-.672,1.5-1.5,1.5Z' />
      </svg>
      <h1>
        {domain?.split(' ')[0]}에
        <br />
        <strong>비정상 접속</strong>이 감지되었습니다.
      </h1>
      <div className='info'>
        <p>
          현재 접속하신 기기로 파워링크 <strong>부정클릭</strong>이 확인되고
          있습니다.
        </p>
        <p>
          파워링크 비용 소진을 목적으로 지속 접속시, <strong>법적 조치</strong>
          가 취해질 수 있습니다.
        </p>
      </div>
      <div className='log'>
        <p>
          <strong>ip: </strong>
          {ip}
        </p>
        {log ? (
          log.map((item, idx) => (
            <>
              <p>{'{'}</p>
              <p className='step2'>
                <strong>date: </strong>
                {item.date}
              </p>
              <p className='step2'>
                <strong>time: </strong>
                {item.time}
              </p>
              <p>{'},'}</p>
            </>
          ))
        ) : (
          <></>
        )}
      </div>
      <button
        className='fullBtn'
        onClick={() => {
          window.close();
        }}
      >
        창 닫기
      </button> */}
    </div>
  );
}

export default AnalyticsRatePlan;
