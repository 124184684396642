import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

// 랜딩 페이지
import LandingMain from './Pages/Landing/LandingMain';

// 애널리틱스 레이아웃 (데스크톱)
import AnalyticsLayout from './Components/AnalyticsLayout';
// 모바일 레이아웃
import { MobileLayout } from './Components/Mobile';

// 애널리틱스 페이지
import AnalyticsMain from './Pages/Analytics/AnalyticsMain';
import AnalyticsVisit from './Pages/Analytics/AnalyticsVisit';
import AnalyticsPageView from './Pages/Analytics/AnalyticsPageView';
import AnalyticsTimeLine from './Pages/Analytics/AnalyticsTimeLine';
import AnalyticsDaily from './Pages/Analytics/AnalyticsDaily';
import AnalyticsDurationTime from './Pages/Analytics/AnalyticsDurationTime';
import AnalyticsPoupularPage from './Pages/Analytics/AnalyticsPoupularPage';
import AnalyticsInflowKeyword from './Pages/Analytics/AnalyticsInflowKeyword';
import AnalyticsStartPage from './Pages/Analytics/AnalyticsStartPage';
import AnalyticsEndPage from './Pages/Analytics/AnalyticsEndPage';
import AnalyticsReturnPage from './Pages/Analytics/AnalyticsReturnPage';
import AnalyticsOs from './Pages/Analytics/AnalyticsOs';
import AnalyticsBrowser from './Pages/Analytics/AnalyticsBrowser';
import AnalyticsResolution from './Pages/Analytics/AnalyticsResolution';
import ScrollToTop from './Components/ScrollToTop';
import AnalyticsInflowUrl from './Pages/Analytics/AnalyticsInflowUrl';
import AnalyticsInflowSearch from './Pages/Analytics/AnalyticsInflowSearch';
import AnalyticsPowerlink from './Pages/Analytics/AnalyticsInPowerlink';
import AnalyticsEmail from './Pages/Analytics/AnalyticsEmail';
import AnalyticsRatePlan from './Pages/Analytics/AnalyticsRatePlan';
import AnalyticsManage from './Pages/Analytics/AnalyticsManage';
import AnalyticsUserManage from './Pages/Analytics/AnalyticsUserManage';
import { Sign, Loading, NotFound } from './Pages/index';
// Webview
import { isMobile } from 'react-device-detect';

// import Sign from './Pages/Sign';
// import NotFound from './Pages/NotFound';
// import Loading from './Pages/Loading';
// import Block from './Pages/Block';
// import RatePlan from './Pages/RatePlan';


function App() {
  // Web, App Device check
  const [currentPage, setCurrentPage] = useState('사이트현황');

  return (
    <div className='App'>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/signin' element={<Sign value='in' />} />
          <Route path='/signup' element={<Sign value='up' />} />
          <Route path='/' element={<LandingMain />} />
          {/* <Route element={
            isMobile ? (
              <MobileLayout currentPage={currentPage} />
            ) : (
              <AnalyticsLayout currentPage={currentPage} />
            )} > */}
            <Route
              element={
                <div className='layout'>
                  <div className='desktopLayout'>
                    <AnalyticsLayout currentPage={currentPage}/>
                  </div>
                  <div className='mobileLayout'>
                    <MobileLayout currentPage={currentPage} />
                  </div>
                </div>
              }
            >
            <Route
              path='/analytics/rateplan'
              element={
                <AnalyticsRatePlan
                  pageTitle='요금 정책'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/manage'
              element={
                <AnalyticsManage
                  pageTitle='사이트 및 권한 관리'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/usermanage'
              element={
                <AnalyticsUserManage
                  pageTitle='회원정보 관리'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/summary'
              element={
                <AnalyticsMain
                  pageTitle='사이트현황'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/visit/uv'
              element={
                <AnalyticsVisit
                  pageTitle='방문현황'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/visit/pv'
              element={
                <AnalyticsPageView
                  pageTitle='페이지뷰'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/visit/timeline'
              element={
                <AnalyticsTimeLine
                  pageTitle='시간대방문분포'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/visit/daily'
              element={
                <AnalyticsDaily
                  pageTitle='요일별방문분포'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/visit/powerlink'
              element={
                <AnalyticsPowerlink
                  pageTitle='부정클릭현황'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/visit/durationTime'
              element={
                <AnalyticsDurationTime
                  pageTitle='방문체류시간'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            {/* <Route
                            path="/analytics/inflow/summary"
                            element={<AnalyticsMain />}
                            pageTitle="전체유입현황"
                            setCurrentPage={setCurrentPage}
                        /> */}
            <Route
              path='/analytics/inflow/search'
              element={
                <AnalyticsInflowSearch
                  pageTitle='검색유입현황'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/inflow/keyword'
              element={
                <AnalyticsInflowKeyword
                  pageTitle='유입검색어'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/inflow/url'
              element={
                <AnalyticsInflowUrl
                  pageTitle='유입상세URL'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/page/popular'
              element={
                <AnalyticsPoupularPage
                  pageTitle='인기페이지'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/page/start'
              element={
                <AnalyticsStartPage
                  pageTitle='시작페이지'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/page/end'
              element={
                <AnalyticsEndPage
                  pageTitle='종료페이지'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/page/return'
              element={
                <AnalyticsReturnPage
                  pageTitle='반송페이지'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/realtime'
              element={
                <Loading
                  pageTitle='실시간분석'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/user/os'
              element={
                <AnalyticsOs
                  pageTitle='운영체제'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/user/browser'
              element={
                <AnalyticsBrowser
                  pageTitle='웹브라우저'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/user/resolution'
              element={
                <AnalyticsResolution
                  pageTitle='화면해상도'
                  setCurrentPage={setCurrentPage}
                />
              }
            />
            <Route
              path='/analytics/user/email'
              element={
                <AnalyticsEmail
                  pageTitle='이메일관리'
                  setCurrentPage={setCurrentPage}
                />
              }
            />

          </Route>
          <Route path='/*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;