import { useState, useEffect } from 'react';
import './Styles/PopularPage.css';
import { isMobile } from 'react-device-detect';

const PopularPage = ({ data }) => {
  const [newData, setNewData] = useState([]);
  const totalPageView = getTotal(data, 'pv');

  function getTotal(arr, key) {
    let temp = 0;
    for (let i = 0; i < arr.length; i++) {
      temp += arr[i][key];
    }
    return temp;
  }

  useEffect(() => {
    const grouped = {};

    data?.forEach((item) => {
      if (item?.url) {
        try {
          const protocol = `${new URL(item?.url).protocol}//`;
          const domain = new URL(item?.url)?.hostname;
          const newdomain = domain.includes('www')
            ? domain.replace('www.', '')
            : domain;
          const search = new URL(item?.url)?.search;
          const pathname = new URL(item?.url)?.pathname;
          const path = pathname === '/' ? '' : pathname;
          const queryCheck = ['n_media', 'n_query', 'fbclid', 'n_auery', 's'];
          const findQuery = queryCheck.some((param) =>
            search.includes(`?${param}`)
          );

          const route = findQuery
            ? `${protocol}${newdomain}`
            : `${protocol}${newdomain}${path}`;
          if (!grouped[route]) {
            grouped[route] = { url: route, pv: 0, avgTime: 0 };
          }

          grouped[route].pv += item?.pv || 0;
          grouped[route].avgTime += item?.avgTime || 0;
        } catch (error) {
          console.error(`Invalid URL: ${item?.url}`);
        }
      }
    });
    const limitData = Object.values(grouped)
      ?.sort((a, b) => b.pv - a.pv)
      ?.slice(0, 10);
    setNewData(limitData);
  }, [data]);
  return (
    <>
      <div id='popularPage'>
        <div className='compHeader'>
          <div className='title'>TOP10 인기페이지</div>
        </div>
        <div className='compBody'>
          <table className='rank'>
            <thead>
              <tr>
                <th className='no'>순위</th>
                <th>페이지 URL</th>
                <th>페이지뷰</th>
                <th className='percent'>비율</th>
                {/* <th>평균체류시간</th> */}
              </tr>
            </thead>
            <tbody>
              {newData && newData?.length > 0
                ? newData?.map((item, idx) => (
                  <tr className='item' key={`${item?.pv}-${idx}`}>
                    <td className='no'>{idx + 1}</td>
                    <td className='url'>
                      <a href={item?.url}>{decodeURIComponent(item?.url)}</a>
                    </td>
                    <td className='pageView'>{item?.pv}</td>
                    <td className='percent'>
                      {((item?.pv / totalPageView) * 100).toFixed(1)}%
                    </td>
                    {/* <td className='avgTime'>{item?.avgTime}초</td> */}
                  </tr>
                ))
                : [
                  <tr className='item' key={`notdata`}>
                    <td className='no'>{1}</td>
                    <td className='url'>
                      <p>{'-'}</p>
                    </td>
                    <td className='pageView'>{0}</td>
                    <td className='percent'>{0}%</td>
                    {/* <td className='avgTime'>{0}초</td> */}
                  </tr>,
                ]}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default PopularPage;
