import React, { useState, useEffect } from 'react';
import './Styles/AnalyticsMain.css';
import OSSummary from '../../Components/AnalyticsOS/OSSummary';
import OSGraph from '../../Components/AnalyticsOS/OSGraph';
import OSDetail from '../../Components/AnalyticsOS/OSDetail';
import { DataApi } from '../../API';
import { useSelector } from 'react-redux';
import { useLoadingState } from '../../context/LoadingContext';
function AnalyticsOs(props) {
  props.setCurrentPage(props.pageTitle);
  const [, setLoading] = useLoadingState(true);
  const currentSite = useSelector((state) => state.currentSite);
  const period = useSelector((state) => state.period);
  const comparePeriod = useSelector((state) => state.comparePeriod);
  const [data, setData] = useState([]);
  useEffect(() => {
    try {
      setLoading(true);
      const getApi = async () => {
        const data = await DataApi.getDevice(currentSite.script_code, period);
        setData(data);
      };
      getApi();
    } catch (e) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [currentSite, period, comparePeriod]);

  return (
    <>
      <OSSummary data={data} />
      <OSGraph data={data} />
      <OSDetail data={data} />
    </>
  );
}

export default AnalyticsOs;
