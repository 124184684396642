import { useEffect, useRef, useState } from 'react';
import './Styles/DailyGraph.css';
import { LineChart, Line, XAxis, YAxis } from 'recharts';
import { MobileChart } from '../Mobile';

const DailyGraph = ({ data }) => {
  const [, setInnerWidth] = useState(window.innerWidth);
  const [graphWidth, setGraphWidth] = useState(
    window.innerWidth > 1920
      ? 1156
      : window.innerWidth > 1200
        ? window.innerWidth - 764
        : window.innerWidth - 422
  );
  const graphRef = useRef();
  const [graphData, setGraphData] = useState([]);
  

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
      if (window.innerWidth > 1920) {
        setGraphWidth(1160);
      } else if (window.innerWidth > 1200) {
        setGraphWidth(window.innerWidth - 764);
      } else if (window.innerWidth > 960) {
        setGraphWidth(window.innerWidth - 422);
      } else {
        setGraphWidth(540);
      }
    };
    window.addEventListener('resize', resizeListener);
  });

  useEffect(() => {
    const newData = data?.map((item) => ({
      date: item?.date,
      [item?.all_visitor?.name]: item?.all_visitor?.value,
      [item?.new_visitor?.name]: item?.new_visitor?.value,
      [item?.page_view?.name]: item?.page_view?.value,
      [item?.re_visitor?.name]: item?.re_visitor?.value,
      [item?.visit_times?.name]: item?.visit_times?.value,
    }));
    // newData?.map((item, i) => {
    //   console.log();

    //   setGraphData(item);
    // });
    setGraphData(newData);
  }, [data]);
  

  return (
    <>
      <div id='dailyGraph'>

        {/* Mobile Chart */}
        <div className='mobile_chart'>
          <MobileChart
            title='요일별 방문그래프'
            data={graphData}
            border={false}
          />
        </div>

        <div className='compHeader'>
          <div className='title'>요일별 방문그래프</div>
        </div>
        <div className='compBody'>
          <div className='graph'>
            <div className='chart' ref={graphRef}>
              <LineChart
                width={graphWidth}
                height={200}
                data={graphData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey='date' />
                <YAxis />
                <Line
                  type='monotone'
                  dataKey='방문자수'
                  stroke='#2CBF6D'
                  borderWidth='3px'
                  activeDot={{ r: 7 }}
                />
                <Line
                  type='monotone'
                  dataKey='신규방문자수'
                  stroke='#E16070'
                  activeDot={{ r: 7 }}
                />
                <Line
                  type='monotone'
                  dataKey='페이지뷰'
                  stroke='#8C939C'
                  activeDot={{ r: 7 }}
                />
                <Line
                  type='monotone'
                  dataKey='재방문자수'
                  stroke='#EFC663'
                  activeDot={{ r: 7 }}
                />
                <Line
                  type='monotone'
                  dataKey='방문횟수'
                  stroke='#2C97DE'
                  activeDot={{ r: 7 }}
                />
              </LineChart>
            </div>
          </div>
          <div className='graphInfo'>
            <div className='legendInfo totalNum'>
              <span></span> 방문자수
            </div>
            <div className='legendInfo totalCnt'>
              <span></span> 방문횟수
            </div>
            <div className='legendInfo newVisitNum'>
              <span></span> 신규방문자수
            </div>
            <div className='legendInfo reVisitNum'>
              <span></span> 재방문자수
            </div>
            <div className='legendInfo pageView'>
              <span></span> 페이지뷰
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DailyGraph;
