import React, { useEffect } from 'react';
import './Styles/AnalyticsMain.css';
import EPDetail from '../../Components/AnalyticsPage/EPDetail';
import { useSelector } from 'react-redux';
import { useLoadingState } from '../../context/LoadingContext';
function AnalyticsEndPage(props) {
  props.setCurrentPage(props.pageTitle);
  const [, setLoading] = useLoadingState(true);
  const currentSite = useSelector((state) => state.currentSite);
  const period = useSelector((state) => state.period);
  const comparePeriod = useSelector((state) => state.comparePeriod);
  useEffect(() => {
    try {
      setLoading(true);
    } catch (e) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [currentSite, period, comparePeriod]);
  const data = [
    {
      url: 'http://twoguys2015.com/창업안내/',
      pv: 564,
    },
    {
      url: 'http://twoguys2015.com/매장찾기/',
      pv: 458,
    },
    {
      url: 'http://twoguys2015.com/',
      pv: 278,
    },
    {
      url: 'http://twoguys2015.com/치킨메뉴/',
      pv: 121,
    },
    {
      url: 'http://twoguys2015.com/피자메뉴/',
      pv: 95,
    },
    {
      url: 'http://twoguys2015.com/치밥메뉴/',
      pv: 38,
    },
    {
      url: 'http://twoguys2015.com/매장찾기/page/3/',
      pv: 25,
    },
    {
      url: 'http://twoguys2015.com/매장찾기/page/2/',
      pv: 22,
    },
    {
      url: 'http://twoguys2015.com/브랜드소개/',
      pv: 11,
    },
    {
      url: 'http://twoguys2015.com/인사말/',
      pv: 5,
    },
    {
      url: 'http://twoguys2015.com/보도자료/',
      pv: 4,
    },
    {
      url: 'http://twoguys2015.com/창업절차-및-비용/',
      pv: 3,
    },
    {
      url: 'http://twoguys2015.com/â���ȳ�/',
      pv: 1,
    },
    {
      url: 'http://twoguys2015.com/인증서/',
      pv: 1,
    },
  ];
  return (
    <>
      <EPDetail data={data} />
    </>
  );
}

export default AnalyticsEndPage;
