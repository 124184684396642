import './Styles/RSSummary.css';

const PVSummary = ({ data, comparePeriod }) => {
  return (
    <>
      <div id='rsSummary'>
        <div className='compHeader'>
          <div className='title'>화면해상도 요약</div>
        </div>

        <div className='compBody'>
          {data.map((item, idx) => {
            return (
              <div className='item' key={idx}>
                <div className='itemHeader'>
                  <div className='title'>{item.title}</div>
                </div>
                <div className='itemBody'>
                  <div className='rs'>{item.rs}</div>
                  <div className='info ratio2'>{item.ratio}%</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default PVSummary;
