import './Styles/OSSummary.css';

const PVSummary = ({ data }) => {
  let getData = data;
  const deviceData = getData && getData.length > 0 ? getData : [];
  let allDevice = 0;
  let pc = 0;
  let mobile = 0;
  //   (device[index]?.value / allDevice) * 100).toFixed(1)
  const device = [
    {
      title: 'PC 웹 점유율',
      os: 0,
      ratio: 0,
    },
    {
      title: '모바일 점유율',
      os: 0,
      ratio: 0,
    },
    {
      title: 'PC 최다 OS',
      os: '',
      ratio: 0,
    },
    {
      title: '모바일 최다 OS',
      os: '',
      ratio: 0,
    },
  ];
  if (deviceData.length > 0) {
    //유니크한 OS찾기
    const mobileData = deviceData?.filter((item) => item.is_mobile === '0');
    const pcData = deviceData?.filter((item) => item.is_mobile === '1');
    // 각 경우에서 가장 많이 등장하는 os와 그 개수 찾기 함수(재활용 하자고)
    function findMostFrequentOs(data) {
      const osCounts = {};

      for (const item of data) {
        const os = item.os;
        osCounts[os] = (osCounts[os] || 0) + 1;
      }

      let mostFrequentOs = '';
      let num = 0;
      let maxCount = 0;
      for (const os in osCounts) {
        num += osCounts[os];
        if (osCounts[os] > maxCount) {
          mostFrequentOs = os;
          maxCount = osCounts[os];
        }
      }

      return { os: mostFrequentOs, count: maxCount, total: num };
    }
    // is_mobile 값이 0인 경우
    const mobileResult = findMostFrequentOs(mobileData);
    // is_mobile 값이 1인 경우
    const pcResult = findMostFrequentOs(pcData);
    device[2].os = mobileResult.os;
    device[3].os = pcResult.os;
    deviceData?.forEach((item) => {
      allDevice++;
      if (item?.is_mobile === '0') {
        device[0].os += 1;
        pc++;
      } else {
        device[1].os += 1;
        mobile++;
      }
    });
    device[0].ratio = ((pc / allDevice) * 100).toFixed(1);
    device[1].ratio = ((mobile / allDevice) * 100).toFixed(1);
    device[2].ratio = ((pcResult.count / pcResult.total) * 100).toFixed(1);
    device[3].ratio = ((mobileResult.count / mobileResult.total) * 100).toFixed(
      1
    );
  }
  return (
    <>
      <div id='osSummary'>
        <div className='compHeader'>
          <div className='title'>운영체제 요약</div>
        </div>

        <div className='compBody'>
          {device?.map((item, idx) => {
            return (
              <div className='item' key={idx}>
                <div className='itemHeader'>
                  <div className='title'>{item.title}</div>
                </div>
                <div className='itemBody'>
                  {item.os === '' ? (
                    <div className='info ratio1'>
                      <strong>{item.ratio}</strong>%
                    </div>
                  ) : (
                    <>
                      <div className='os'>{item.os}</div>
                      <div className='info ratio2'>{item.ratio}%</div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default PVSummary;
