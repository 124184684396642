import APIConstant from './APIContant';
import APIManager from './APIManager';
import { parameterToPath } from '../utils/index';
const $http = new APIManager();
const Api = {
  /**
   * 스크립트 전체 조회
   * --
   * @method GET
   * @param {*} type : type
   * @param {*} uid :user_id
   * @returns
   */
  getScripts: async (type, uid) => {
    try {
      const query = {
        type: type,
        user_id: uid,
      };
      const { status, data } = await $http.get(APIConstant.GET_SCRIPTS, query);
      if (status === 200) {
        return data;
      }
      return [];
    } catch (e) {
      throw e;
    }
  },
  /**
   * 스크립트 단일 조회
   * ---
   * @param {*} script_id
   * @returns
   */
  getScript: async (script_id) => {
    try {
      const url = parameterToPath(APIConstant.GET_SCRIPT, { script_id });
      const { status, data } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      return [];
    } catch (e) {
      throw e;
    }
  },
  /**
   * 스크립트 생성
   * ---
   * @param {*} userId : 유저ID
   * @param {*} email : 유저EMAIL
   * @param {*} domain : 도메인
   * @param {*} url : 주소
   * @returns
   */
  createScript: async (userId, email, domain, url) => {
    try {
      let tempUrl = url;
      tempUrl = tempUrl.replace('http://', '');
      tempUrl = tempUrl.replace('https://', '');
      tempUrl = tempUrl.split('/')[0];
      const siteData = {
        user_id: userId,
        email: email,
        domain: domain,
        url: tempUrl,
      };
      const data = await $http.post(APIConstant.CREATE_SCRIPT, siteData);
      return data;
    } catch (e) {
      throw e;
    }
  },
  /**
   * 스크립트 업데이트
   * ---
   * @param {*} scriptData
   * @returns
   */
  updateScript: async (scriptData) => {
    try {
      const { script_id, email } = scriptData;
      const url = parameterToPath(APIConstant.UPDATE_SCRIPT, { script_id });
      const data = await $http.put(url, { email });
      return data;
    } catch (e) {
      throw e;
    }
  },
  /**
   * 스크립트 삭제
   * ---
   * @param {*} scriptcode
   * @returns
   */
  deleteScript: async (scriptcode) => {
    try {
      const data = await $http.delete(APIConstant.DELETE_SCRIPT, {
        script_code: scriptcode,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
};
export default Api;
