import { useEffect, useRef, useState } from "react";
import "./Styles/RSGraph.css";
import { BarChart, Bar, Tooltip, XAxis, YAxis } from "recharts";

const RSGraph = ({ data }) => {
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [graphWidth, setGraphWidth] = useState(
        window.innerWidth > 1920
            ? 1156
            : window.innerWidth > 1200
            ? window.innerWidth - 764
            : window.innerWidth - 422
    );
    const graphRef = useRef();
    useEffect(() => {
        const resizeListener = () => {
            setInnerWidth(window.innerWidth);
            if (window.innerWidth > 1920) {
                setGraphWidth(1160);
            } else if (window.innerWidth > 1200) {
                setGraphWidth(window.innerWidth - 764);
            } else if (window.innerWidth > 960) {
                setGraphWidth(window.innerWidth - 422);
            } else {
                setGraphWidth(540);
            }
        };
        window.addEventListener("resize", resizeListener);
    });

    return (
        <>
            <div id="rsGraph">
                <div className="compHeader">
                    <div className="title">화면해상도 그래프</div>
                </div>
                <div className="compBody">
                    <div className="graph">
                        <div className="chart" ref={graphRef}>
                            <BarChart
                                width={graphWidth}
                                height={280}
                                data={data.slice(0, 7)}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <Tooltip />
                                <XAxis dataKey="rs" stroke="#686C73" />
                                <YAxis stroke="#686C73" />
                                <Bar
                                    barSize={30}
                                    dataKey="visitNum"
                                    fill="#426BDD"
                                />
                            </BarChart>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RSGraph;
