import React from 'react';
import cookie from 'react-cookies';
import './Styles/AnalyticsMain.css';
import MSDetail from '../../Components/AnalyticsMN/MSDetail';
import MADetail from '../../Components/AnalyticsMN/MADetail';

function AnalyticsManage(props) {
  props.setCurrentPage(props.pageTitle);

  console.log(cookie.load('codeData'));
  const data = {
    sites: cookie.load('codeData'),
    auth: {
      grant: [],
      get: [],
    },
  };
  return (
    <>
      <div id='manage'>
        <MSDetail data={data.sites} />
        <MADetail data={data.auth} />
      </div>
    </>
  );
}

export default AnalyticsManage;
