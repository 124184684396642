import "./Styles/RSDetail.css";

const RSDetail = ({ data }) => {
    const totalCnt = getTotalCount();

    function getTotalCount() {
        let temp = 0;
        for (let i = 0; i < data.length; i++) {
            temp += data[i].visitNum;
        }
        return temp;
    }
    return (
        <>
            <div id="rsDetail">
                <div className="compHeader">
                    <div className="title">화면해상도 상세정보</div>
                </div>
                <div className="compBody">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>순위</th>
                                <th>해상도</th>
                                <th>방문자수</th>
                                <th>비율</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, idx) => (
                                <tr className="item" key={idx}>
                                    <td className="rank">{idx + 1}</td>
                                    <td className="rs">{item.rs}</td>
                                    <td className="visitNum">
                                        {item.visitNum}
                                    </td>
                                    <td className="ratio">
                                        {(
                                            (item.visitNum / totalCnt) *
                                            100
                                        ).toFixed(2)}
                                        %
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
export default RSDetail;
