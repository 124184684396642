import React, { useEffect, useState } from 'react';
import './Styles/AnalyticsMain.css';
import SummaryVisit from '../../Components/AnalyticsMain/SummaryVisit';
import DistVisitByDay from '../../Components/AnalyticsMain/DistVisitByDay';
import InflowByChannel from '../../Components/AnalyticsMain/InflowByChannel';
import InflowByDevice from '../../Components/AnalyticsMain/InflowByDevice';
import InflowKeyword from '../../Components/AnalyticsMain/InflowKeyword';
import PopularPage from '../../Components/AnalyticsMain/PopularPage';
import { useLoadingState } from '../../context/LoadingContext';
import { DataApi } from '../../API';
import { useSelector } from 'react-redux';

function AnalyticsMain(props) {
  const currentSite = useSelector((state) => state.currentSite);
  const period = useSelector((state) => state.period);
  const comparePeriod = useSelector((state) => state.comparePeriod);
  const [, setLoading] = useLoadingState(true);
  const [PVData, setPVData] = useState({ summary: [], row: [] });
  const [UVData, setUVData] = useState({ summary: [], row: [] });
  const [PPData, setPPData] = useState([]);
  const [IKData, setIKData] = useState([]);
  const [DeviceData, setDeviceData] = useState([]);
  const [UrlData, setUrlData] = useState([]);
  useEffect(() => {
    try {
      setLoading(true);
      const getApi = async () => {
        /**
         * 페이지 뷰
         * ---
         */
        const pvData = await DataApi.getPV(
          currentSite.script_code,
          period,
          comparePeriod
        );
        const pvRowData = await DataApi.getPVRow(
          currentSite.script_code,
          period
        );
        setPVData({ summary: pvData, row: pvRowData });
        /**
         * 방문현황 요약
         * ---
         */
        const uvData = await DataApi.getUV(
          currentSite.script_code,
          period,
          comparePeriod
        );
        const uvRowData = await DataApi.getUVRow(
          currentSite.script_code,
          period
        );
        setUVData({ summary: uvData, row: uvRowData });
        /**
         * 유입검색어
         * ----
         */
        const keywordData = await DataApi.getKeyword(
          currentSite.script_code,
          period,
          comparePeriod
        );
        setIKData(keywordData);
        /**
         * 인기페이지
         * ----
         */
        const PPData = await DataApi.getPopularPage(
          currentSite.script_code,
          period
        );
        setPPData(PPData);
        /**
         * 디바이스 조회
         * ----
         */
        const deviceData = await DataApi.getDevice(
          currentSite.script_code,
          period,
          comparePeriod
        );
        setDeviceData(deviceData);
        /**
         * 검색채널별 조회
         * ----
         */
        const UrlData = await DataApi.getIncomeUrl(
          currentSite.script_code,
          period,
          comparePeriod
        );
        setUrlData(UrlData);
      };
      getApi();
    } catch (e) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [currentSite, period, comparePeriod, setLoading]);

  props.setCurrentPage(props.pageTitle);
  return (
    <>
      <SummaryVisit data={{ PVData: PVData, UVData: UVData }} />
      <DistVisitByDay data={{ PVData: PVData, UVData: UVData }} />
      <div className='c2-1'>
        <div className='c2-1-1'>
          <InflowByChannel data={UrlData} />
          <InflowByDevice data={DeviceData} /> 
        </div>
        <div className='c2-1-2'>
          <InflowKeyword data={IKData} />
        </div>
      </div>
      <PopularPage data={PPData} />
    </>
  );
}

export default AnalyticsMain;
