import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import './MobileLayout.css';

// Mobile Component
import MobileSidebar from '../MobileSidebar';
import MobileHeader from '../MobileHeader';
import MobileFooter from '../MobileFooter';
import { MobilePeriod } from '../../Components';

// Desktop Component
import Loading from '../../../../Pages/Loading';
import { useLoadingState } from '../../../../context/LoadingContext';

const MobileLayout = ({
  currentPage, // Header에 들어가는 페이지명
}) => {

  /* ===== State ===== */
  // 로딩 상태 
  const [loading, setLoading] = useLoadingState()
  const [isPeriod, setIsPeriod] = useState(false);

  /* ===== Function ===== */
  /* ===== Hooks ===== */
  
  // Period 컴포넌트가 필요없는 페이지
  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath === '/analytics/usermanage') {
      setIsPeriod(false);
    }
    else if (currentPath === '/analytics/manage') {
      setIsPeriod(false);
    }
    else if (currentPath === '/analytics/user/email') {
      setIsPeriod(false);
    }
    else {
      setIsPeriod(true);
    }
  })

  /* ===== Render ===== */
  return (
    <>
      {loading ? <Loading /> : <></>}
      <div className='mobile_layout_container'>
        <MobileSidebar />

        {/* Sidebar */}

        {/* Header */}
        <div className='mobile_layout_header'>
          <MobileHeader
            title={currentPage}
          />
        </div>

        {/* Content */}
        <div className='mobile_layout_content'>
          {
            isPeriod ? (
              <MobilePeriod />
            ) : (
              <></>
            )
          }
          <Outlet />
        </div>

        {/* Footer */}
        <div className='mobile_layout_footer'>
          <MobileFooter />
        </div>
      </div>
    </>
  );
};

export default MobileLayout;