import { useState, useEffect, useMemo } from 'react';
import './Styles/IUDetail.css';
import { isMobile } from 'react-device-detect';

const getPlatform = (url) => {
  if (url.includes('naver.com')) return 'Naver';
  if (url.includes('google.com')) return 'Google';
  if (url.includes('daum.net')) return 'Daum';
  if (url.includes('instagram.com')) return 'Instagram';
  if (url.includes('facebook.com')) return 'Facebook';
  return 'Others';
};
const IUDetail = ({ data }) => {
  const iuData = useMemo(() => {
    return data?.length > 1 ? data : [{ url: '', inflowNum: 0 }];
  }, [data]);
  const [rowNum, setRowNum] = useState(10);
  const [startPage, setStartPage] = useState(0);
  const [newData, setNewData] = useState([]);
  const [platformCount, SetPlatformCount] = useState({
    Naver: 0,
    Google: 0,
    Daum: 0,
    Instagram: 0,
    Facebook: 0,
    Others: 0,
  });
  /* ===== FUNCTION ===== */
  const handleRowNum = (e) => {
    setRowNum(parseInt(e.target.value));
    setStartPage(0);
  };
  const totalPageView = getTotal(data, 'inflowNum');

  function getTotal(arr, key) {
    let temp = 0;
    for (let i = 0; i < arr.length; i++) {
      temp += arr[i][key];
    }
    return temp;
  }

  // useEffect(() => {
  //   const grouped = {};
  //   data?.forEach((item) => {
  //     if (item?.url) {
  //       try {
  //         const decodeurl = decodeURIComponent(item.url);
  //         const searchParams = new URL(decodeurl).searchParams;
  //         const searchKeyword =
  //           searchParams.get('q') || searchParams.get('query');
  //         // console.log(decodeurl.get('query').replace(/\s+/g, ''));
  //         console.log(decodeURIComponent(new URL(decodeurl).search));
  //         // console.log(searchKeyword, 'searchKeyword');
  //         const route = searchParams;
  //         if (!grouped[route]) {
  //           grouped[route] = { url: route, inflowNum: 0 };
  //         }
  //         grouped[route].inflowNum += item?.inflowNum || 0;
  //       } catch (error) {
  //         console.error(`Invalid URL: ${item.url}`);
  //       }
  //     }
  //   });
  // }, [data]);

  /* ===== RENDER ===== */

  useEffect(() => {
    const grouped = {};
    const stats = {
      Naver: 0,
      Google: 0,
      Daum: 0,
      Instagram: 0,
      Facebook: 0,
      Others: 0,
    };
    iuData?.forEach((item) => {
      if (item?.url) {
        try {
          const protocol =
            item?.url === '(없음)' ? '' : `${new URL(item.url).protocol}//`;
          const domain =
            item?.url === '(없음)' ? item?.url : new URL(item.url).hostname;
          const route = `${protocol}${domain}`;
          const platform = getPlatform(route);

          if (!grouped[route]) {
            grouped[route] = { url: route, inflowNum: 0 };
          }
          grouped[route].inflowNum += item?.inflowNum || 0;
          stats[platform] += item?.inflowNum || 0;
        } catch (error) {
          console.error(`Invalid URL: ${item.url}`);
        }
      }
    });
    setNewData(Object.values(grouped));
    SetPlatformCount(stats);
  }, [iuData]);
  // URL 별 내림차순 정렬
  newData?.sort((a, b) => b.inflowNum - a.inflowNum);
  // 플랫폼별 통계 내림차순 정렬
  const sortedPlatformCount = Object.entries(platformCount)
    .sort(([, a], [, b]) => b - a)
    .reduce((acc, [platform, inflowNum]) => {
      acc[platform] = inflowNum;
      return acc;
    }, {});

  return (
    <>
      <div id='iuDetail'>
        <div className='compHeader'>
          <div className='title'>유입상세URL</div>
          <div className='func'>
            <div className='info'>
              검색유입합계<strong>{totalPageView}</strong>
            </div>
            <select className='rowNum' onChange={handleRowNum}>
              <option value='10'>10개씩 보기</option>
              <option value='20'>20개씩 보기</option>
              <option value='30'>30개씩 보기</option>
              <option value='50'>50개씩 보기</option>
              <option value='100'>100개씩 보기</option>
            </select>
          </div>
        </div>
        <div className='compBody'>
          <table className='rank' style={{marginBottom : '2rem'}}>
            <thead>
              <tr>
                <th>순위</th>
                <th>플랫폼</th>
                <th>유입수</th>
                <th>비율</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(sortedPlatformCount).map(([platform, inflowNum], idx) => (
                <tr key={idx} className='item'>
                  <td className='no'>{ idx + 1}</td>
                  <td className='url' style={{display : 'flex',  justifyContent : 'center'}}>{platform}</td>
                  <td className='inflowNum'>{inflowNum}</td>
                  <td className='ratio'>
                      <div className='barWrap'>
                        <span
                          className='bar'
                          style={{
                            width:
                              ((inflowNum / totalPageView) * 100).toFixed(
                                1
                              ) + '%',
                          }}
                        ></span>
                      </div>
                      <div className='value'>
                        {((inflowNum / totalPageView) * 100).toFixed(1)}%
                      </div>
                    </td>
                  
                </tr>
              ))}
            </tbody>
          </table>
          <table className='rank'>
            <thead>
              <tr>
                <th>순위</th>
                <th>페이지URL</th>
                <th>유입수</th>
                <th>비율</th>
              </tr>
            </thead>
            <tbody>
              {newData
                ?.slice(startPage, startPage + rowNum)
                ?.map((item, idx) => (
                  <tr className='item'>
                    <td className='no'>{startPage + idx + 1}</td>
                    <td className='url'>
                      {item.url === '(direct)' ? (
                        <>{item.url}</>
                      ) : (
                        <a href={item.url} target='_blank' rel='noreferrer'>
                          {item.url}
                        </a>
                      )}
                    </td>
                    <td className='inflowNum'>{item.inflowNum}</td>
                    <td className='ratio'>
                      <div className='barWrap'>
                        <span
                          className='bar'
                          style={{
                            width:
                              ((item.inflowNum / totalPageView) * 100).toFixed(
                                1
                              ) + '%',
                          }}
                        ></span>
                      </div>
                      <div className='value'>
                        {((item.inflowNum / totalPageView) * 100).toFixed(1)}%
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='compFooter'>
          {startPage === 0 ? (
            <span></span>
          ) : (
            <button
              className='pageBtn preBtn'
              onClick={() => {
                setStartPage(startPage - rowNum);
              }}
              style={{marginLeft : '1rem'}}
            >
              <svg
                viewBox='0 0 24 24'
                width='12'
                height='12'
                style={{ transform: 'rotate(180deg)'}}
              >
                <path d='M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z' />
              </svg>
              이전페이지
            </button>
          )}

          {startPage + rowNum >= data?.length ? (
            <span></span>
          ) : (
            <button
              className='pageBtn nxtBtn'
              onClick={() => {
                setStartPage(startPage + rowNum);
              }}
              style={{marginRight : '1rem'}}
            >
              다음페이지
              <svg viewBox='0 0 24 24' width='12' height='12'>
                <path d='M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z' />
              </svg>
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default IUDetail;
