import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ScriptApi } from '../../API';
import './Styles/Email.css';

const Email = ({ data }) => {
  const userData = useSelector((state) => state.userData);
  const [newData, setNewData] = useState([]);
  const [editData, seteditData] = useState([]);
  const [addSitePopUp, setAddSitePopUp] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [emailFormCheck, setEmailFormCheck] = useState(false);
  const popupHandler = (e) => {
    setFeedback('');
    seteditData([]);
    setAddSitePopUp(!addSitePopUp);
  };
  const blockBubbling = (event) => {
    event.stopPropagation();
  };
  const inputSiteUrlHandler = (e) => {
    const value = e.target.value;
    seteditData(value);
    if (
      value.match(
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,4}$/i
      )
    ) {
      seteditData(value);
      setEmailFormCheck(true);
    } else {
      setEmailFormCheck(false);
    }
  };
  /* ===== RENDER ===== */
  useEffect(() => {
    setNewData(data);
  }, [data]);
  const deleteEmail = async (e) => {
    const index = e.target.value;
    if (window.confirm('정말 삭제 하시겠습니까?')) {
      //삭제 버튼 클릭시 해당 인덱스 번호를 가지고 인덱스 번호의 이메일 삭제 후 새 배열로 리턴
      const [filterData] = newData?.map((item) => {
        const script_id = item?.script_id;
        item?.email.splice(index, 1);
        return { email: item.email, script_id: script_id };
      });
      const updateData = {
        script_id: filterData?.script_id,
        email: filterData?.email,
      };
      const { status } = await ScriptApi.updateScript(updateData);
      if (status === 200) {
        window.alert('이메일 등록 성공');
      } else {
        window.alert('이메일 등록 실패');
      }
      if (status === 200) {
        window.alert('이메일 삭제가 완료되었습니다.');
        window.location.reload();
      } else {
        window.alert('다시 시도 해주세요.');
        window.location.reload();
      }
    }
  };

  const createEamil = async () => {
    try {
      if (editData?.length !== 0 && !emailFormCheck) {
        setFeedback('이메일을 정확하게 입력해주세요');
        return;
      }
      setFeedback('');
      if (window.confirm('이메일을 추가하시겠습니까?')) {
        //이메일 마지막 인덱스에 추가(배열은 인덱스가 0부터 시작이기 떄문에) 2개 존재시 인덱스 0,1 길이는2 즉 길이는 마지막 인덱스를 가르킨다.
        const [filterData] = newData?.map((item) => {
          const script_id = item?.script_id;
          const emailCount = item?.email?.length;
          item.email[emailCount] = editData;
          return { email: item.email, script_id: script_id };
        });
        //새로운 배열로 만들어서 스크립트 업데이트
        const updateData = {
          type: userData?.type,
          script_id: filterData?.script_id,
          email: filterData?.email,
          user_id: userData?.user_id,
        };
        const { status } = await ScriptApi.updateScript(updateData);
        if (status === 200) {
          window.alert('이메일 등록 성공');
        } else {
          window.alert('이메일 등록 실패');
        }
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };
  return (
    <>
      <div id='Email'>
        <div className='compHeader'>
          <div className='title'>이메일 현황</div>
        </div>
        <div className='compBody'>
          <table className='rank'>
            <thead>
              <tr>
                <th className='no'>순서</th>
                <th>스크립트 코드</th>
                <th>사이트명</th>
                <th>사이트URL</th>
                <th>이메일</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {newData?.map((mainitem) => {
                return mainitem?.email?.map((item, idx) => (
                  <tr className='item' key={`${item?.script_code}-${idx}`}>
                    <td className='no'>{idx + 1} </td>
                    <td className='script_code'>{mainitem?.script_code}</td>
                    <td className='domain'>{mainitem?.domain}</td>
                    <td className='url'>{mainitem?.url}</td>
                    <td className='email'>{item}</td>
                    <td className='btn'>
                      <button
                        className='tdBtn'
                        value={idx}
                        onClick={deleteEmail}
                      >
                        삭제
                      </button>
                    </td>
                  </tr>
                ));
              })}
            </tbody>
          </table>
          <button className='fullBtn' onClick={popupHandler}>
            추가
          </button>
        </div>
      </div>

      {/* 등록화면 */}
      {addSitePopUp ? (
        <div id='addSite' onClick={popupHandler}>
          <div className='content' onClick={blockBubbling}>
            <div className='close' onClick={popupHandler}>
              <svg viewBox='0 0 24 24' width='32' height='32'>
                <path d='M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z' />
              </svg>
            </div>
            <h1 className='title'>이메일 등록</h1>
            {feedback !== '' ? <p className='error'>{feedback}</p> : <></>}
            <h3 className='subTitle'>이메일 주소</h3>
            <div className='inputWrap'>
              <input
                id='Email'
                name='email'
                placeholder='이메일 주소를 입력해주세요.'
                onChange={inputSiteUrlHandler}
              ></input>
            </div>
            <button className='fullBtn' onClick={createEamil}>
              등록하기
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default Email;
