import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './Styles/AnalyticsMain.css';
import TLSummary from '../../Components/AnalyticsTimeLine/TLSummary';
import TLGraph from '../../Components/AnalyticsTimeLine/TLGraph';
import TLDetail from '../../Components/AnalyticsTimeLine/TLDetail';
import { DataApi } from '../../API';
import { useLoadingState } from '../../context/LoadingContext';
import { isMobile } from 'react-device-detect';
import { MobileChart } from '../../Components/Mobile';
function AnalyticsTimeLine(props) {
  // const dd = props.setCurrentPage(props.pageTitle);
  props.setCurrentPage(props.pageTitle);
  const [, setLoading] = useLoadingState(true);
  const currentSite = useSelector((state) => state.currentSite);
  const period = useSelector((state) => state.period);
  const comparePeriod = useSelector((state) => state.comparePeriod);
  const [TimeData, setTimeData] = useState([]);
  const [TimeDayData, setTimeDayData] = useState([]);
  useEffect(() => {
    try {
      setLoading(true);
      const getApi = async () => {
        const getTimeLine = await DataApi.getTimeLine(
          currentSite.script_code,
          period
        );
        const getTimeLineDay = await DataApi.getTimeLine(
          currentSite.script_code,
          period
        );

        setTimeData(getTimeLine);
        setTimeDayData(getTimeLineDay);
        // if (data) {
        //   setLoading(false);
        // }
      };
      getApi();
    } catch (e) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [currentSite, period, comparePeriod]);

  /**
     * 데이터 그룹화 함수
     * --
     */
  const groupByTime = (data) => {
    return data.reduce((acc, item) => {
      const time = item.time;
      if (!acc[time]) {
        acc[time] = {
          time: time || 0,
          visitNum: 0,
          visitCnt: 0,
          newVisitNum: 0,
          reVisitNum: 0,
          pageView: 0,
        };
      }
      acc[time].visitNum += item.visitNum || 0;
      acc[time].visitCnt += item.visitCnt || 0;
      acc[time].newVisitNum += item.newVisitNum || 0;
      acc[time].reVisitNum += item.reVisitNum || 0;
      acc[time].pageView += item.pageView || 0;
      return acc;
    }, {});
  };

  const groupedData = groupByTime(TimeDayData);
  const newData = Object.values(groupedData);

  /**
   * 그룹 데이터 정렬
   * --
   */
  const sortedData = newData.sort((a, b) => {
    const hour = a.time.split(':').map(Number);
    const minute = b.time.split(':').map(Number);
    return hour[0] - minute[0] || hour[1] - minute[1];
  });

  return (
    <>
      <TLSummary data={TimeData} />
      <TLGraph data={sortedData} />
      <TLDetail data={TimeDayData} />
    </>
  );
}

export default AnalyticsTimeLine;
