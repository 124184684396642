import React, { useState, useEffect } from 'react';
import './MobileHeader.css';
import AnalyticsDropDown from '../../../AnalyticsDropDown';
import { useSelector, useDispatch } from 'react-redux';
import { DataApi } from '../../../../API';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

const MobileHeader = ({
    title, // Header에 들어가는 페이지명
}) => {
    const dispatch = useDispatch();
    const scriptData = useSelector((state) => state.scriptData);
    const currentSite = useSelector((state) => state.currentSite);
    const [dropDownVisible, setDropDownVisible] = useState(false);
    const [pvData, setPvData] = useState(0);

    const pagePrint = () => {
        window.print();
    };

    const dropDownHandler = () => {
        setDropDownVisible(!dropDownVisible);
    };

    const mouseOutHandler = () => {
        setDropDownVisible(false);
    };

    const selectHandler = (e) => {
        dispatch({
            type: 'SET_CURRENT_SITE',
            payload: scriptData[e.target.value],
        });
    };

    useEffect(() => {
        try {
            const getApi = async () => {
                /**
                 * 인기페이지
                 * ----
                 */
                const now = moment.tz('Asia/Seoul');
                // 현재 날짜를 가져옵니다.
                const firstDayOfMonth = now
                    .clone()
                    .subtract(1, 'months')
                    .startOf('month')
                    .format('YYYY-MM-DD');
                const lastDayOfMonth = now
                    .clone()
                    .subtract(1, 'months')
                    .endOf('month')
                    .format('YYYY-MM-DD');
                const pvDatas = await DataApi.getPV(
                    currentSite?.script_code,
                    [firstDayOfMonth, lastDayOfMonth],
                    []
                );
                const pvData = pvDatas[0];
                setPvData(pvData);
            };
            getApi();
        } catch (e) {
            console.error(e.message);
        }
    }, [currentSite?.script_code]);

    return (
        <div className='mobile_header_container'>
            <div id='mobile_header'>
                <div className='rightSide'>
                    <div className='selWrap'>
                        <select name='' id='selSite' onChange={selectHandler}>
                            {scriptData?.length === 0 ? (
                                <option value='noSite'>등록된 사이트가 없습니다.</option>
                            ) : (
                                scriptData?.map((item, idx) => (
                                    <option
                                        key={idx}
                                        value={idx}
                                        selected={
                                            currentSite?.domain === item?.domain ? true : false
                                        }
                                    >
                                        {item?.domain}({item?.url})
                                    </option>
                                ))
                            )}
                        </select>
                        <svg viewBox='0 0 24 24' width='12' height='12'>
                            <path d='M6.414,9H17.586a1,1,0,0,1,.707,1.707l-5.586,5.586a1,1,0,0,1-1.414,0L5.707,10.707A1,1,0,0,1,6.414,9Z' />
                        </svg>
                    </div>
                    <div className='btn_box'>
                        <Link to='/analytics/rateplan' className='fullBtn' style={{ textDecoration: "none" }}>
                            요금 정책
                        </Link>
                        <div className='btn' onClick={pagePrint}>
                            <svg viewBox='0 0 24 24' width='30' height='20'>
                                <rect x='5' width='14' height='5' />
                                <rect x='6' y='15' width='12' height='9' />
                                <path d='M21,7H3a3,3,0,0,0-3,3V20H4V13H20v7h4V10A3,3,0,0,0,21,7Zm-2,4H15V9h4Z' />
                            </svg>
                        </div>
                        <div className='btn' onClick={dropDownHandler}>
                            <svg viewBox='0 0 24 24' width='30' height='20'>
                                <path d='M21,12a9.143,9.143,0,0,0-.15-1.645L23.893,8.6l-3-5.2L17.849,5.159A9,9,0,0,0,15,3.513V0H9V3.513A9,9,0,0,0,6.151,5.159L3.107,3.4l-3,5.2L3.044,10.355A9.1,9.1,0,0,0,0,13.4L.107,15.4l3,5.2,3.044-1.758A9,9,0,0,0,9,20.487V24h6V20.487a9,9,0,0,0,2.849-1.646L20.893,20.6l3-5.2L20.85,13.645A9.143,9.143,0,0,0,21,12Zm-6,0a3,3,0,1,1-3-3A3,3,0,0,1,15,12Z' />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='leftSide'>
                    <div className='pageTitle'>{title}</div>
                    <span className='PageViewTitle'>
                        전월 총 페이지뷰 {pvData?.cur ? pvData?.cur : 0}회
                    </span>
                </div>
                {dropDownVisible ? (
                    <AnalyticsDropDown
                        onMouseOut={mouseOutHandler}
                        setDropDownVisible={setDropDownVisible}
                    />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default MobileHeader;
