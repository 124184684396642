import './Styles/DTDetail.css';

const DTDetail = ({ data }) => {
  const totalCnt = getTotalCount();
  function getTotalCount() {
    let temp = 0;
    for (let i = 0; i < data.length; i++) {
      temp += data[i].visitCnt;
    }
    return temp;
  }
  return (
    <>
      <div id='dtDetail'>
        <div className='compHeader'>
          <div className='title'>체류시간 분포</div>
        </div>
        <div className='compBody'>
          <div className='summary'>
            <div className='item'>
              방문횟수합계<strong>{totalCnt}</strong>회
            </div>
            <div className='item'>
              평균 체류시간<strong>19</strong>초
            </div>
          </div>
          <table className='table'>
            <thead>
              <tr>
                <th style={{ width: '15%' }}>체류시간</th>
                <th style={{ width: '15%' }}>방문횟수</th>
                <th style={{ width: '70%' }}>비율</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, idx) => (
                <tr className='item' key={idx}>
                  <td className='date'>{item.durationTime}초</td>
                  <td className='visitCnt'>{item.visitCnt}</td>
                  <td className='ratio'>
                    <div className='barWrap'>
                      <span
                        className='bar'
                        style={{
                          width:
                            ((item.visitCnt / totalCnt) * 100).toFixed(2) + '%',
                        }}
                      ></span>
                    </div>
                    {((item.visitCnt / totalCnt) * 100).toFixed(2)}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default DTDetail;
