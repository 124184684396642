import React, { useState, useEffect } from 'react';
import './Styles/AnalyticsMain.css';
// import PreiodPicker from '../../Components/PreiodPicker';
import Powerlink from '../../Components/AnalyticsPowerlink/Powerlink';
import { useSelector } from 'react-redux';
import { DataApi } from '../../API';
import { useLoadingState } from '../../context/LoadingContext';
function AnalyticsPowerlink(props) {
  props.setCurrentPage(props.pageTitle);
  const currentSite = useSelector((state) => state.currentSite);
  const period = useSelector((state) => state.period);
  const [data, setData] = useState([]);
  const [, setLoading] = useLoadingState(true);
  useEffect(() => {
    try {
      setLoading(true);
      const getApi = async () => {
        const data = await DataApi.getPowerLink(
          currentSite.script_code,
          period
        );
        const filterdata = data?.row?.map((item) => ({
          ...item,
          mobile: data?.mobile.filter((subitem) => item.ip === subitem.ip)[0],
        }));
        setData(filterdata);
      };
      getApi();
    } catch (e) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [currentSite, period]);
  return (
    <>
      <Powerlink data={data} />
    </>
  );
}

export default AnalyticsPowerlink;
