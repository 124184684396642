import React from 'react';
import { useSelector } from 'react-redux';
import './Styles/UVSummary.css';

const UVSummary = ({ data }) => {
  const period = useSelector((state) => state.period);
  // console.log(data);
  return (
    <>
      <div id='uvSummary'>
        <div className='compHeader'>
          <div className='title'>방문현황 요약</div>
          <div className='info'>
            등락 비교일 {period[0]}~{period[1]}
          </div>
        </div>

        <div className='compBody'>
          {data?.map((item, idx) => (
            <div className='item' key={idx}>
              <div className='itemHeader'>
                <div className='title'>{item?.title}</div>
                <div className='value'>{item?.cur}</div>
              </div>
              <div className='itemBody'>
                <div className='info'>
                  <strong>{item?.pre}</strong>
                </div>
                <div className='info'>
                  {idx == 4
                    ? (item?.cur - item?.pre).toFixed(2)
                    : item?.cur - item?.pre}
                  (
                  {item.pre
                    ? item?.cur - item?.pre < 0
                      ? `${(
                          ((item?.cur - item?.pre) / item?.pre) *
                          100
                        ).toFixed(1)}%`
                      : `+${(
                          ((item?.cur - item?.pre) / item?.pre) *
                          100
                        ).toFixed(1)}%`
                    : '100%'}
                  )
                  {/* {(item.cur - item.pre).toFixed(2)}(
                  {((item?.cur / (item?.cur - item.pre)) * 100).toFixed(1)}
                  %) */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default UVSummary;
