import React, { useState, useEffect } from 'react';
import './Styles/AnalyticsMain.css';
import BSSummary from '../../Components/AnalyticsBS/BSSummary';
import BSGraph from '../../Components/AnalyticsBS/BSGraph';
import BSDetail from '../../Components/AnalyticsBS/BSDetail';
import { useSelector } from 'react-redux';
import { DataApi } from '../../API';
import { useLoadingState } from '../../context/LoadingContext';
import { isMobile } from 'react-device-detect';
import { MobileChart } from '../../Components/Mobile';
function AnalyticsBrowser(props) {
  props.setCurrentPage(props.pageTitle);
  const [, setLoading] = useLoadingState(true);
  const currentSite = useSelector((state) => state.currentSite);
  const period = useSelector((state) => state.period);
  const comparePeriod = useSelector((state) => state.comparePeriod);
  const [data, setData] = useState([]);

  useEffect(() => {
    try {
      setLoading(true);
      const getApi = async () => {
        const data = await DataApi.getBrowser(currentSite.script_code, period);
        setData(data);
      };
      getApi();
    } catch (e) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [currentSite, period, comparePeriod]);
  return (
    <>
      <BSSummary data={data} />
      <BSGraph data={data} />
      <BSDetail data={data} />
    </>
  );
}

export default AnalyticsBrowser;
