import './Styles/InflowByDevice.css';
import { PieChart, Pie, Cell } from 'recharts';

const COLORS = ['#675CB4', '#74A3FA', '#8C939C'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
    >
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  );
};

const InflowByDevice = ({ data }) => {
  const deviceData = data;
  const device = [
    { name: 'PC', value: 0 },
    { name: '모바일', value: 0 },
  ];
  let allDevice = 0;
  if (deviceData) {
    deviceData?.forEach((item) => {
      allDevice++;
      if (item?.is_mobile === '0') {
        device[0].value += 1;
      } else {
        device[1].value += 1;
      }
    });
  }
  device?.sort((a, b) => b.value - a.value);
  return (
    <>
      <div id='inflowByDevice'>
        <div className='compHeader'>
          <div className='title'>접속환경별 검색유입</div>
        </div>
        <div className='compBody'>
          <div className='graph'>
            <PieChart width={160} height={160}>
              <Pie
                data={device}
                cx='50%'
                cy='50%'
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill='#8884d8'
                dataKey='value'
              >
                {device?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </div>
          <div className='graphInfo'>
            {device?.map((item, index) => (
              <div className='item' key={index + 1}>
                <span style={{ background: COLORS[index] }}></span>
                <div className='channel'>{device[index]?.name}</div>
                <div className='percent'>
                  {((device[index]?.value / allDevice) * 100).toFixed(1)}%
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default InflowByDevice;
