import React from 'react';
import cookie from 'react-cookies';
import './Styles/AnalyticsManage.css';
import UserDetail from '../../Components/AnalyticsUserMN/UserDetail';

function AnalyticsUserManage(props) {
  props.setCurrentPage(props.pageTitle);
  
  const data = {
    sites: cookie.load('codeData'),
    auth: {
      grant: [],
      get: [],
    },
  };
  return (
    <>
      <div id='manage'>
        <UserDetail data={data.sites} />
      </div>
    </>
  );
}

export default AnalyticsUserManage;
