import { useState } from 'react';
import './Styles/IKDetail.css';

const IKDetail = ({ data }) => {
  const [rowNum, setRowNum] = useState(10);
  const [startPage, setStartPage] = useState(0);
  const handleRowNum = (e) => {
    setRowNum(parseInt(e.target.value));
    setStartPage(0);
  };
  const totalPageView = getTotal(data, 'inflowNum');

  function getTotal(arr, key) {
    let temp = 0;
    for (let i = 0; i < arr.length; i++) {
      temp += arr[i][key];
    }
    return temp;
  }
  data?.sort((a, b) => b.inflowNum - a.inflowNum);
  return (
    <>
      <div id='ikDetail'>
        <div className='compHeader'>
          <div className='title'>유입검색어</div>
          <div className='func'>
            <div className='info'>
              검색유입합계<strong>{totalPageView}</strong>
            </div>
            <select className='rowNum' onChange={handleRowNum}>
              <option value='10'>10개씩 보기</option>
              <option value='20'>20개씩 보기</option>
              <option value='30'>30개씩 보기</option>
              <option value='50'>50개씩 보기</option>
              <option value='100'>100개씩 보기</option>
            </select>
          </div>
        </div>
        <div className='compBody'>
          <table className='rank'>
            <thead>
              <tr>
                <th>순위</th>
                <th>검색어</th>
                <th>유입수</th>
                <th>비율</th>
              </tr>
            </thead>
            <tbody>
              {data?.slice(startPage, startPage + rowNum)?.map((item, idx) => (
                <tr className='item' key={idx + 1}>
                  <td className='no'>{startPage + idx + 1}</td>
                  <td className='keyword'>{item?.keyword}</td>
                  <td className='inflowNum'>{item?.inflowNum}</td>
                  <td className='ratio'>
                    <div className='barWrap'>
                      <span
                        className='bar'
                        style={{
                          width:
                            ((item?.inflowNum / totalPageView) * 100).toFixed(
                              1
                            ) + '%',
                        }}
                      ></span>
                    </div>
                    <div className='value'>
                      {((item?.inflowNum / totalPageView) * 100).toFixed(1)}%
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='compFooter'>
          {startPage === 0 ? (
            <span></span>
          ) : (
            <button
              className='pageBtn preBtn'
              onClick={() => {
                setStartPage(startPage - rowNum);
              }}
              style={{marginLeft : '20px'}}
            >
              <svg
                viewBox='0 0 24 24'
                width='12'
                height='12'
                style={{ transform: 'rotate(180deg)' }}
              >
                <path d='M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z' />
              </svg>
              이전페이지
            </button>
          )}

          {startPage + rowNum >= data?.length ? (
            <span></span>
          ) : (
            <button
              className='pageBtn nxtBtn'
              onClick={() => {
                setStartPage(startPage + rowNum);
              }}
              style={{marginRight : '20px'}}
            >
              다음페이지
              <svg viewBox='0 0 24 24' width='12' height='12'>
                <path d='M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z' />
              </svg>
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default IKDetail;
