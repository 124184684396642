import React, { useEffect } from 'react';
import './Styles/AnalyticsMain.css';
import RSSummary from '../../Components/AnalyticsRS/RSSummary';
import RSGraph from '../../Components/AnalyticsRS/RSGraph';
import RSDetail from '../../Components/AnalyticsRS/RSDetail';
import { useSelector } from 'react-redux';
import { useLoadingState } from '../../context/LoadingContext';
function AnalyticsResolution(props) {
  props.setCurrentPage(props.pageTitle);
  const [, setLoading] = useLoadingState(true);
  const currentSite = useSelector((state) => state.currentSite);
  const period = useSelector((state) => state.period);
  const comparePeriod = useSelector((state) => state.comparePeriod);
  // const [data, setData] = useState([]);
  useEffect(() => {
    try {
      setLoading(true);
    } catch (e) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [currentSite, period, comparePeriod]);
  const data = {
    summaryData: [
      {
        title: 'PC 최다 해상도',
        rs: '1920 X 1080',
        ratio: 5.33,
      },
      {
        title: '모바일 최다 해상도',
        rs: '390 X 844',
        ratio: 10.52,
      },
      {
        title: '가장 큰 해상도',
        rs: '2000 X 2000',
        ratio: 0.56,
      },
      {
        title: '가장 작은 해상도',
        rs: '320 X 568',
        ratio: 0.14,
      },
    ],
    graphData: [
      {
        rs: '390 X 844',
        visitNum: 150,
      },
      {
        rs: '375 X 812',
        visitNum: 128,
      },
      {
        rs: '412 X 915',
        visitNum: 108,
      },
      {
        rs: '1920 X 1080',
        visitNum: 77,
      },
      {
        rs: '412 X 883',
        visitNum: 74,
      },
      {
        rs: '412 X 892',
        visitNum: 68,
      },
      {
        rs: '412 X 869',
        visitNum: 60,
      },
      {
        rs: '360 X 800',
        visitNum: 58,
      },
      {
        rs: '414 X 896',
        visitNum: 55,
      },
      {
        rs: '360 X 880',
        visitNum: 55,
      },
      {
        rs: '360 X 780',
        visitNum: 54,
      },
      {
        rs: '384 X 854',
        visitNum: 50,
      },
    ],
  };
  return (
    <>
      <RSSummary data={data.summaryData} />
      <RSGraph data={data.graphData} />
      <RSDetail data={data.graphData} />
    </>
  );
}

export default AnalyticsResolution;
