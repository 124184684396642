import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bg1 from './Images/bg-main.png';
import bg2 from './Images/bg-main2.png';
import bg3 from './Images/bg-main3.png';
const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

const ImageSlider = () => {
  return (
    <Slider
      {...settings}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <div className='slider-item'>
        {' '}
        <img
          src={bg2}
          alt=''
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
      <div className='slider-item'>
        {' '}
        <img
          src={bg3}
          alt=''
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
      <div className='slider-item'>
        <img
          src={bg1}
          alt=''
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    </Slider>
  );
};

export default ImageSlider;
