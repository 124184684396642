import React from 'react';
import './Styles/InflowKeyword.css';

const InflowKeyword = ({ data }) => {
  const totalPageView = getTotal(data, 'inflowNum');
  function getTotal(arr, key) {
    let temp = 0;
    for (let i = 0; i < arr.length; i++) {
      temp += arr[i][key];
    }
    return temp;
  }
  return (
    <>
      <div id='inflowKeyword'>
        <div className='compHeader'>
          <div className='title'>유입검색어</div>
        </div>
        <div className='compBody'>
          <div className='rank'>
            {data && data?.length > 0 ? (
              data?.slice(0, 10)?.map((item, idx) => (
                <div className='item' key={idx + 1}>
                  <span className='no'>{idx + 1}</span>
                  <span className='keyword'>{item?.keyword}</span>
                  <span className='percent'>
                    {((item.inflowNum / totalPageView) * 100).toFixed(1)}%
                  </span>
                </div>
              ))
            ) : (
              <div className='item'>
                <span className='no'>{1}</span>
                <span className='keyword'>{'-'}</span>
                <span className='percent'>{0}%</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default InflowKeyword;
