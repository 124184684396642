import React, { useState, useEffect } from 'react';
import './Styles/AnalyticsMain.css';
import Email from '../../Components/AnalyticsEmail/Email';
import { useSelector } from 'react-redux';
import { ScriptApi } from '../../API';
import { useLoadingState } from '../../context/LoadingContext';
function AnalyticsEmail(props) {
  props.setCurrentPage(props.pageTitle);
  const currentSite = useSelector((state) => state.currentSite);
  const userData = useSelector((state) => state.userData);
  const [data, setData] = useState([]);
  const [, setLoading] = useLoadingState(true);
  useEffect(() => {
    try {
      setLoading(true);
      const getScript = async () => {
        const data = await ScriptApi.getScripts(
          userData?.type,
          userData?.user_id
        );
        const emailFilterData = data?.filter(
          (item) => item?.script_code === currentSite.script_code
        );
        setData(emailFilterData);
      };
      getScript();
    } catch (e) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [currentSite.script_code, setLoading, userData?.type, userData?.user_id]);
  return (
    <>
      <Email data={data} />
    </>
  );
}

export default AnalyticsEmail;
