/**
 *
 *
 */

const APIConstant = {
  /*=== AUTH ===*/
  AUTH: '/auth/check',
  /* 방문현황 */
  GET_VISIT_UV: '/visit/uv',
  GET_VISIT_UV_DAY: '/visit/uv/day',
  /* 페이지뷰 */
  GET_VISIT_PV: '/visit/pv',
  GET_VISIT_PV_DAY: '/visit/pv/day',
  /* 요일별 */
  GET_VISIT_DALIY: '/visit/daily',
  GET_VISIT_DALIY_DAY: '/visit/daily/day',
  /* 시간별 */
  GET_TIME_LINE: '/visit/times',
  GET_TIME_LINE_DAY: '/visit/times/day',
  /* 유입검색어 */
  GET_KEYWORD: '/income/keyword',
  /* 유입URL상세 */
  GET_INCOME_URL: '/income/url',
  /* 검색유입현황(안씀) */
  GET_INCOME_SEARCH: '/income/search',
  /* 인기페이지 */
  GET_HOTPAGE: '/page/hotpage',
  /* 시작페이지 */
  GET_STARTPAGE: '/page/startpage',
  /* 파워링크 */
  GET_POWERLINK: '/powerlink',
  /* 디바이스 */
  GET_DEVICE: '/device',
  /* 브라우저 */
  GET_BROWSER: '/browser',
  /* 스크립트 */
  GET_SCRIPTS: '/script',
  GET_SCRIPT: '/script/:script_id',
  CREATE_SCRIPT: '/script',
  UPDATE_SCRIPT: '/script/:script_id',
  DELETE_SCRIPT: '/script',
  /* 유저 */
  USER_LOGIN: '/user/login',
  GET_USERS: '/users',
  GET_USER: '/user/:user_id',
  CREATE_USER: '/user',
  UPDATE_USER: '/user/:user_id',
  DELETE_USER: '/user/:user_id',
  PASSWORD_CHECK_USER: '/user/check/password',
};
export default APIConstant;
