import React, { useState, useEffect } from 'react';
import './Styles/AnalyticsMain.css';
import PreiodPicker from '../../Components/PreiodPicker';
import PPDetail from '../../Components/AnalyticsPage/PPDetail';
import { useSelector } from 'react-redux';
import { DataApi } from '../../API';
import { useLoadingState } from '../../context/LoadingContext';
function AnalyticsPoupularPage(props) {
  props.setCurrentPage(props.pageTitle);
  const [, setLoading] = useLoadingState(true);
  const currentSite = useSelector((state) => state.currentSite);
  const period = useSelector((state) => state.period);
  const comparePeriod = useSelector((state) => state.comparePeriod);
  const [data, setData] = useState([]);
  useEffect(() => {
    try {
      setLoading(true);
      const getApi = async () => {
        const PPData = await DataApi.getPopularPage(
          currentSite.script_code,
          period
        );
        setData(PPData);
      };
      getApi();
    } catch (e) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [currentSite, period, comparePeriod]);
  return (
    <>
      <PPDetail data={data} />
    </>
  );
}

export default AnalyticsPoupularPage;
