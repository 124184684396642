import './Styles/TLDetail.css';
const TLDetail = ({ data }) => {
  // const newData =
  //   data && data.length > 0
  //     ? data
  //     : [
  //         {
  //           time: '00:00',
  //           visitNum: 0,
  //           visitCnt: 0,
  //           newVisitNum: 9,
  //           reVisitNum: 0,
  //           pageView: 0,
  //         },
  //       ];
  //newVisitNum = is_revisit 0 = 신규 사용자 방문 (신규 사용자)
  //reVisitNum =  is_revisit 1 = 기존 사용자 방문 (기존 사용자)
  // visitCnt = visit_count 0 = 재 방문자 방문 후 이동
  // visitNum = visit_count 1 = 방문 횟수

  /**
   * 데이터 그룹화 함수
   * --
   */
  const groupByTime = (data) => {
    return data.reduce((acc, item) => {
      const time = item.time;
      if (!acc[time]) {
        acc[time] = {
          time: time || 0,
          visitNum: 0,
          visitCnt: 0,
          newVisitNum: 0,
          reVisitNum: 0,
          pageView: 0,
        };
      }
      acc[time].visitNum += item.visitNum || 0;
      acc[time].visitCnt += item.visitCnt || 0;
      acc[time].newVisitNum += item.newVisitNum || 0;
      acc[time].reVisitNum += item.reVisitNum || 0;
      acc[time].pageView += item.pageView || 0;
      return acc;
    }, {});
  };

  const groupedData = groupByTime(data);
  const newData = Object.values(groupedData);

  const sortedData = newData.sort((a, b) => {
    const hour = a.time.split(':').map(Number);
    const minute = b.time.split(':').map(Number);
    return hour[0] - minute[0] || hour[1] - minute[1];
  });

  return (
    <>
      <div id='tlDetail'>
        <div className='compHeader'>
          <div className='title'>시간대별 방문 상세정보</div>
        </div>
        <div className='compBody'>
          <table className='table'>
            <thead>
              <tr>
                <th>시간대</th>
                <th>방문자수</th>
                <th>전시간대비</th>
                <th>방문횟수</th>
                <th>신규방문자수</th>
                <th>재방문자수</th>
                <th>페이지뷰</th>
              </tr>
            </thead>
            <tbody>
              {sortedData?.map((item, idx) => (
                <tr className='item' key={idx}>
                  <td className='date'>{item?.time}</td>
                  <td className='visitNum'>{item?.visitNum}</td>
                  <td className='compare'>
                    {idx === 0 ? '0' : item?.visitNum - data[idx - 1]?.visitNum}
                    {(idx === 0
                      ? '0'
                      : item?.visitNum - data[idx - 1]?.visitNum) > 0 ? (
                      <svg id='up' viewBox='0 0 24 24' width='10' height='10'>
                        <path d='M10,22.03c-.77,0-1.51-.3-2.09-.88L1.18,14.82c-1.57-1.57-1.57-4.09-.02-5.64,0,0,.01-.01,.02-.02L7.93,2.81c.84-.85,2.09-1.1,3.22-.63s1.84,1.52,1.85,2.74v2.06h7.03c2.19,0,3.97,1.8,3.97,4.01v1.98c0,2.21-1.78,4.01-3.97,4.01h-7.03v2.06c0,1.23-.71,2.28-1.85,2.75-.38,.16-.77,.23-1.15,.23Z' />
                      </svg>
                    ) : item?.visitNum - data[idx - 1]?.visitNum < 0 ? (
                      <svg id='down' viewBox='0 0 24 24' width='10' height='10'>
                        <path d='M10,22.03c-.77,0-1.51-.3-2.09-.88L1.18,14.82c-1.57-1.57-1.57-4.09-.02-5.64,0,0,.01-.01,.02-.02L7.93,2.81c.84-.85,2.09-1.1,3.22-.63s1.84,1.52,1.85,2.74v2.06h7.03c2.19,0,3.97,1.8,3.97,4.01v1.98c0,2.21-1.78,4.01-3.97,4.01h-7.03v2.06c0,1.23-.71,2.28-1.85,2.75-.38,.16-.77,.23-1.15,.23Z' />
                      </svg>
                    ) : (
                      <svg width='10' height='10' viewBox='0 0 30 512'>
                        <path d='M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z' />
                      </svg>
                    )}
                  </td>
                  <td className='visitCnt'>{item?.visitCnt}</td>
                  <td className='newVisitNum'>{item?.newVisitNum}</td>
                  <td className='reVisitNum'>{item?.reVisitNum}</td>
                  <td className='pageView'>{item?.pageView}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default TLDetail;
