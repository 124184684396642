import { useEffect, useRef, useState } from 'react';
import './Styles/DistVisitByDay.css';
import { LineChart, Line, XAxis, YAxis } from 'recharts';
import { isMobile } from 'react-device-detect';
import { MobileChart } from '../Mobile';
const DistVisitByDay = ({ data }) => {
  // const newData = Array.isArray(data) ? data.map((item) => {
  //   return {
  //     date: item?.date,
  //     visitNum: item?.all_visitor?.value,
  //     visitCnt: item?.visit_times?.value,
  //     newVisitNum: item?.new_visitor?.value,
  //     reVisitNum: item?.re_visitor?.value,
  //     pageView: item?.all_pv?.value,
  //   };
  // }) : [];
  const UVData = data?.UVData?.row;
  const PVData = data?.PVData.row;
  const Average = {
    new_visitor: 0,
    re_visitor: 0,
    all_pv: 0,
    new_pv: 0,
    re_pv: 0,
  };
  const graphData = [];
  if (UVData && PVData && UVData.length > 0 && PVData.length > 0) {
    for (let i = 0; i < UVData?.length; i++) {
      Average.new_visitor += UVData[i]?.new_visitor?.value;
      Average.re_visitor += UVData[i]?.re_visitor?.value;
      Average.new_pv += PVData[i]?.new_pv?.value;
      Average.re_pv += PVData[i]?.re_pv?.value;
      Average.all_pv += PVData[i]?.all_pv?.value;
    }

    for (let i = 0; i < UVData?.length; i++) {
      const item = {
        date: UVData[i]?.date,
        visitNum: UVData[i]?.all_visitor?.value,
        visitCnt: UVData[i]?.visit_times?.value,
        newVisitNum: UVData[i]?.new_visitor?.value,
        reVisitNum: UVData[i]?.re_visitor?.value,
        pageView: PVData[i]?.all_pv?.value,
      };
      graphData.push(item);
    }
  }

  const [, setInnerWidth] = useState(window.innerWidth);
  const [graphWidth, setGraphWidth] = useState(
    window.innerWidth > 1920
      ? 1156
      : window.innerWidth > 1200
        ? window.innerWidth - 764
        : window.innerWidth - 422
  );
  const graphRef = useRef();
  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
      if (window.innerWidth > 1920) {
        setGraphWidth(1160);
      } else if (window.innerWidth > 1200) {
        setGraphWidth(window.innerWidth - 764);
      } else if (window.innerWidth > 960) {
        setGraphWidth(window.innerWidth - 422);
      } else {
        setGraphWidth(540);
      }
    };
    window.addEventListener('resize', resizeListener);
  });
  return (
    <>
      <div id='distVisitByDay'>
        <div className='mobile_chart'>
          <MobileChart
            title='일별 방문분포'
            data={graphData}
            border={false}
          />
        </div>

        <div className='compHeader'>
          <div className='title'>일별 방문분포</div>
          <div className='info'>
            <div className='legendInfo totalNum'>
              <span></span> 방문자수
            </div>
            <div className='legendInfo totalCnt'>
              <span></span> 방문횟수
            </div>
            <div className='legendInfo newVisitNum'>
              <span></span> 신규방문자수
            </div>
            <div className='legendInfo reVisitNum'>
              <span></span> 재방문자수
            </div>
            <div className='legendInfo pageView'>
              <span></span> 페이지뷰
            </div>
          </div>
        </div>

        <div className='compBody'>
          <div className='graph'>
            <div className='chart' ref={graphRef}>
              <LineChart
                width={graphWidth}
                height={200}
                data={graphData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey='date' />
                <YAxis />
                <Line
                  type='monotone'
                  dataKey='visitNum'
                  stroke='#2EBF6D'
                  borderWidth='3px'
                  activeDot={{ r: 7 }}
                />
                <Line
                  type='monotone'
                  dataKey='visitCnt'
                  stroke='#2C97DE'
                  activeDot={{ r: 7 }}
                />
                <Line
                  type='monotone'
                  dataKey='newVisitNum'
                  stroke='#E16070'
                  activeDot={{ r: 7 }}
                />
                <Line
                  type='monotone'
                  dataKey='reVisitNum'
                  stroke='#EEC763'
                  activeDot={{ r: 7 }}
                />
                <Line
                  type='monotone'
                  dataKey='pageView'
                  stroke='#8C939C'
                  activeDot={{ r: 7 }}
                />
              </LineChart>
            </div>
          </div>

          <div className='graphInfo'>
            <div className='item'>
              <ul>
                <li>
                  신규방문횟수: <strong>{Average.new_visitor}</strong>회
                </li>
                <li>
                  방문비율:{' '}
                  <strong>
                    {((Average.new_pv / Average.all_pv) * 100).toFixed(0)}
                  </strong>
                  %
                </li>
                <li>
                  페이지뷰: <strong>{Average.new_pv}</strong>건
                </li>
                <li>
                  방문당 페이지뷰:{' '}
                  <strong>
                    {(Average.new_pv / PVData?.length).toFixed(0)}
                  </strong>
                  건
                </li>
              </ul>
            </div>
            <div className='item'>
              <ul>
                <li>
                  재방문횟수: <strong>{Average.re_visitor}</strong>회
                </li>
                <li>
                  방문비율{' '}
                  <strong>
                    {((Average.re_pv / Average.all_pv) * 100).toFixed(0)}
                  </strong>
                  %
                </li>
                <li>
                  페이지뷰: <strong>{Average.re_pv}</strong>건
                </li>
                <li>
                  방문당 페이지뷰:{' '}
                  <strong>{(Average.re_pv / PVData?.length).toFixed(0)}</strong>
                  건
                </li>
              </ul>
            </div>
            {/* <div className='item'>
              <ul>
                <li>
                  평균 체류시간: <strong>{'-'}</strong>
                </li>
                <li>
                  방문당 페이지뷰: <strong>{'-'}</strong>건
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default DistVisitByDay;
