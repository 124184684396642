import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './Styles/AnalyticsDropDown.css';
import { deleteCookie } from '../utils';

const AnalyticsDropDown = ({ setDropDownVisible }) => {
  const dispatch = useDispatch();
  const closeDropDown = () => {
    setDropDownVisible(false);
  };
  const signOut = () => {
    dispatch({
      type: 'CLEAR_ALL_DATA',
    });
    deleteCookie('METRICS_ACCECE_TOKEN', {});
    window.location.replace('/');
  };
  return (
    <>
      <div id='dropDown' onClick={closeDropDown}>
        <Link to='/analytics/manage' className='item'>
          사이트 관리
        </Link>
        <Link to='/analytics/usermanage' className='item'>
          회원 정보 관리
        </Link>
        <div className='signOut' onClick={signOut}>
          로그아웃
        </div>
      </div>
    </>
  );
};

export default AnalyticsDropDown;
