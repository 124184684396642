import React, { useState, useEffect } from 'react';
import './Styles/AnalyticsMain.css';
import PVSummary from '../../Components/AnalyticsPV/PVSummary';
import PVGraph from '../../Components/AnalyticsPV/PVGraph';
import PVDetail from '../../Components/AnalyticsPV/PVDetail';
import { useSelector } from 'react-redux';
import { DataApi } from '../../API';
import { useLoadingState } from '../../context/LoadingContext';
import { isMobile } from 'react-device-detect';
import { MobileChart } from '../../Components/Mobile';
function AnalyticsPageView(props) {
  props.setCurrentPage(props.pageTitle);
  const [, setLoading] = useLoadingState(true);
  const currentSite = useSelector((state) => state.currentSite);
  const period = useSelector((state) => state.period);
  const comparePeriod = useSelector((state) => state.comparePeriod);
  const [data, setData] = useState({ summary: [], row: [] });
  useEffect(() => {
    try {
      setLoading(true);
      const getApi = async () => {
        const pvData = await DataApi.getPV(
          currentSite.script_code,
          period,
          comparePeriod
        );
        const pvRowData = await DataApi.getPVRow(
          currentSite.script_code,
          period
        );
        setData({ summary: pvData, row: pvRowData });
      };
      getApi();
    } catch (e) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [currentSite, period, comparePeriod]);

  return (
    <>
      <PVSummary data={data?.summary} />
      <PVGraph data={data?.row} />
      <PVDetail data={data?.row} />
    </>
  );
}

export default AnalyticsPageView;
