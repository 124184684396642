import { useState, useEffect } from 'react';
import './Styles/Powerlink.css';
import { DataEmpty } from '../../Pages';

const Powerlink = ({ data }) => {
  const [rowNum, setRowNum] = useState(10);
  const [startPage, setStartPage] = useState(0);
  const [newData, setNewData] = useState([]);
  const handleRowNum = (e) => {
    setRowNum(parseInt(e.target.value));
    setStartPage(0);
  };
  
  const totalPageView = data?.length;
  /* ===== RENDER ===== */
  useEffect(() => {
    const newData = data?.map((item) => {
      try {
        const ip = item?.ip;
        const alertTimeArray = new Date(item?.alert_time)
          .toISOString()
          .slice(0, 19)
          .split('T');
        const alert_time = alertTimeArray && (
          <>
            {' '}
            <>
              {alertTimeArray[0]}
              <br className='m_view' /> {alertTimeArray[1]}
            </>
          </>
        );
        const logs = item?.logs.map((subitem) => {
          let keyword = '';
          if (subitem?.cur_page_url.includes('?')) {
            const queryString = subitem?.cur_page_url.split('?')[1];
            // 쿼리 문자열을 &를 기준으로 분리하여 객체로 변환
            const queryParams = {};
            queryString?.split('&').forEach((query) => {
              const [key, value] = query?.split('=');
              queryParams[key] = value;
            });
            // 키워드 추출
            const decodeKeyword = decodeURIComponent(queryParams['n_query']);
            keyword = decodeKeyword !== 'undefined' ? decodeKeyword : '';
          }
          const is_mobile = item?.mobile?.is_mobile === '1' ? 'mobile' : 'PC';
          return {
            date: subitem.date,
            time: subitem.time,
            day_of_week: subitem.day_of_week,
            keyword: keyword,
            is_mobile: is_mobile,
          };
        });
        const ipChange =
          item?.ipMach === 'false' ? (
            <>
              대역폭을 변경하여
              <br className='m_view' /> 접속한 이력이 <br className='m_view' />
              있습니다.
            </>
          ) : (
            ''
          );
        return {
          ip,
          alert_time,
          logs,
          ipChange,
        };
      } catch (error) {
        console.error(`Invalid URL: ${item?.ip}`);
      }
    });
    setNewData(newData);
  }, [data]);
  return (
    <>
      <div id='Powerlink'>
        <div className='compHeader'>
          <div className='title'>부정클릭현황</div>
          <div className='func'>
            <div className='info'>
              부정클릭합계<strong>{totalPageView}</strong>
            </div>
            <select className='rowNum' onChange={handleRowNum}>
              <option value='10'>10개씩 보기</option>
              <option value='20'>20개씩 보기</option>
              <option value='30'>30개씩 보기</option>
              <option value='50'>50개씩 보기</option>
              <option value='100'>100개씩 보기</option>
            </select>
          </div>
        </div>
        {
          data?.length ? (
            <div className='compBody'>
              <table className='rank'>
                <thead>
                  <tr>
                    <th className='no'>순위</th>
                    <th>IP</th>
                    <th>접속 시간</th>
                    <th>로그 정보</th>
                    <th className='etc'>기타</th>
                  </tr>
                </thead>
                <tbody>
                  {newData
                    ?.slice(startPage, startPage + rowNum)
                    ?.map((item, idx) => (
                      <tr className='item' key={`${item?.ip}-${idx}`}>
                        <td className='no'>{startPage + idx + 1}</td>
                        <td className='ip'>{item?.ip}</td>
                        <td className='time'>{item?.alert_time}</td>
                        <td className='logData'>
                          {item?.logs?.map((x, i) => (
                            <div key={`${x?.date}-${i}`}>
                              <p>Date : {x?.date}</p>
                              <p>Day : {x.day_of_week}</p>
                              <p>Time : {x?.time}</p>
                              {x.keyword && <p>Keyword : {x?.keyword}</p>}
                              {x.is_mobile && <p>Device : {x?.is_mobile}</p>}
                            </div>
                          ))}
                        </td>
                        <td className='etc'>{item?.ipChange}</td>

                        {/* <td className='ratio'>
                    <div className='barWrap'>
                      <span
                        className='bar'
                        style={{
                          width:
                            ((item.inflowNum / totalPageView) * 100).toFixed(
                              1
                            ) + '%',
                        }}
                      ></span>
                    </div>
                    <div className='value'>
                      {((item.inflowNum / totalPageView) * 100).toFixed(1)}%
                    </div>
                  </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <DataEmpty />
          )
        }

        <div className='compFooter'>
          {startPage === 0 ? (
            <span></span>
          ) : (
            <button
              className='pageBtn preBtn'
              onClick={() => {
                setStartPage(startPage - rowNum);
              }}
            >
              <svg
                viewBox='0 0 24 24'
                width='12'
                height='12'
                style={{ transform: 'rotate(180deg)' }}
              >
                <path d='M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z' />
              </svg>
              이전페이지
            </button>
          )}

          {startPage + rowNum >= data?.length ? (
            <span></span>
          ) : (
            <button
              className='pageBtn nxtBtn'
              onClick={() => {
                setStartPage(startPage + rowNum);
              }}
            >
              다음페이지
              <svg viewBox='0 0 24 24' width='12' height='12'>
                <path d='M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z' />
              </svg>
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default Powerlink;
