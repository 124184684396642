import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import './Styles/AnalyticsLayout.css';
import AnalyticsSideBar from './AnalyticsSideBar';
import AnalyticsHeader from './AnalyticsHeader';
import AnalyticsFooter from './AnalyticsFooter';
import Loading from './../Pages/Loading';
import PreiodPicker from './PreiodPicker';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCookie, deleteCookie } from '../utils/index';
import { DataApi } from '../API';
import { useLoadingState } from '../context/LoadingContext';
import { MobilePeriod } from './Mobile/Components';
const exceptPath = ['manage', 'email', 'rateplan', 'usermanage'];

const AnalyticsLayout = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const [except, setExcept] = useState(false);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [margin, setMargin] = useState('');
  // const [token, setToken] = useState('');
  // console.log('token', token);
  // useEffect(() => {
  //   if (!token || token === '') {
  //     window.alert('로그인 후 이용해주세요');
  //     navigate('/signin');
  //   }
  // }, [token]);

  useEffect(() => {
    const cookie = getCookie('METRICS_ACCECE_TOKEN');
    // console.log(cookie);
    const authToken = async () => {
      const data = await DataApi.authcheck();
      if (!data?.user_id) {
        deleteCookie('METRICS_ACCECE_TOKEN', {});
        window.alert('토큰이 만료되었습니다. 다시 로그인해주세요.');
        navigate('/signin');
      }
    };
    if (cookie) {
      // console.log(cookie);
      authToken();
    } else {
      window.alert('로그인 후 이용해주세요');
      navigate('/signin');
    }
  }, [navigate]);

  const [loading, setLoading] = useLoadingState(false);

  // useEffect(() => {
  //   if (loading) {
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 1000);
  //   }
  // }, [loading]);

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, [navigate]);

  useEffect(() => {
    const call = (path) => {
      const splitPath = path?.split('/')[2];
      const splitPathSecond = path?.split('/')[3];
      if (
        exceptPath.includes(splitPath) ||
        exceptPath.includes(splitPathSecond)
      ) {
        setExcept(true);
      } else {
        setExcept(false);
      }
    };

    call(pathname);
  }, [pathname]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);
  }, []);
  /**
   * 마진 값 변동
   * ---
   */
  useEffect(() => {
    const call = (path) => {
      if (screenSize < 500) {
        const splitPath = path?.split('/')[2];
        const splitDetailPath = path?.split('/')[3];
        if (splitPath === 'summary' || splitDetailPath === 'timeline') {
          setMargin('0px');
        } else if (
          splitDetailPath === 'uv' ||
          splitDetailPath === 'pv' ||
          splitDetailPath === 'daliy' ||
          splitPath === 'user'
        ) {
          setMargin('500px');
        } else {
          setMargin('1400px');
        }
      } else {
        setMargin('0%');
      }
    };

    call(pathname);
  }, [pathname, screenSize]);
  return (
    <>
      {loading ? <Loading /> : <></>}
      <div id='AnalyticsLayout'>
        <AnalyticsSideBar currentPage={props.currentPage} />
        <div id='content'>
          <AnalyticsHeader currentPage={props.currentPage} />
          <div id='overview' className='page'>
            {except ? null : (
              <div className='c1'>
                <PreiodPicker setLoading={setLoading} />
              </div>
            )}
            <div className='c2' style={{ 'margin-bottom': margin }}>
              <Outlet />
            </div>
          </div>
          <div id='footer'>
            <AnalyticsFooter currentPage={props.currentPage} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsLayout;
