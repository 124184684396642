import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './redux/rootReducer';
import { persistStore } from 'redux-persist'; // 추가
import { PersistGate } from 'redux-persist/integration/react';
import { composeWithDevTools } from 'redux-devtools-extension';
import { LoadingProvider } from './context/LoadingContext';

const store = createStore(rootReducer, composeWithDevTools());
const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <LoadingProvider>
        <App />
      </LoadingProvider>
    </PersistGate>
  </Provider>
);
reportWebVitals();
