import './Styles/DailySummary.css';

const DailySummary = ({ data }) => {
  return (
    <>
      <div id='dailySummary'>
        <div className='compHeader'>
          <div className='title'>요일별 방문요약</div>
        </div>

        <div className='compBody'>
          {data?.map((item, idx) =>
            idx === 4 ? (
              <div className='item' key={idx}>
                <div className='itemHeader'>
                  <div className='title'>{item?.title}</div>
                  <div className='info'>
                    <div className='infoItem'>
                      <div className='subtitle'>방문자수</div>
                      <div className='value'>{item?.visitValue}</div>
                    </div>
                    <div className='infoItem'>
                      <div className='subtitle'>페이지뷰</div>
                      <div className='value'>{item?.pageViewValue}</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='item' key={idx}>
                <div className='itemHeader'>
                  <div className='title'>{item?.title}</div>
                  <div className='value'>{item?.value}</div>
                </div>
                <div className='itemBody'>
                  <div className='info'>
                    <strong>{item?.day}</strong>
                  </div>
                  <div className='info'>{item?.date}</div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};
export default DailySummary;
