import moment from 'moment';
import APIConstant from './APIContant';
import APIManager from './APIManager';

const $http = new APIManager();

const Api = {
  /**
   * 토큰 인증
   * --
   * @method GET
   * @returns
   */
  authcheck: async () => {
    try {
      const { status, data } = await $http.get(APIConstant.AUTH);
      if (status !== 200) return;
      return data;
    } catch (e) {
      throw e;
    }
  },
  /**
   * 방문현황 조회
   * --
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @param {*} comparePeriod
   * @returns
   */
  getUV: async (scriptCode, period, comparePeriod) => {
    try {
      const searchPeriod = [
        { d1: period[1], d2: period[0] },
        { d1: comparePeriod[1], d2: comparePeriod[0] },
      ];
      const query = {
        script_code: scriptCode,
        period: JSON.stringify(searchPeriod),
      };
      const { status, data } = await $http.get(APIConstant.GET_VISIT_UV, query);
      if (status !== 200) return;
      const summaryData = [
        {
          title: '방문자수',
          cur: data?.cur_data?.all_visitor?.value,
          pre: data?.pre_data?.all_visitor?.value,
        },
        {
          title: '방문횟수',
          cur: data?.cur_data?.visit_times?.value,
          pre: data?.pre_data?.visit_times?.value,
        },
        {
          title: '신규방문자수',
          cur: data?.cur_data?.new_visitor?.value,
          pre: data?.pre_data?.new_visitor?.value,
        },
        {
          title: '재방문자수',
          cur: data?.cur_data?.re_visitor?.value,
          pre: data?.pre_data?.re_visitor?.value,
        },
        {
          title: '방문당 PV',
          cur: data?.cur_data?.visit_pv?.value,
          pre: data?.pre_data?.visit_pv?.value,
        },
      ];
      return summaryData;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 방문현황 일별 조회
   * --
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @returns
   */
  getUVRow: async (scriptCode, period) => {
    const startDate = new Date(period[0]);
    const endDate = new Date(period[1]);
    const diffDate =
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
    try {
      const dateArray = [];
      for (let k = 0; k <= diffDate; k++) {
        const date = new Date(period[0]);
        date?.setDate(date?.getDate() + k);
        dateArray.push(moment(date)?.format('YYYY-MM-DD'));
      }
      const query = { script_code: scriptCode, period: dateArray };
      const { status, data } = await $http.get(
        APIConstant.GET_VISIT_UV_DAY,
        query
      );
      if (status !== 200) return [];
      return data;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 페이지뷰 조회
   * --
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @param {*} comparePeriod
   * @returns
   */
  getPV: async (scriptCode, period, comparePeriod) => {
    try {
      const searchPeriod = [
        { d1: period[1], d2: period[0] },
        { d1: comparePeriod[1], d2: comparePeriod[0] },
      ];
      const query = {
        script_code: scriptCode,
        period: JSON.stringify(searchPeriod),
      };
      const { data } = await $http.get(APIConstant.GET_VISIT_PV, query);
      const summaryData = [
        {
          title: '페이지뷰',
          cur: data?.cur_data?.all_pv?.value,
          pre: data?.pre_data?.all_pv?.value,
        },
        {
          title: '신규방문자 PV',
          cur: data?.cur_data?.new_pv?.value,
          pre: data?.pre_data?.new_pv?.value,
        },
        {
          title: '재방문자 PV',
          cur: data?.cur_data?.re_pv?.value,
          pre: data?.pre_data?.re_pv?.value,
        },
        {
          title: '방문횟수',
          cur: data?.cur_data?.visit_times?.value,
          pre: data?.pre_data?.visit_times?.value,
        },
        {
          title: '방문당 PV',
          cur: data?.cur_data?.visit_pv?.value,
          pre: data?.pre_data?.visit_pv?.value,
        },
      ];
      return summaryData;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 페이지뷰 로우 조회
   * --
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @returns
   */
  getPVRow: async (scriptCode, period) => {
    // let rowDatas = [];
    const startDate = new Date(period[0]);
    const endDate = new Date(period[1]);
    const diffDate =
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
    try {
      const dateArray = [];
      for (let k = 0; k <= diffDate; k++) {
        const date = new Date(period[0]);
        date?.setDate(date?.getDate() + k);
        dateArray.push(moment(date)?.format('YYYY-MM-DD'));
      }
      const query = { script_code: scriptCode, period: dateArray };
      const { status, data } = await $http.get(
        APIConstant.GET_VISIT_PV_DAY,
        query
      );
      if (status !== 200) return [];
      return data;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 요일별 조회
   * --
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @param {*} comparePeriod
   * @returns
   */
  getDaily: async (scriptCode, period, comparePeriod) => {
    try {
      const searchPeriod = [
        { d1: period[1], d2: period[0] },
        { d1: comparePeriod[1], d2: comparePeriod[0] },
      ];
      const query = {
        script_code: scriptCode,
        period: JSON.stringify(searchPeriod),
      };
      const { data } = await $http.get(APIConstant.GET_VISIT_DALIY, query);
      const summaryData = [
        {
          title: '최대 방문자수',
          value: data?.cur_data?.max_visit?.value,
          day: data?.cur_data?.max_visit?.key,
          // date: data.data.cur_data.max_visit.key,
        },
        {
          title: '최소 방문자수',
          value: data?.cur_data?.min_visit?.value,
          day: data?.cur_data?.min_visit?.key,
          // date: data.data.cur_data.min_visit.key,
        },
        {
          title: '최대 페이지뷰',
          value: data?.cur_data?.max_page?.value,
          day: data?.cur_data?.max_page?.key,
          // date: data.data.cur_data.max_page.key,
        },
        {
          title: '최소 페이지뷰',
          value: data?.cur_data?.min_page?.value,
          day: data?.cur_data?.min_page?.key,
          // date: data.data.cur_data.min_page.key,
        },
        {
          title: '최소최대 · 편차',
          visitValue:
            data?.cur_data?.max_page?.value - data?.cur_data?.min_page?.value,
          pageViewValue:
            data?.cur_data?.max_visit?.value - data?.cur_data?.min_visit?.value,
        },
      ];
      return summaryData;
    } catch (e) {
      throw e;
    }
  },
  /**
   * 요일별 로우 조회
   * --
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @returns
   */
  getDailyRow: async (scriptCode, period) => {
    let rowDatas = [];
    const startDate = new Date(period[1]);
    const endDate = new Date(period[0]);
    const tempPeriod = { d1: startDate, d2: endDate };
    const dayList = [
      'mon_data',
      'tue_data',
      'wed_data',
      'thu_data',
      'fri_data',
      'sat_data',
      'sun_data',
    ];
    const dayListKo = [
      '월요일',
      '화요일',
      '수요일',
      '목요일',
      '금요일',
      '토요일',
      '일요일',
    ];
    try {
      const query = {
        script_code: scriptCode,
        period: JSON.stringify(tempPeriod),
      };
      const { status, data } = await $http.get(
        APIConstant.GET_VISIT_DALIY_DAY,
        query
      );
      if (status !== 200) return;
      dayList.forEach((day, idx) => {
        let temp = data[day];
        temp.date = dayListKo[idx];
        rowDatas.push(temp);
      });
      return rowDatas;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 시간대별방문 요약 조회
   * ---
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @returns getData[]
   */

  getTimeLine: async (scriptCode, period) => {
    try {
      const startDate = period[1];
      const endDate = period[0];
      const tempPeriod = { d1: startDate, d2: endDate };
      const query = {
        script_code: scriptCode,
        period: JSON.stringify(tempPeriod),
      };
      const { status, data } = await $http.get(
        APIConstant.GET_TIME_LINE,
        query
      );
      if (status === 200) {
        return data;
      }
      return [];
    } catch (e) {
      throw e;
    }
  },
  /**
   * 시간대별방문 요약 조회
   * ---
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @returns
   */
  getTimeLineDay: async (scriptCode, period) => {
    try {
      const startDate = period[1];
      const endDate = period[0];
      const tempPeriod = { d1: startDate, d2: endDate };
      const query = {
        script_code: scriptCode,
        period: JSON.stringify(tempPeriod),
      };
      const { status, data } = await $http.get(
        APIConstant.GET_TIME_LINE_DAY,
        query
      );
      if (status === 200) {
        return data;
      }
      return [];
    } catch (e) {
      throw e;
    }
  },
  /**
   * 키워드 조회
   * --
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @param {*} comparePeriod
   * @returns
   */
  getKeyword: async (scriptCode, period, comparePeriod) => {
    try {
      const searchPeriod = [
        { d1: period[1], d2: period[0] },
        { d1: comparePeriod[1], d2: comparePeriod[0] },
      ];
      const query = {
        script_code: scriptCode,
        period: JSON.stringify(searchPeriod),
      };
      const { status, data } = await $http.get(APIConstant.GET_KEYWORD, query);
      if (status === 200) {
        let keywordData = [];
        data?.cur_data?.keyword_obj?.value?.forEach((item) => {
          keywordData.push({
            keyword: Object.keys(item)[0],
            inflowNum: Object.values(item)[0],
          });
        });
        return keywordData;
      }
      return [{ keyword: '', inflowNum: 0 }];
    } catch (e) {
      throw e;
    }
  },
  /**
   * 인기페이지 조회
   * --
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @returns
   */
  getPopularPage: async (scriptCode, period) => {
    try {
      const searchPeriod = [{ d1: period[1], d2: period[0] }];
      const query = {
        script_code: scriptCode,
        period: JSON.stringify(searchPeriod),
      };
      const { status, data } = await $http.get(APIConstant.GET_HOTPAGE, query);
      if (status === 200) {
        let popularPageData = [];
        data?.cur_data?.url_obj?.value?.forEach((item) => {
          popularPageData.push({
            url: Object.keys(item)[0],
            pv: Object.values(item)[0],
            avgTime: 0,
          });
        });
        return popularPageData;
      }
      return [
        {
          url: '',
          pv: 0,
          avgTime: 0,
        },
      ];
    } catch (e) {
      throw e;
    }
  },
  /**
   * 시작페이지 조회
   * --
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @returns
   */
  getStartPage: async (scriptCode, period, comparePeriod) => {
    try {
      const searchPeriod = [
        { d1: period[1], d2: period[0] },
        { d1: comparePeriod[1], d2: comparePeriod[0] },
      ];
      const query = {
        script_code: scriptCode,
        period: JSON.stringify(searchPeriod),
      };
      const { status, data } = await $http.get(
        APIConstant.GET_STARTPAGE,
        query
      );
      if (status === 200) {
        let startPageData = [];
        data?.cur_data?.url_obj?.value?.forEach((item) => {
          startPageData.push({
            url: Object.keys(item)[0],
            pv: Object.values(item)[0],
            avgTime: 0,
          });
        });
        return startPageData;
      }
      return [
        {
          url: '',
          pv: 0,
          avgTime: 0,
        },
      ];
    } catch (e) {
      throw e;
    }
  },
  /**
   * 유입URL 상세 조회
   * --
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @param {*} comparePeriod
   * @returns
   */
  getIncomeUrl: async (scriptCode, period, comparePeriod) => {
    try {
      const searchPeriod = [
        { d1: period[1], d2: period[0] },
        { d1: comparePeriod[1], d2: comparePeriod[0] },
      ];
      const query = {
        script_code: scriptCode,
        period: JSON.stringify(searchPeriod),
      };
      const { status, data } = await $http.get(
        APIConstant.GET_INCOME_URL,
        query
      );
      if (status === 200) {
        let urlData = [];
        data?.cur_data?.url_obj?.value.forEach((item) => {
          urlData.push({
            url: Object.keys(item)[0],
            inflowNum: Object.values(item)[0],
          });
        });
        return urlData;
      }
      return [{ url: '', inflowNum: 0 }];
    } catch (e) {
      throw e;
    }
  },
  /**
   * 부정클릭 조회
   * --
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @returns
   */
  getPowerLink: async (scriptCode, period) => {
    try {
      const startDate = period[1];
      const endDate = period[0];
      const tempPeriod = { d1: startDate, d2: endDate };
      const query = {
        script_code: scriptCode,
        period: JSON.stringify(tempPeriod),
      };
      const { status, data } = await $http.get(
        APIConstant.GET_POWERLINK,
        query
      );
      if (status === 200) {
        return data;
      }
      return [];
    } catch (e) {
      throw e;
    }
  },
  /**
   * 디바이스/ OS 조회
   * --
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @returns
   */
  getDevice: async (scriptCode, period) => {
    try {
      const startDate = period[1];
      const endDate = period[0];
      const tempPeriod = { d1: startDate, d2: endDate };
      const query = {
        script_code: scriptCode,
        period: JSON.stringify(tempPeriod),
      };
      const { status, data } = await $http.get(APIConstant.GET_DEVICE, query);
      if (status === 200) {
        return data;
      }
      return [];
    } catch (e) {
      throw e;
    }
  },
  /**
   * 브라우저 조회
   * --
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @returns
   */
  getBrowser: async (scriptCode, period) => {
    try {
      const startDate = period[1];
      const endDate = period[0];
      const tempPeriod = { d1: startDate, d2: endDate };
      const query = {
        script_code: scriptCode,
        period: JSON.stringify(tempPeriod),
      };
      const { status, data } = await $http.get(APIConstant.GET_BROWSER, query);
      if (status === 200) {
        return data;
      }
      return [];
    } catch (e) {
      throw e;
    }
  },
  /////////////
  /**
   * 검색 유입 현황 조회(X)
   * --
   * @method GET
   * @param {*} scriptCode
   * @param {*} period
   * @returns
   */
  getIncomeSearch: async (scriptCode, period, comparePeriod) => {
    try {
      const searchPeriod = [
        { d1: period[1], d2: period[0] },
        { d1: comparePeriod[1], d2: comparePeriod[0] },
      ];
      const query = {
        script_code: scriptCode,
        period: JSON.stringify(searchPeriod),
      };
      const { status, data } = $http.get(APIConstant.GET_INCOME_SEARCH, query);
      if (status === 200) {
        return data;
      }
      return [];
    } catch (e) {
      throw e;
    }
  },
};
export default Api;
