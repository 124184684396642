import APIConstant from './APIContant';
import APIManager from './APIManager';
import { parameterToPath } from '../utils/index';
const $http = new APIManager();
const Api = {
  /**
   * 유저 로그인
   * ---
   * @param {*} user_account
   * @param {*} password
   * @returns
   */
  userLogin: async (user_account, password) => {
    try {
      const InfoData = {
        user_account,
        password,
      };
      const data = await $http.post(APIConstant.USER_LOGIN, InfoData);
      return data;
    } catch (e) {
      throw e;
    }
  },
  /**
   * 유저 회원가입
   * ---
   * @param {*} userData
   * @returns
   */
  createUser: async (userData) => {
    try {
      const data = await $http.post(APIConstant.CREATE_USER, userData);
      return data;
    } catch (e) {
      throw e;
    }
  },
  /**
   * 유저 전체 조회
   * ---
   * @returns
   */
  getUsers: async () => {
    try {
      const data = await $http.get(APIConstant.GET_USERS);
      return data;
    } catch (e) {
      throw e;
    }
  },
  /**
   * 유저 업데이트
   * ---
   * @returns
   */
  updateUser: async (userData) => {
    try {
      const url = parameterToPath(APIConstant.UPDATE_USER, {
        user_id: userData?.user_id,
      });
      const data = await $http.put(url, userData);
      return data;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 유저 단일 조회
   * ---
   * @returns
   */
  getUser: async (user_id) => {
    try {
      const url = parameterToPath(APIConstant.GET_USER, {
        user_id,
      });
      const data = await $http.get(url);
      return data;
    } catch (e) {
      throw e;
    }
  },
  /**
   * 유저 비밀번호 확인
   * ---
   * @returns
   */
  checkUserPassword: async (userData) => {
    try {
      const data = await $http.post(APIConstant.PASSWORD_CHECK_USER, userData);
      return data;
    } catch (e) {
      throw e;
    }
  },
};
export default Api;
