import { useState, useEffect } from 'react';
import './Styles/PPDetail.css';
import { isMobile } from 'react-device-detect';

const PPDetail = ({ data }) => {
  const [rowNum, setRowNum] = useState(10);
  const [startPage, setStartPage] = useState(0);
  const handleRowNum = (e) => {
    setRowNum(parseInt(e.target.value));
    setStartPage(0);
  };
  const totalPageView = getTotal(data, 'pv');
  const [newData, setNewData] = useState([]);

  /* ===== FUNCTION ===== */
  function getTotal(arr, key) {
    let temp = 0;
    for (let i = 0; i < arr.length; i++) {
      temp += arr[i][key];
    }
    return temp;
  }

  /* ===== RENDER ===== */
  useEffect(() => {
    const grouped = {};
    const datas = {};
    data?.forEach((item) => {
      if (item?.url) {
        try {
          const protocol = `${decodeURI(new URL(item.url).protocol)}//`;
          const domain = decodeURI(new URL(item.url).hostname);
          const newdomain = domain.includes('www')
            ? domain.replace('www.', '')
            : domain;
          const pathname = decodeURI(new URL(item.url).pathname);
          const path = pathname === '/' ? '' : pathname;

          const route = `${protocol}${newdomain}${path}`;
          if (!datas[route]) {
            datas[route] = { url: route, count: 0 };
          }
          datas[route].count += item.pv;

          if (!grouped[route]) {
            grouped[route] = { url: route, pv: 0, avgTime: 0 };
          }

          grouped[route].pv += item?.pv || 0;
          grouped[route].avgTime += item?.avgTime || 0;
        } catch (error) {
          console.error(`Invalid URL: ${item.url}`);
        }
      }
    });

    setNewData(Object.values(grouped));
  }, [data]);
  newData?.sort((a, b) => b.pv - a.pv);
  return (
    <>
      <div id='ppDetail'>
        <div className='compHeader'>
          <div className='title'>인기페이지</div>
          <div className='func'>
            <div className='info'>
              페이지뷰합계<strong>{totalPageView}</strong>
            </div>
            <select className='rowNum' onChange={handleRowNum}>
              <option value='10'>10개씩 보기</option>
              <option value='20'>20개씩 보기</option>
              <option value='30'>30개씩 보기</option>
              <option value='50'>50개씩 보기</option>
              <option value='100'>100개씩 보기</option>
            </select>
          </div>
        </div>
        <div className='compBody'>
          <table className='rank'>
            <thead>
              <tr>
                <th>순위</th>
                <th>페이지 URL</th>
                <th>페이지뷰</th>
                <th>비율</th>
                {/* <th>평균체류시간</th> */}
              </tr>
            </thead>
            <tbody>
              {newData
                ?.slice(startPage, startPage + rowNum)
                ?.map((item, idx) => (
                  <tr className='item'>
                    <td className='no'>{startPage + idx + 1}</td>
                    <td className='url'>
                      <a href={item?.url}>{decodeURIComponent(item?.url)}</a>
                    </td>
                    <td className='pageView'>{item?.pv}</td>
                    <td className='ratio'>
                      <div className='barWrap'>
                        <span
                          className='bar'
                          style={{
                            width:
                              ((item?.pv / totalPageView) * 100).toFixed(1) +
                              '%',
                          }}
                        ></span>
                      </div>
                      <div className='value'>
                        {((item.pv / totalPageView) * 100).toFixed(1)}%
                      </div>
                    </td>
                    {/* <td className='avgTime'>{item?.avgTime}초</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='compFooter'>
          {startPage === 0 ? (
            <span></span>
          ) : (
            <button
              className='pageBtn preBtn'
              onClick={() => {
                setStartPage(startPage - rowNum);
              }}
              style={{marginLeft : '20px'}}
            >
              <svg
                viewBox='0 0 24 24'
                width='12'
                height='12'
                style={{ transform: 'rotate(180deg)' }}
              >
                <path d='M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z' />
              </svg>
              이전페이지
            </button>
          )}

          {startPage + rowNum >= data?.length ? (
            <span></span>
          ) : (
            <button
              className='pageBtn nxtBtn'
              onClick={() => {
                setStartPage(startPage + rowNum);
              }}
              style={{marginRight : '20px'}}
            >
              다음페이지
              <svg viewBox='0 0 24 24' width='12' height='12'>
                <path d='M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z' />
              </svg>
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default PPDetail;
