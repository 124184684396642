import './Styles/BSDetail.css';
import { useState } from 'react';
const BSDetail = ({ data }) => {
  const totalPageView = data?.length;
  const broswerData = data ? data : [];
  const browserCounts = {};
  let browserAllCount = 0;
  const [rowNum, setRowNum] = useState(10);
  const [startPage, setStartPage] = useState(0);
  const handleRowNum = (e) => {
    setRowNum(parseInt(e.target.value));
    setStartPage(0);
  };
  for (const item of broswerData) {
    browserAllCount++;
    const browser =
      item.app_name === null ? '기타' : `${item.app_name} ${item.version}`;
    browserCounts[browser] = (browserCounts[browser] || 0) + 1;
  }
  const result = Object.entries(browserCounts)?.map(([browser, count]) => ({
    browser: browser,
    visitNum: count,
  }));
  result?.sort((a, b) => b.visitNum - a.visitNum);
  return (
    <>
      <div id='bsDetail'>
        <div className='compHeader'>
          <div className='title'>웹브라우저 상세정보</div>
          <div className='func'>
            <div className='info'>
              웹브라우저 합계<strong>{totalPageView}</strong>
            </div>
            <select className='rowNum' onChange={handleRowNum}>
              <option value='10'>10개씩 보기</option>
              <option value='20'>20개씩 보기</option>
              <option value='30'>30개씩 보기</option>
              <option value='50'>50개씩 보기</option>
              <option value='100'>100개씩 보기</option>
            </select>
          </div>
        </div>
        <div className='compBody'>
          <table className='table'>
            <thead>
              <tr>
                <th>순위</th>
                <th>웹브라우저</th>
                <th>방문자수</th>
                <th>비율</th>
              </tr>
            </thead>
            <tbody>
              {result
                ?.slice(startPage, startPage + rowNum)
                ?.map((item, idx) => (
                  <tr className='item' key={idx}>
                    <td className='rank'>{startPage + idx + 1}</td>
                    <td className='rs'>{item.browser}</td>
                    <td className='visitNum'>{item.visitNum}</td>
                    <td className='ratio'>
                      {((item.visitNum / browserAllCount) * 100).toFixed(2)}%
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='compFooter'>
          {startPage === 0 ? (
            <span></span>
          ) : (
            <button
              className='pageBtn preBtn'
              onClick={() => {
                setStartPage(startPage - rowNum);
              }}
            >
              <svg
                viewBox='0 0 24 24'
                width='12'
                height='12'
                style={{ transform: 'rotate(180deg)' }}
              >
                <path d='M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z' />
              </svg>
              이전페이지
            </button>
          )}

          {startPage + rowNum >= data?.length ? (
            <span></span>
          ) : (
            <button
              className='pageBtn nxtBtn'
              onClick={() => {
                setStartPage(startPage + rowNum);
              }}
            >
              다음페이지
              <svg viewBox='0 0 24 24' width='12' height='12'>
                <path d='M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z' />
              </svg>
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default BSDetail;
