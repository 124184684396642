import './Styles/TLSummary.css';

const TLSummary = ({ data }) => {
  // 배열에서 visitNum이 가장 큰 데이터 찾기
  const newdata =
    data?.length > 1 && data
      ? data
      : [
          {
            date: '-',
            newVisitNum: 0,
            pageView: 0,
            reVisitNum: 0,
            time: '00:00',
            visitCnt: 0,
            visitNum: 0,
          },
        ];
  const maxVisitNumData = newdata?.reduce((maxData, currentData) => {
    if (currentData?.visitNum > maxData?.visitNum) {
      return currentData;
    }
    return maxData;
  }, newdata[0]);

  // 배열에서 visitNum이 가장 작은 데이터 찾기
  const minVisitNumData = newdata?.reduce((minData, currentData) => {
    if (currentData?.visitNum < minData?.visitNum) {
      return currentData;
    }
    return minData;
  }, newdata[0]);

  // 배열에서 pageView가 가장 많은 데이터 찾기
  const maxPageViewData = newdata?.reduce((maxData, currentData) => {
    if (currentData?.pageView > maxData?.pageView) {
      return currentData;
    }
    return maxData;
  }, newdata[0]);

  // 배열에서 pageView가 가장 작은 데이터 찾기
  const minPageViewData = newdata?.reduce((minData, currentData) => {
    if (currentData?.pageView < minData?.pageView) {
      return currentData;
    }
    return minData;
  }, newdata[0]);
  // 데이터 형식 변환 및 추출
  const result = [
    {
      title: '최대방문자수',
      value: maxVisitNumData?.visitNum,
      time: maxVisitNumData?.time,
      date: maxVisitNumData?.date,
    },
    {
      title: '최소방문자수',
      value: minVisitNumData?.visitNum,
      time: minVisitNumData?.time,
      date: minVisitNumData?.date,
    },
    {
      title: '최대페이지뷰',
      value: maxPageViewData?.pageView,
      time: maxPageViewData?.time,
      date: maxPageViewData?.date,
    },
    {
      title: '최소페이지뷰',
      value: minPageViewData?.pageView,
      time: minPageViewData?.time,
      date: minPageViewData?.date,
    },
    {
      title: '최소최대 · 편차',
      visitValue: maxVisitNumData?.visitNum - minVisitNumData?.visitNum,
      pageViewValue: maxPageViewData?.pageView - minPageViewData?.pageView,
    },
  ];

  return (
    <>
      <div id='tlSummary'>
        <div className='compHeader'>
          <div className='title'>시간대별 방문요약</div>
        </div>

        <div className='compBody'>
          {result && result.length > 1 ? (
            result?.map((item, idx) => {
              return idx === 4 ? (
                <div className='item' key={idx}>
                  <div className='itemHeader'>
                    <div className='title'>{item.title}</div>
                    <div className='info'>
                      <div className='infoItem'>
                        <div className='subtitle'>방문자수</div>
                        <div className='value'>{item.visitValue}</div>
                      </div>
                      <div className='infoItem'>
                        <div className='subtitle'>페이지뷰</div>
                        <div className='value'>{item.pageViewValue}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='item' key={idx}>
                  <div className='itemHeader'>
                    <div className='title'>{item?.title}</div>
                    <div className='value'>{item?.value}</div>
                  </div>
                  <div className='itemBody'>
                    <div className='info'>
                      <strong>{item?.time}</strong>
                    </div>
                    <div className='info'>{item?.date}</div>
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
export default TLSummary;
