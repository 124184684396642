import './Styles/BSSummary.css';

const BSSummary = ({ data }) => {
  const broswerData = data && data?.length > 0 ? data : [];
  const browserArray = [
    {
      title: '최다 웹브라우저',
      browser: '',
      ratio: 0,
    },
    {
      title: '최소 웹브라우저',
      browser: '',
      ratio: 0,
    },
  ];

  // 중복된 값 더하고 각 브라우저의 개수 계산
  const browserCounts = {};
  let browserAllCount = 0;
  for (const item of broswerData) {
    browserAllCount++;
    const browser =
      item.app_name === null ? '기타' : `${item.app_name} ${item.version}`;
    browserCounts[browser] = (browserCounts[browser] || 0) + 1;
  }

  // 결과값을 배열로 변환
  const result = Object.entries(browserCounts).map(([browser, count]) => ({
    browser: browser,
    count: count,
  }));
  // 최대 및 최소 값을 추적할 변수 초기화
  let maxBrowser = result[0]?.browser;
  let maxCount = result[0]?.count;
  let minBrowser = result[0]?.browser;
  let minCount = result[0]?.count;

  for (const item of result) {
    if (item.count > maxCount) {
      maxBrowser = item.browser;
      maxCount = item.count;
    }
    if (item.count < minCount) {
      minBrowser = item.browser;
      minCount = item.count;
    }
  }
  browserArray[0].browser = maxBrowser;
  browserArray[0].ratio = maxCount
    ? ((maxCount / browserAllCount) * 100).toFixed(1)
    : 0;
  browserArray[1].browser = minBrowser;
  browserArray[1].ratio = minCount
    ? ((minCount / browserAllCount) * 100).toFixed(1)
    : 0;
  return (
    <>
      <div id='bsSummary'>
        <div className='compHeader'>
          <div className='title'>웹브라우저 요약</div>
        </div>

        <div className='compBody'>
          {browserArray?.map((item, idx) => {
            return (
              <div className='item' key={idx}>
                <div className='itemHeader'>
                  <div className='title'>{item?.title}</div>
                </div>
                <div className='itemBody'>
                  <div className='bs'>{item?.browser}</div>
                  <div className='info ratio2'>{item?.ratio}%</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default BSSummary;
