import React from 'react';
import { useSelector } from 'react-redux';
import './Styles/ISSummary.css';

const ISSummary = ({ data }) => {
  const period = useSelector((state) => state.period);
  return (
    <>
      <div id='isSummary'>
        <div className='compHeader'>
          <div className='title'>방문현황 요약</div>
          <div className='info'>
            등락 비교일 {period[0]}~{period[1]}
          </div>
        </div>

        <div className='compBody'>
          {data.map((item, idx) => {
            return (
              <div className='item' key={idx}>
                <div className='itemHeader'>
                  <div className='title'>{item.title}</div>
                  {item.channel === '' ? (
                    <div className='value'>
                      {Number.isInteger(item.inPreiod) ? (
                        <>{item.inPreiod}</>
                      ) : (
                        <>{item.inPreiod}%</>
                      )}
                    </div>
                  ) : (
                    <>
                      <div className='channel'>{item.channel}</div>
                      <div className='value1'>{item.inPreiod}%</div>
                    </>
                  )}
                </div>
                <div className='itemBody'>
                  <div className='info'>{item.comparePeriod}</div>
                  <div className='info'>
                    {idx === 2 || idx === 3
                      ? (item.inPreiod - item.comparePeriod).toFixed(2)
                      : item.inPreiod - item.comparePeriod}
                    (
                    {(
                      ((item.inPreiod - item.comparePeriod) /
                        item.comparePeriod) *
                      100
                    ).toFixed(1)}
                    %)
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default ISSummary;
