import { persistReducer } from 'redux-persist'; // 추가
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
};

const initialState = {
  userData: {},
  scriptData: [],
  currentSite: {},
  period: ['', ''],
  comparePeriod: ['', ''],
};

const rootReducer = (state = initialState, action) => {
  let temp = { ...state };
  // console.log(action.payload);
  switch (action.type) {
    case 'SET_USER_DATA':
      temp.userData = action.payload;
      return temp;
    case 'CLEAR_USER_DATA':
      temp.userData = {};
      return temp;
    case 'SET_SCRIPT_DATA':
      temp.scriptData = action.payload;
      return temp;
    case 'SET_CURRENT_SITE':
      temp.currentSite = action.payload;
      return temp;
    case 'SET_PERIOD':
      temp.period = action.payload;
      return temp;
    case 'SET_COMPARE_PERIOD':
      temp.comparePeriod = action.payload;
      return temp;
    case 'CLEAR_ALL_DATA':
      temp = {
        userData: {},
        scriptData: [],
        currentSite: {},
        period: ['', ''],
        comparePeriod: ['', ''],
      };
      return temp;
    default:
      return temp;
  }
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
